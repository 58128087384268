import React, { useEffect, useState } from 'react';
import { db } from '../../../../utils/firebase';
import "./users.scss";

/**
 * UI components
 */
import User from './user';

/**
 * Functional component for displaying the current active users on the content page.
 * 
 * The adding and removing of the users from the database document also takes place in here for simplicity 
 * sake to keep everything in the same place.
 * 
 * @returns HTML mkarjup for the active users on the content reviewing page
 */
function ActiveUsers(props) {
    const [activeUsers, setActiveUsers] = useState([]);

    /**
     * Deconstruct the viewID from the props
     */
    const { viewID } = props;

    /**
     * On component mount & unmount
     */
    useEffect(() => {
        /**
         * Setup a listener to the database for the active users stream
         */
        const unsubscribe = db.doc(`content/${viewID}`).onSnapshot((snap) => {
            /**
             * Make sure we read a valid content document
             */
            if (snap.data()) {
                /**
                 * Get the active users from the document
                 */
                const users = snap.data().activeUsers;
                /**
                 * If there are users to show
                 */
                if (users) {
                    /**
                     * Loop over the different users
                     */
                    users.forEach(async (user) => {
                        /**
                         * Get that user's details from firestore
                         */
                        db.doc(`users/${user}`).get().then((userDoc) => {
                            /**
                             * Deconstruct the user data from the document
                             */
                            const { name, email, imageURL } = userDoc.data();
                            /**
                             * Push them onto the active users array
                             */
                            const newActiveUser = {
                                id: user,
                                name: name,
                                email: email,
                                image: imageURL,
                            }
                            /**
                             * Update the state if required
                             */
                            setActiveUsers((activeUsers) => {
                                /**
                                 * Initialise a new copy of the activeUsers array
                                 */
                                let currentlyActive = [...activeUsers];
                                /**
                                 * Attempt to find the index for this new user in the array
                                 */
                                const index = currentlyActive.findIndex((user) => user.id === newActiveUser.id);
                                /**
                                 * If the index returned is -1 (the Id was not found in the array)
                                 */
                                if (index === -1) {
                                    /**
                                     * Push the new user onto the array
                                     */
                                    currentlyActive.push(newActiveUser);
                                }
                                /**
                                 * Return the new array
                                 */
                                return currentlyActive;
                            });
                        });
                    });
                }
            }
        });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    return (
        <div className="active-viewers">
            <p className="title small">Viewing</p>
            {activeUsers.map((user) => (
                <User key={user.id} userID={user.id} name={user.name} email={user.email} />
            ))}
        </div>
    );
}

export default ActiveUsers;