import React, { useState, useContext } from "react";
import { AlertsContext } from "../../utils/providers/alerts";
import { ModalContext } from "../../utils/providers/modal";

import Button from "../../components/design-system/ui/button/button";
import Input from "../../components/design-system/ui/inputs/input";
import File from "../../components/design-system/ui/inputs/file";
import Textarea from "../../components/design-system/ui/inputs/textarea";
import Checkbox from "../../components/design-system/ui/checkbox/checkbox";
import RadioButton from "../../components/design-system/ui/radio-button/radio-button";
import Select from "../../components/design-system/ui/select/select";
import MultipleSelect from "../../components/design-system/ui/select/multiple-select";
import SearchSelect from "../../components/design-system/ui/select/search-select";
import Badge from "../../components/design-system/ui/badge/badge";
import Toggle from "../../components/design-system/ui/toggle/toggle";
import Toast from "../../components/design-system/ui/toast/toast";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Sandbox() {
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [radioChecked, setRadioChecked] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedSearchOption, setSelectedSearchOption] = useState([]);
    const [selectedMultipleOption, setSelectedMultipleOption] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [files, setFiles] = useState(null);

    const { pushAlert } = useContext(AlertsContext);
    const callAlert = () => {
        pushAlert({ type: "SUCCESS", title: "It's working!", body: "Nice one pal" });
    }

    const { showModal } = useContext(ModalContext);
    const displayModal = () => {
        showModal({
            type: "SUCCESS",
            title: "It's working!",
            body: "Nice one pal",
            cancel: {
                label: "Cancel",
                action: cancelledAction
            },
            next: {
                label: "Keep going!",
                action: nextAction
            }
        });
    }

    const cancelledAction = () => {
        console.log("cancelled");
    }

    const nextAction = () => {
        console.log("next");
    }

    console.log(files)

    return (
        <div className="sandbox-holder" style={{ background: "#fff", padding: "50px" }}>
            <Input
                type="text"
                showLabel={true}
                value={value}
                onChange={setValue}
                label="Client search"
                placeholder="Start typing..."
                icon={<FontAwesomeIcon icon={faSearch} />}
                note="This is a note to put in here"
                error=""
            />
            <br />
            <Textarea placeholder="Test" resize={false} />
            <br />
            <File
                placeholder="Choose logo"
                value="Logo chosen"
                accept="image/jpeg, image/png"
                onChange={setFiles} />
            <br />
            <Button
                label="Primary Button"
                loading={loading}
                loadingText="Loading..."
                onClick={() => setLoading(true)}
            />
            <br />
            <Select
                placeholder="Choose from dropdown"
                value={selectedOption.value}
                onSelect={setSelectedOption}
                options={{ option1: "Option 1", option2: "Option 2" }}
            />
            <br />
            <Checkbox
                label="Checkbox option"
                checked={checked}
                onClick={() => setChecked((checked) => !checked)}
            />
            <br />
            <RadioButton
                label="Radio option"
                checked={radioChecked}
                onClick={() => setRadioChecked((radioChecked) => !radioChecked)}
            />
            <br />
            <div style={{ width: "200px" }}>
                <MultipleSelect
                    label="Your house"
                    showLabel={true}
                    placeholder="Choose from dropdown"
                    onSelect={setSelectedMultipleOption}
                    options={{
                        option1: "Option 1",
                        option2: "Another option",
                        option3: "Option 3",
                        option4: "Option 4",
                        option5: "Option 5",
                    }}
                />
            </div>
            <br />
            <div style={{ width: "250px" }}>
                <SearchSelect
                    placeholder="Choose from dropdown"
                    value={selectedSearchOption.value}
                    onSelect={setSelectedSearchOption}
                    options={{
                        option1: "Option 1",
                        option2: "Another option",
                        option3: "Option 3",
                        option4: "Option 4",
                        option5: "Option 5",
                    }}
                />
            </div>
            <br />
            <Badge label="Default pill" />
            <Badge type="POSITIVE" label="Positive pill" />
            <Badge type="AMBER" label="Amber pill" />
            <Badge type="NEGATIVE" label="Negative pill" />
            <Badge type="INFO" label="Info pill" />
            <br /><br />
            <Toggle
                on={toggle}
                onClick={() => setToggle((toggle) => !toggle)}
                label="Turn on notification" />
            <br />
            <Toast title="This is an alert" />
            <br />
            <Toast
                type="SUCCESS"
                title="Success"
                body="This action was successful" />
            <br />
            <Toast
                type="ALERT"
                title="Warning"
                body="This action has a warning with some longer form text in it. This will be used for more information." />
            <br />
            <Button
                label="Call alert"
                onClick={() => callAlert()}
            />
            <br />
            <Button
                label="Show modal"
                onClick={() => displayModal()}
            />
        </div>
    );
}

export default Sandbox;
