// Import action types
import * as actionTypes from '../actions';

// Setup an inital state
const initState = {
    onboard_package: null
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.ONBOARD_PACKAGE:
            return {
                ...state,
                onboard_package: action.payload
            }
        default:
            return state;
    }
};

export default reducer;