import React, { useState, useEffect } from "react";
import { db, auth } from "../../../utils/firebase";

/**
 * UI components
 */
import Notification from "./notification/notification";

/**
 * Functional component to return the notification toggles for the users
 */
function Notifications() {
    const [notifications, setNotifications] = useState({});

    /**
     * On component load, pull
     */
    useEffect(() => {
        /**
         * Pull the user document from the database
         */
        db.doc(`users/${auth.currentUser.uid}`).get().then((userDoc) => {
            /**
             * Deconstruct the notifications from their document
             */
            const { notifications } = userDoc.data() || {};
            /**
             * Push those notification toggles into the state
             */
            setNotifications(notifications);
        });
    }, []);

    /**
     * Update the local copy of the notifications for this user without refreshing the state
     */
    const updateLocalNotifications = (notification, direction) => {
        /**
         * Update the local copy of the notifications
         */
        setNotifications((notifications) => ({
            ...notifications,
            [notification]: direction,
        }));
    }

    return (
        <>
            <div className="edit-details-block">
                <h2>Notifications</h2>
                <p>Change what notifications you want to recieve from FEEVAL</p>

                <Notification
                    toggled={notifications.CONTENT_UPLOADED}
                    type="CONTENT_UPLOADED"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Content Uploaded</h1>
                    <p>When content has been uploaded to a project you're following</p>
                </Notification>
                <Notification
                    toggled={notifications.CONTENT_APPROVED_AGENCY}
                    type="CONTENT_APPROVED_AGENCY"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Content Approved</h1>
                    <p>When a client has approved a piece of content you're following</p>
                </Notification>
                <Notification
                    toggled={notifications.CONTENT_READY_FOR_DOWNLOAD}
                    type="CONTENT_READY_FOR_DOWNLOAD"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Content Ready for Download</h1>
                    <p>When content you're following is marked ready for download</p>
                </Notification>
                <Notification
                    toggled={notifications.CONTENT_DOWNLOADED}
                    type="CONTENT_DOWNLOADED"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Content Downloaded</h1>
                    <p>When a client has downloaded some content</p>
                </Notification>
                <Notification
                    toggled={notifications.FEEDBACK_LEFT}
                    type="FEEDBACK_LEFT"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Feedback Left</h1>
                    <p>When there has been some feedback left on content you're following</p>
                </Notification>
                <Notification
                    toggled={notifications.CLIENT_ASSIGNED}
                    type="CLIENT_ASSIGNED"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Client Assigned</h1>
                    <p>When you've been assigned a client</p>
                </Notification>
                <Notification
                    toggled={notifications.PROJECT_ASSIGNED}
                    type="PROJECT_ASSIGNED"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Project Assigned</h1>
                    <p>When you've been assigned to a project</p>
                </Notification>
                <Notification
                    toggled={notifications.TASK_ASSIGNED}
                    type="TASK_ASSIGNED"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Task Assigned</h1>
                    <p>When you've been assigned a new task</p>
                </Notification>
                <Notification
                    toggled={notifications.TASK_COMPLETED}
                    type="TASK_COMPLETED"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Task Completed</h1>
                    <p>When a task you're following has been completed</p>
                </Notification>
                <Notification
                    toggled={notifications.INVITED_TO_AGENCY}
                    type="INVITED_TO_AGENCY"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Agency Invite</h1>
                    <p>When you've been invited to collaborate with a new agency</p>
                </Notification>
                <Notification
                    toggled={notifications.MARKETING}
                    type="MARKETING"
                    bubbleToggle={(notification) => updateLocalNotifications(notification)}>
                    <h1>Marketing</h1>
                    <p>Promotional offers and platform news &amp; updates</p>
                </Notification>
            </div>
        </>
    )
}

export default Notifications;