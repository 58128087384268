import { db, auth } from "./firebase";
import * as errors from "./errors";

/**
 * Attempts to fetch the users first name or return their email address if no name
 * has been set by them
 */
export const fetchNameOrEmail = async (uid) => {
    /**
     * Build a promise to return the value
     */
    const user = new Promise(async (res, rej) => {
        await db.doc(`users/${uid}`).get().then(doc => {
            if (doc.exists) {
                const username = doc.data().name || doc.data().email;
                const splitName = username.split(" ");
                return res(splitName[0]);
            } else {
                return rej({ error: errors.NO_DOCUMENT });
            }
        });
    });
    /**
     * Return the result
     */
    return await user;
}

/**
 * Fetch a user record
 */
export const fetchUser = async (userID) => {
    /**
     * If the userID wasn't passed in
     */
    if (!userID) {
        return { error: errors.MISSING_PARAMS };
    }
    /**
     * Make sure only authenticated users are calling this function
     */
    if (auth.currentUser) {
        /**
         * Make a call to the database for the user document
         */
        const user = await db.doc(`users/${userID}`).get().then(userDoc => {
            /**
             * If the user document exists
             */
            if (userDoc.exists) {
                return { id: userID, ...userDoc.data() }
            } else {
                return { error: errors.NO_DOCUMENT };
            }
        });
        return user;
    } else {
        return { error: errors.NO_AUTH };
    }
}

/**
 * Fetch an agency record
 */
export const fetchAgency = async (agencyID) => {
    /**
     * If the agencyID wasn't passed in
     */
    if (!agencyID) {
        return { error: errors.MISSING_PARAMS };
    }
    /**
     * Make sure only authenticated users are calling this function
     */
    if (auth.currentUser) {
        /**
         * Make a call to the database for the agency document
         */
        const agency = await db.doc(`agencies/${agencyID}`).get().then(agencyDoc => {
            /**
             * If the agency exists
             */
            if (agencyDoc.exists) {
                /**
                 * If the current user is found in the agencies user list
                 */
                if (agencyDoc.data().users.includes(auth.currentUser.uid)) {
                    return { id: agencyID, ...agencyDoc.data() }
                } else {
                    return { error: errors.NOT_ALLOWED };
                }
            } else {
                return { error: errors.NO_DOCUMENT };
            }
        });
        return agency;
    } else {
        return { error: errors.NO_AUTH };
    }
}

/**
 * Check the userID passed in aganst the "premium_users" array on the agencyID passed 
 * in to see if they have access to the premium features
 */
export const checkForPremium = async (userID, agencyID) => {
    /**
     * If there was no userID passed in
     */
    if (!userID) {
        return { error: errors.MISSING_PARAMS };
    }
    /**
     * Make sure only authenticated users are calling this function
     */
    if (auth.currentUser) {
        /**
         * Get the user document exists
         */
        const user = await fetchUser(userID);
        /**
         * If it does
         */
        if (!user.error) {
            /**
             * Get the agency document
             */
            const agency = await fetchAgency(agencyID);
            /**
             * If it exists
             */
            if (!agency.error) {
                /**
                 * Check to see if the userID passed in is present in the "premium_users" array
                 */
                if (agency.premium_users?.includes(userID)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return { error: errors.NO_DOCUMENT };
            }
        } else {
            return { error: errors.NO_DOCUMENT };
        }
    } else {
        return { error: errors.NO_AUTH };
    }
}