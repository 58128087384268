import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { db } from "../../../utils/firebase";
import { AlertsContext } from "../../../utils/providers/alerts";
import { ModalContext } from "../../../utils/providers/modal";

/**
 * UI components
 */
import Button from "../../../components/design-system/ui/button/button";
import Input from "../../../components/design-system/ui/inputs/input";
import Toggle from "../../../components/design-system/ui/toggle/toggle";

/**
 * Functional component to return the edit page for the project currently in scope
 */
function Edit(props) {
    const [projectTitle, setProjectTitle] = useState("");
    const [originalProjectTitle, setOriginalProjectTitle] = useState("");
    const [savingTitle, setSavingTitle] = useState(false);
    const [redirect, setRedirect] = useState("");
    const [downloadEnabled, setDownloadEnabled] = useState(false);

    /**
     * Get the clientID from the props
     */
    const { clientID, projectID } = props;

    /**
     * Deconstruct the showModal fucntion from the modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Deconstruct the pushAlert function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * When the clientID and projectID are updated
     */
    useEffect(() => {
        /**
         * If they are both not empty
         */
        if (clientID && projectID) {
            /**
             * Fetch the project document from the database
             */
            db.doc(`clients/${clientID}/projects/${projectID}`).get().then((projectDoc) => {
                /**
                 * Deconstruct the title from the document
                 */
                const { title, download } = projectDoc.data();
                /**
                 * Update the state with it
                 */
                setProjectTitle(title);
                setOriginalProjectTitle(title);
                setDownloadEnabled(download);
            });
        }
    }, [clientID, projectID]);

    /**
     * When the downloading toggle is updated
     */
    useEffect(() => {

    }, [downloadEnabled]);

    /**
     * Save the project title in the local state tothe project document
     */
    const saveProjectTitle = () => {
        /**
         * Update the state
         */
        setSavingTitle(true);
        /**
         * Update the project document with the new title
         */
        db.doc(`clients/${clientID}/projects/${projectID}`).set({
            title: projectTitle
        }, { merge: true });
        /**
         * Push an alert for the update
         */
        pushAlert({
            type: "SUCCESS",
            title: "Project title updated",
        });
        /**
         * Update the state
         */
        setSavingTitle(false);
    }

    /**
     * Toggle the download option for this project
     */
    const toggleDownload = () => {
        /**
         * We need to get the opposite of whats in state because it will take a moment to update
         */
        const enabled = !downloadEnabled;
        /**
         * Toggle the downloading
         */
        setDownloadEnabled((downloadEnabled) => !downloadEnabled);
        /**
         * Update the project document in the database with the download toggle
         */
        db.doc(`clients/${clientID}/projects/${projectID}`).set({
            download: enabled,
        }, { merge: true });
        /**
         * Push an alert into the DOM
         */
        pushAlert({
            type: "SUCCESS",
            title: enabled ? "Downloading enabled" : "Downloading disabled",
        });
    }

    /**
     * Check the removal of the client before proceeding with a modal
     */
    const checkProjectRemoval = () => {
        /**
         * Show a modal confirming before continuing the removal process
         */
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            body: "Once a project is removed all feedback and content associated will be lost forever - no going back!",
            cancel: {
                label: "Nevermind",
                action: () => { return false; }
            },
            next: {
                label: "Yes, I'm sure",
                action: removeProject
            }
        });
    }

    /**
     * Remove the project from the client and redirect the user back to the projects list.
     * 
     * A cloud function will handle the removal of various other documents and references along with the media 
     * associated with the project from the storage bucket.
     */
    const removeProject = async () => {
        /**
         * Remove the project document
         */
        await db.doc(`clients/${clientID}/projects/${projectID}`).delete();
        /**
         * Push an alert to the DOM
         */
        pushAlert({
            type: "SUCCESS",
            title: "Project removed",
        });
        /**
         * Update the state with a redirect back to the client projects grid
         */
        setRedirect(`/clients/${clientID}/projects`);
    }

    /**
     * If there is a redirect set in the state (for example to /clients/clientID/projects after this 
     * project has been removed)
     */
    if (redirect) {
        return <Redirect to={redirect} />;
    } else {
        return (
            <>
                <div className="edit-details-block">
                    <h2>Update Title</h2>
                    <p>Change the title of this project.</p>
                    <Input
                        placeholder="Project title:"
                        value={projectTitle}
                        onChange={setProjectTitle} />
                    <Button
                        label="Save Title"
                        loading={savingTitle}
                        loadingText="Saving..."
                        disabled={originalProjectTitle === projectTitle}
                        onClick={() => saveProjectTitle()} />
                </div>

                <div className="edit-details-block">
                    <h2>Downloading</h2>
                    <p>Enable the downloading of content by stakeholders assigned to this project.</p>
                    <Toggle
                        on={downloadEnabled}
                        onClick={() => toggleDownload()}
                        label="Enable downloading" />
                </div>

                <div className="edit-details-block">
                    <h2>Remove Project</h2>
                    <p>When you remove a project from FEEVAL, all the content associated with it will be <strong>permanently</strong> deleted with no recovery available. Please ensure you no longer require access to these files.</p>
                    <Button
                        type="NEGATIVE"
                        label="Remove Project"
                        loadingText="Removing..."
                        onClick={() => checkProjectRemoval()} />
                </div>
            </>
        );
    }
}

export default Edit;