import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { db, auth, storage } from "../../../utils/firebase";
import { AlertsContext } from "../../../utils/providers/alerts";
import { ModalContext } from "../../../utils/providers/modal";
import "./edit.scss";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";
import Input from "../../design-system/ui/inputs/input";
import File from "../../design-system/ui/inputs/file";

/**
 * Required to generate the ID for the new content document
 */
const random = require("randomstring");

/**
 * Functional component to return the edit page for the client in scope
 */
function Edit(props) {
    const [removing, setRemoving] = useState(false);
    const [originalClientName, setOriginalClientName] = useState("");
    const [clientName, setClientName] = useState("");
    const [savingName, setSavingName] = useState(false);
    const [clientLogo, setClientLogo] = useState({});
    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [clientLogoURL, setClientLogoURL] = useState("");
    const [clientLogoError, setClientLogoError] = useState("");
    const [redirect, setRedirect] = useState("");

    /**
     * Get the clientID from the props
     */
    const { clientID } = props;

    /**
     * Deconstruct the showModal fucntion from the modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Deconstruct the pushAlert function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On update of the clientID
     */
    useEffect(() => {
        /**
         * Fetch the client document from teh database
         */
        db.doc(`clients/${clientID}`).get().then((clientDoc) => {
            /**
             * deconstruct the name from the client document
             */
            const { name } = clientDoc.data();
            /**
             * Push their name into the local state
             */
            setClientName(name);
            setOriginalClientName(name);
        });
    }, [clientID]);

    /**
     * When the client logo file is updates in the state
     */
    useEffect(() => {
        /**
         * If there is a file present in the state
         */
        if (clientLogo[0]) {
            /**
             * Reset any erorrs we might have in the local state
             */
            setClientLogoError("");
            /**
             * Get the file details from the file object
             */
            const { size, type } = clientLogo[0];
            /**
             * Setup some paramters for allowed file types and get the size in MB
             */
            const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
            const sizeInMB = size / 1024 / 1024;
            /**
             * First make sure it matches the allowed types
             */
            if (!allowedFormats.includes(type)) {
                /**
                 * Push an error to the state if it doesn't match
                 */
                setClientLogoError("This file format is not supported");
            } else if (sizeInMB >= 2) {
                /**
                 * Push an error if the size of the file is too big
                 */
                setClientLogoError("Please use a file less than 2MB");
            }
        }
    }, [clientLogo]);

    /**
     * Check the removal of the client before proceeding with a modal
     */
    const checkClientRemoval = () => {
        /**
         * Show a modal confirming before continuing the removal process
         */
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            body: "Once a client is removed all feedback and content associated will be lost forever - no going back!",
            cancel: {
                label: "Nevermind",
                action: () => { return false; }
            },
            next: {
                label: "Yes, I'm sure",
                action: removeClient
            }
        });
    }

    /**
     * Remove the client from the agency and redirect the user back to the clients list.
     * 
     * A cloud function will handle the removal of various other documents and references along with the media 
     * associated with the client from the storage bucket.
     */
    const removeClient = async () => {
        /**
         * Remove the client document
         */
        await db.doc(`clients/${clientID}`).delete();
        /**
         * Update the state with a redirect back to the clients grid
         */
        setRedirect("/clients");
    }

    /**
     * Save the new client name into their database document
     */
    const saveClientName = () => {
        /**
         * Update the state
         */
        setSavingName(true);
        /**
         * Set the client name into the database
         */
        db.doc(`clients/${clientID}`).set({
            name: clientName,
        }, { merge: true });
        /**
         * Show an alert to feedback to the user
         */
        pushAlert({
            type: "SUCCESS",
            title: "Client name updated"
        });
        /**
         * Update the state
         */
        setSavingName(false);
    }

    /**
     * Upload the new client logo to the storage bucket and assign the original URL to their document, 
     * a cloud function will take over and save the thumbnail copies when they're ready
     */
    const uploadClientLogo = async () => {
        /**
         * Update the state
         */
        setUploadingLogo(true);
        /**
         * Upload the file
         */
        const logoURL = await handleFileUpload(clientLogo[0]);
        /**
         * Save the file info into the database
         */
        await db.doc(`clients/${clientID}`).set({
            logo: {
                _64: null,
                _256: null,
                _512: null,
                original: logoURL,
            },
        }, { merge: true });
        /**
         * Show an alert to feedback to the user
         */
        pushAlert({
            type: "SUCCESS",
            title: "Client logo uploaded"
        });
        /**
         * Update the state
         */
        setClientLogo({});
        setUploadProgress(0);
        setUploadingLogo(false);
    }

    /**
     * Handles the uploading of the file into the sotrage bucket
     *
     * @param {file} file File object to upload to storage
     */
    const handleFileUpload = (file) => {
        /**
         * Generate a new ID for using on the document references for this file
         */
        const uploadID = random.generate(20);
        /**
         * Build out some metadata for the image
         */
        const meta = {
            customMetadata: {
                clientID,
                fileID: uploadID,
                fileName: file.name,
                uploadedBy: auth.currentUser.uid,
                awaitingTranscode: true,
                type: "client_logo",
            },
        };
        /**
         * Move the file into firebase storage
         */
        const upload = storage.ref(`clients/${clientID}/logos/${uploadID}/${uploadID}`).put(file, meta);
        /**
         * Return a promise listening to the upload progress
         */
        return new Promise((resolve, reject) => {
            /**
             * Setup the monitor for the file
             */
            monitorUploadProgress(upload, file.id).then(() => {
                /**
                 * When it's complete, get the downloadURL from the callback
                 */
                upload.snapshot.ref.getDownloadURL().then((url) => {
                    /**
                     * Update the state with the file whose upload is complete
                     */
                    setClientLogoURL(url);
                    /**
                     * Resolve the promise
                     */
                    resolve(url);
                });
            });
        });
    };

    /**
     * Attach a listener onto the upload job to stream the progress into state
     *
     * @param {object} upload Upload object reference on the storage item
     * @param {string} fileID ID of the file to be monitored
     */
    const monitorUploadProgress = (upload, fileID) => {
        return new Promise((resolve, reject) => {
            /**
             * Setup a listener on the upload process
             */
            upload.on("state_changed", (snapshot) => {
                /**
                 * Work out a progress percentage
                 */
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                /**
                 * Update the state with the progress of this file
                 */
                setUploadProgress(progress);
            }, (error) => console.log(error), () => {
                resolve();
            });
        });
    };

    /**
     * If there is a redirect set in the state (for example to /clients after this client has been removed)
     */
    if (redirect) {
        return <Redirect to={redirect} />;
    } else {
        return (
            <>
                <div className="edit-details-block">
                    <h2>Update Name</h2>
                    <p>Change the display name for this client throughout FEEVAL.</p>
                    <Input
                        placeholder="Client name:"
                        value={clientName}
                        onChange={setClientName} />
                    <Button
                        label="Save Name"
                        loading={savingName}
                        loadingText="Saving..."
                        disabled={originalClientName === clientName}
                        onClick={() => saveClientName()} />
                </div>

                <div className="edit-details-block">
                    <h2>Change Logo</h2>
                    <p>Set a logo to display alongside this client on FEEVAL.</p>
                    <File
                        placeholder="Choose file:"
                        onChange={setClientLogo}
                        note="File sizes up to 2MB permitted"
                        showFileNames={true}
                        accept={["image/jpeg", "image/jpg", "image/png"]}
                        error={clientLogoError} />
                    <Button
                        label="Save Logo"
                        loading={uploadingLogo}
                        loadingText={`Uploading (${uploadProgress}%)...`}
                        disabled={(!clientLogo?.length || clientLogoError)}
                        onClick={() => uploadClientLogo()} />
                </div>

                <div className="edit-details-block">
                    <h2>Remove Client</h2>
                    <p>When you remove a client from FEEVAL, all the content associated with their account will be <strong>permanently</strong> deleted with no recovery available. Please ensure you no longer require access to these files.</p>
                    <Button
                        type="NEGATIVE"
                        label="Remove Client"
                        loading={removing}
                        loadingText="Removing..."
                        onClick={() => checkClientRemoval()} />
                </div>
            </>
        );
    }
}

export default Edit;