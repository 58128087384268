import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { db, auth } from "../../utils/firebase";
import { AuthContext } from "../../utils/providers/auth";
import { ChevronRightIcon } from "../../utils/svgs";

/**
 * UI components & structure
 */
import Tile from "../../components/design-system/structure/tile/tile";
import Title from "../../components/design-system/structure/title/title";
import Navigation from "../../components/design-system/structure/navigation/navigation";

/**
 * Route components
 */
import Timeline from "./timeline/timeline";
import Edit from "../../containers/project/edit/edit";
import Error404 from "../errors/404";

/**
 * 
 * 
 * @param {object} props 
 * @returns
 */
function Project(props) {
    const [clientID, setClientID] = useState(null);
    const [projectID, setProjectID] = useState(null);
    const [permissions, setPermissions] = useState({});
    const [clientName, setClientName] = useState("");
    const [projectTitle, setProjectTitle] = useState("");

    /**
     * Deconstruct the user prop from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the url & ID from props
     */
    const { url } = props.match;
    const { id } = props.match.params;

    /**
     * When the project ID can be pulled from the URL path
     */
    useEffect(() => {
        /**
         * Get the clientID for this project from it's record on the users project document
         */
        db.doc(`users/${auth.currentUser.uid}/projects/${id}`).get().then((projectDoc) => {
            /**
             * Make sure this project exists before accessing it
             */
            if (projectDoc.exists) {
                /**
                 * Get the clientID from the document
                 */
                const { client } = projectDoc.data();
                /**
                 * Update the state with the clientID from it's document
                 */
                setClientID(client);
                /**
                 * Set the project ID into the local state
                 */
                setProjectID(id);
            }
        });
    }, [id]);

    /**
     * When the clientID has been pulled from the project document
     */
    useEffect(() => {
        /**
         * Setup a listener on the current users document for this client to watch for permissions
         */
        const unsubscribe = db.doc(`users/${auth.currentUser.uid}/clients/${clientID}`)
            .onSnapshot((userSnap) => {
                /**
                 * Make sure they have a record on their colelction for this client
                 */
                if (userSnap.exists) {
                    /**
                     * Get the permissions for this users access to the client
                     */
                    const { permissions } = userSnap.data();
                    /**
                     * Push the permissions onto the state
                     */
                    setPermissions(permissions);
                }
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, [clientID]);

    /**
     * When the clientID has been pulled from the project document
     */
    useEffect(() => {
        /**
         * Setup a listener to stream the client details into state
         */
        const unsubscribe = db.doc(`/clients/${clientID}`)
            .onSnapshot((clientSnap) => {
                /**
                 * Make sure this client document exists
                 */
                if (clientSnap.exists) {
                    /**
                     * Get the name for this client
                     */
                    const { name } = clientSnap.data();
                    /**
                     * Push the client name into state
                     */
                    setClientName(name);
                }
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, [clientID]);

    /**
     * When the clientID & projectID are present and the user has atleast feedback permissions
     */
    useEffect(() => {
        /**
         * Setup a listener to stream updates to the project document into state
         */
        const unsubscribe = db.doc(`clients/${clientID}/projects/${projectID}`)
            .onSnapshot((projectSnap) => {
                /**
                 * Make sure the project exists
                 */
                if (projectSnap.exists) {
                    /**
                     * Deconstruct the data we need from the snapshot
                     */
                    const { title } = projectSnap.data();
                    /**
                     * Update the state
                     */
                    setProjectTitle(title);
                }
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, [clientID, projectID, permissions?.FEEDBACK]);

    return (
        <Tile fullPage={true}>
            <Title>
                <h1>
                    {user.is_agency &&
                        <>
                            {/* Display a link back to clients */}
                            {user.is_agency && <NavLink to="/clients">Clients</NavLink>}

                            <ChevronRightIcon />

                            {/* Display a link back to this clients projects */}
                            {user.is_agency && <NavLink to={`/client/${clientID}/projects`}>{clientName}</NavLink>}

                            <ChevronRightIcon />
                        </>
                    }

                    {projectTitle}
                </h1>
            </Title>

            {user.is_agency &&
                <>
                    {/* Navigation routes */}
                    <Navigation>
                        {/* Projects link */}
                        <NavLink activeClassName="is-active" to={`${url}/timeline`}>
                            Content Timeline
                        </NavLink>

                        {/* Is the current user of agency type and has administrate permissions? */}
                        {(user.is_agency && permissions.ADMINISTRATE) &&
                            <>
                                {/* Edit details link */}
                                <NavLink activeClassName="is-active" to={`${url}/edit`}>
                                    Edit Details
                                </NavLink>
                            </>
                        }
                    </Navigation>
                </>
            }

            {/* Routes for the various content pages */}
            <Switch>
                {/* Timeline route */}
                <Route path="/project/:id/timeline" exact component={() =>
                    <Timeline clientID={clientID} projectID={projectID} permissions={permissions} />} />

                {/* Is the current user of agency type and has administrate permissions? */}
                {(user.is_agency && permissions.ADMINISTRATE) &&
                    <>
                        {/* Edit project details route */}
                        <Route path="/project/:id/edit" exact component={() =>
                            <Edit clientID={clientID} projectID={projectID} permissions={permissions} />} />
                    </>
                }

                {/* Any other page that doesn't have a route */}
                <Route path="*" component={Error404} />
            </Switch>
        </Tile>
    );
}

export default Project;