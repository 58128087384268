import React, { useEffect, useState } from "react";
import { db, auth } from "../../utils/firebase";
import "./projects.scss";

/**
 * UI components
 */
import Tile from "../../components/design-system/structure/tile/tile";
import Title from "../../components/design-system/structure/title/title";
import Project from "../../components/project/client/project";

/**
 * Functional component to return the projects page for the client user
 */
function Projects() {
    const [projects, setProjects] = useState([]);
    const [sortedProjects, setSortedProjects] = useState([]);
    const [displayMode, setDisplayMode] = useState("CARD");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the users "projects" subcollection
         */
        const unsubscribe = db.collection(`users/${auth.currentUser.uid}/projects`)
            .onSnapshot((projectsSnap) => {
                /**
                 * Loop over the project documents found
                 */
                projectsSnap.docChanges().forEach((change) => {
                    /**
                     * Project document added
                     */
                    if (change.type === "added") {
                        setProjects((projects) => [
                            ...projects,
                            {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }
                        ]);
                    }
                    /**
                     * Project document updated
                     */
                    if (change.type === "modified") {
                        setProjects((projects) => {
                            let updatedProjects = [...projects];
                            for (let i in projects) {
                                if (projects[i].id === change.doc.id) {
                                    updatedProjects[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedProjects;
                        });
                    }
                    /**
                     * Project document removed
                     */
                    if (change.type === "removed") {
                        setProjects((projects) => projects.filter((project) => project.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * When the project content snapshot updates the state
     */
    useEffect(() => {
        /**
         * Sort function, sorting by a lowercase version of the project title
         */
        const sortByTitle = (a, b) => {
            return a.title?.toLowerCase().localeCompare(b.title?.toLowerCase());
        }
        /**
         * Sort the content into date order
         */
        setSortedProjects([...projects.sort(sortByTitle)]);
    }, [projects]);

    /**
     * Append the project title passed back up from the listener on the client component to the local 
     * state here for sorting the project cards alphabetically.
     */
    const appendTitle = (projectID, title) => {
        /**
         * Update the relevant project object in the state
         */
        setProjects((projects) => {
            let updatedProjects = [...projects];
            for (let i in projects) {
                if (projects[i].id === projectID) {
                    updatedProjects[i] = {
                        ...projects[i],
                        title,
                    };
                    break;
                }
            }
            return updatedProjects;
        });
    }

    return (
        <Tile fullPage={true}>
            <Title hasBorder={true}>
                <h1>Your projects</h1>
            </Title>

            <div className={["projects-display", displayMode].join(" ")}>
                {/* Print out all the clients loaded in the state */}
                {sortedProjects.map((project) =>
                    <Project
                        key={project.id}
                        type={displayMode}
                        projectID={project.id}
                        clientID={project.client}
                        appendTitle={appendTitle} />
                )}
            </div>
        </Tile>
    );
}

export default Projects;