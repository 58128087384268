import React, { useState, createContext } from "react";

/**
 * Setup a context for storing data asociated to the toast alerts
 */
const AlertsContext = createContext();

/**
 * Create a context for the Alerts across the app. To run an alert the context can be accessed 
 * through the function which will push a new alert onto the local state here for displaying
 * 
 * @returns Provider for the alerts context
 */
const AlertsProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);

    /**
     * Push a new alert onto the state, thus passing it down the context
     * 
     * @type {const}
     */
    const pushAlert = ({ type, title, body }) => {
        /**
         * Generate a milliseconds timestamp for when this alert was created to use as the 
         * ID for removing later (not the best way but a quick way)
         */
        const timestamp_ms = new Date().getMilliseconds();
        /**
         * Update the state with the new alert
         */
        setAlerts((alerts) => [...alerts, { id: timestamp_ms, type, title, body }]);
    }

    /**
     * Dismiss the alert (remove the context & state)
     * 
     * @type {const}
     * @param {integer} alertID ID for the alert to dismiss, generated as a milliseconds timestamp
     */
    const dismissAlert = (alertID) => {
        /**
         * Remove the matching alert
         */
        setAlerts((alerts) => alerts.filter((alert) => alert.id !== alertID));
    }

    return (
        <AlertsContext.Provider value={{ alerts, pushAlert, dismissAlert }}>
            {children}
        </AlertsContext.Provider>
    );
}

export { AlertsContext, AlertsProvider };
