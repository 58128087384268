import React, { useContext, useEffect, useState } from 'react';
import { ContentContext } from '../../../utils/providers/content';
import { AlertsContext } from "../../../utils/providers/alerts";
import { db } from '../../../utils/firebase';

/**
 * UI components
 */
import TextArea from "../../design-system/ui/inputs/textarea";
import Button from "../../design-system/ui/button/button";
import userEvent from '@testing-library/user-event';

/**
 * Functional component that prints the HTML markup and provides the functionality for displaying and updating 
 * the 'Agency notes' box on the content tile page
 * 
 * @returns HTML markup for the content notes
 */
function Notes(props) {
    const [saving, setSaving] = useState(false);
    const [notes, setNotes] = useState("");

    /**
     * Get the content IDs from the context
     */
    const { ids, content } = useContext(ContentContext);

    /**
     * Get the push function for alerts
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Get the user object from props
     */
    const { user } = props;

    /**
     * When the content variable from the context has been updated
     */
    useEffect(() => {
        /**
         * Set the notes to the component state
         */
        setNotes(content?.notes || "");
    }, [content]);

    /**
     * Save the notes stored in the state to the database
     * 
     * @type {const}
     */
    const saveNotes = async () => {
        /**
         * Update the UI
         */
        setSaving(true);
        /**
         * Get the IDs for the content from the context
         */
        const { client, project, content } = ids;
        /**
         * Then update the database
         */
        await db.doc(`clients/${client}/projects/${project}/content/${content}`).set({
            notes: notes,
        }, { merge: true });
        /**
         * Update the UI
         */
        setSaving(false);
        /**
         * Push an alert to the DOM
         */
        pushAlert({ type: "SUCCESS", title: "Notes saved to content" });
    }

    return (
        <div className="agency-notes">
            <p className="title small">Agency Notes</p>
            <div className="notes-box">
                {/* Is the user allowed to edit this field */}
                {user.is_agency &&
                    <TextArea value={notes} onChange={setNotes} placeholder="Leave some additional comments..." />
                }

                {/* Is the user not allowed to edit this field */}
                {!user.is_agency &&
                    <TextArea readOnly={true} value={notes} placeholder="Leave some additional comments..." />
                }

                {/* Only show the save button if the current user has access to do so */}
                {user.is_agency &&
                    <Button
                        label="Save notes"
                        loading={saving}
                        loadingText="Saving..."
                        className="is-small"
                        onClick={() => saveNotes()} />
                }
            </div>
        </div>
    );
}

export default Notes;