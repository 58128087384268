import React from "react";

/**
 * Styles
 */
import Toggle from "../../../ui/toggle/toggle";

/**
 * UI components
 */
import Head from "../../../ui/card/head";

const Permission = (props) => (
    <div className="userli-permission">
        <div className="userli-permission-title">
            <Head>{props.children}</Head>
        </div>
        <div className="userli-permission-toggle" onClick={props.toggle}>
            <Toggle on={props.toggled} />
        </div>
    </div>
);

export default Permission;
