import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";
import "./projects.scss";

/**
 * UI components & structure blocks
 */
import AddProject from "../../project/add/add";
import Project from "../../project/project";

/**
 * Functional component to return the projects associated with the current client in scope
 */
function Projects(props) {
    const [projects, setProjects] = useState([]);
    const [displayMode, setDisplayMode] = useState("CARD");

    /**
     * Deconstruct the client ID from the props
     */
    const { clientID } = props;

    /**
     * When a clientID is present
     */
    useEffect(() => {
        /**
         * Setup a listener on the clients "project" collection to stream them into the DOM
         */
        const unsubscribe = db.collection(`clients/${clientID}/projects`)
            .orderBy("title")
            .where("users", "array-contains", auth.currentUser.uid)
            .onSnapshot((projectsSnap) => {
                /**
                 * Loop over the project documents found for this client
                 */
                projectsSnap.docChanges().forEach((change) => {
                    /**
                     * Project document added
                     */
                    if (change.type === "added") {
                        setProjects((projects) => [
                            ...projects,
                            {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }
                        ]);
                    }
                    /**
                     * Project document updated
                     */
                    if (change.type === "modified") {
                        setProjects((projects) => {
                            let updatedProjects = [...projects];
                            for (let i in projects) {
                                if (projects[i].id === change.doc.id) {
                                    updatedProjects[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedProjects;
                        });
                    }
                    /**
                     * Project document removed
                     */
                    if (change.type === "removed") {
                        setProjects((projects) => projects.filter((project) => project.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, [clientID]);

    return (
        <div className={["projects-display", displayMode].join(" ")}>
            {/* Add project form */}
            <AddProject clientID={clientID} />

            {/* Print out all the projects loaded in the state */}
            {projects.map((project) =>
                <Project
                    key={project.id}
                    type="CARD"
                    clientID={clientID}
                    project={project} />)}
        </div>
    );
}

export default Projects;