import React from "react";
import "./continue.scss";

/**
 * 
 */
import FeevalLogo from "../../assets/images/feeval-logo.png";

/**
 * 
 */
function Continue() {
    return (
        <div className="continue-splash">
            <div className="continue-box">
                <img src={FeevalLogo} alt="Feeval logo" />
                <p>Please continue on a laptop or desktop device with a larger screen</p>
                <small>Our mobile app is coming soon</small>
            </div>
        </div>
    );
}

export default Continue;