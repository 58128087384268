import React from "react";
import "./tile.scss";

/**
 * Functional component to wrap children passed in inside a "screen tile"
 * 
 * @param {object} props Children elements to sit inside the tile and toggles for tile display options
 * @returns Wrapper HTML for holding DOM elements in a "screen tile"
 */
function Tile(props) {
    return (
        <div className={["screen-tile", props.className, props.fullPage && "is-full-page"].join(" ")}>
            {props.children}
        </div>
    );
}

export default Tile;