import { db, auth } from './firebase';
import * as errors from './errors';

/**
 * Fetch a user record
 */
export const fetchUser = async (userID) => {
    if (!userID) {
        return { error: errors.MISSING_PARAMS };
    }
    if (auth.currentUser) {
        const user = await db.doc(`users/${userID}`).get().then(userDoc => {
            if (userDoc.exists) {
                return { id: userID, ...userDoc.data() }
            } else {
                return { error: errors.NO_DOCUMENT };
            }
        });
        return user;
    } else {
        return { error: errors.NO_AUTH };
    }
}

/**
 * Fetch an agency record
 */
export const fetchAgency = async (agencyID) => {
    if (!agencyID) {
        return { error: errors.MISSING_PARAMS };
    }
    if (auth.currentUser) {
        const agency = await db.doc(`agencies/${agencyID}`).get().then(agencyDoc => {
            if (agencyDoc.exists) {
                if (agencyDoc.data().users.includes(auth.currentUser.uid)) {
                    return { id: agencyID, ...agencyDoc.data() }
                } else {
                    return { error: errors.NOT_ALLOWED };
                }
            } else {
                return { error: errors.NO_DOCUMENT };
            }
        });
        return agency;
    } else {
        return { error: errors.NO_AUTH };
    }
}

/**
 * Fetch a client document
 */
export const fetchClient = async (clientID) => {
    if (!clientID) {
        return { error: errors.MISSING_PARAMS };
    }
    if (auth.currentUser) {
        const client = await db.doc(`clients/${clientID}`).get().then(clientDoc => {
            if (clientDoc.exists) {
                if (clientDoc.data().users.includes(auth.currentUser.uid)) {
                    return { id: clientID, ...clientDoc.data() }
                } else {
                    return { error: errors.NOT_ALLOWED };
                }
            } else {
                return { error: errors.NO_DOCUMENT };
            }
        });
        return client;
    } else {
        return { error: errors.NO_AUTH };
    }
};

/**
 * Fetch a client project
 */
export const fetchProject = async (client, projectID) => {
    if (!client || !projectID) {
        return { error: errors.MISSING_PARAMS };
    }
    if (auth.currentUser) {
        if (client.users.includes(auth.currentUser.uid)) {
            const project = await db.doc(`clients/${client.id}/projects/${projectID}`).get().then(projectDoc => {
                if (projectDoc.exists) {
                    if (projectDoc.data().users.includes(auth.currentUser.uid)) {
                        return { id: projectID, ...projectDoc.data() }
                    } else {
                        return { error: errors.NOT_ALLOWED };
                    }
                } else {
                    return { error: errors.NO_DOCUMENT };
                }
            })
            return project;
        } else {
            return { error: errors.NOT_ALLOWED };
        }
    } else {
        return { error: errors.NO_AUTH };
    }
}

/**
 * Fetch a content item
 */
export const fetchContent = async (client, project, contentID) => {
    if (!client || !project || !contentID) {
        return { error: errors.MISSING_PARAMS };
    }
    if (auth.currentUser) {
        if (client.users.includes(auth.currentUser.uid) && project.users.includes(auth.currentUser.uid)) {
            const content = await db.doc(`clients/${client.id}/projects/${project.id}/content/${contentID}`).get().then(contentDoc => {
                if (contentDoc.exists) {
                    return { id: contentID, ...contentDoc.data() }
                } else {
                    return { error: errors.NO_DOCUMENT };
                }
            });
            return content;
        } else {
            return { error: errors.NOT_ALLOWED };
        }
    } else {
        return { error: errors.NO_AUTH };
    }
}

/**
 * Check the user notification toggle, response with error, true for on, or false for off
 */
export const checkUserNotificationToggle = async (userID, flag) => {
    if (!userID) {
        return { error: errors.MISSING_PARAMS };
    }
    if (auth.currentUser) {
        const user = await fetchUser(userID);
        if (!user.error) {
            const notifications = user.notifications;
            if (notifications[flag.permission]) {
                return true;
            } else {
                return false;
            }
        } else {
            return user;
        }
    } else {
        return { error: errors.NO_AUTH };
    }
}

/**
 * Fetch a content view document
 */
export const fetchAgencyInvite = async (id) => {
    if (!id) {
        return { error: errors.MISSING_PARAMS };
    }
    if (auth.currentUser) {
        const invite = await db.doc(`invites/${id}`).get().then(doc => {
            if (doc.exists) {
                if (doc.data().user === auth.currentUser.uid) {
                    return { ...doc.data() }
                } else {
                    return { error: errors.NOT_ALLOWED };
                }
            } else {
                return { error: errors.NO_DOCUMENT };
            }
        });
        return invite;
    } else {
        return { error: errors.NO_AUTH };
    }
}
