import React from "react";
import "./input.scss";

/**
 * Functional component for a textarea inputfield.
 * 
 * Values are passed back up the props to the containers they are embedded in and the values are 
 * passed back into the input to keep it as a controlled component.
 * 
 * @param {object} props Props passed in for the input
 * @returns HTML markup and functionality for the inputfield
 */
function Textarea(props) {
    return (
        <div className={["ui-input-wrapper", props.wrapperClass, props.error && "has-error"].join(" ")}>
            {/* Input wrapper for the input and icon should one be passed in */}
            <div className="ui-input-block">
                {/* Icon to display in the input */}
                {props.icon && <div className="ui-input-icon">{props.icon}</div>}

                {/* Placeholder to sit above the field when there is a value */}
                {((props.placeholder || props.label) && props.showLabel) &&
                    <div className={["ui-input-upper-placeholder", props.value.length > 0 && "active"].join(" ")}>
                        {props.label || props.placeholder}
                    </div>
                }

                {/* Input field itself */}
                <textarea
                    name={props.name}
                    id={props.id}
                    type={props.type || "text"}
                    className={["ui-input-text", props.className, props.icon && "has-icon", !props.resize && "no-resize"].join(" ")}
                    readOnly={props.readOnly}
                    placeholder={props.placeholder}
                    required={props.required}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    rows={props.rows || 4} />
            </div>

            {/* Is there an error or note to display */}
            {props.error ? <small>{props.error}</small> :
                props.note && <small>{props.note}</small>}
        </div>
    );
}

export default Textarea;