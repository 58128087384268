import React from "react";
import "./title.scss";

/**
 * Functional component to display a title for the tile
 * 
 * @param {object} props Children elements to sit inside the title block
 * @returns Wrapper HTML for displaying the tile title
 */
function Title(props) {
    return (
        <div className={[
            "tile-title",
            props.className,
            props.hasBorder && "has-border",
            props.isFlex && "is-flex",
        ].join(" ")}>
            {props.children}
        </div>
    );
}

export default Title;