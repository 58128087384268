import React, { useEffect, useState, useRef } from "react";

/**
 * Functional component to print the HTML for the comment that sits above the content tiles
 *
 * @param {object} props
 * @returns HTML markup for the comment that overlays above the content tile
 */
function Comment(props) {
    const [display, setDisplay] = useState(false);

    const commentRef = useRef(null);

    /**
     * Deconstruct the values from context
     */
    const { comment, media, toggled } = props;

    /**
     * On update of either the comments secondsAt or the medias secondsAt. It's likely going to the media secondsAt value changing here as
     * the video plays out.
     */
    useEffect(() => {
        /**
         * Do the seconds at match on the comment and where the media currently is?
         */
        comment.secondsAt === media.secondsAt ? setDisplay(true) : setDisplay(false);
    }, [comment.secondsAt, media.secondsAt]);

    /**
     * Is this comment toggled?
     */
    if (toggled) {
        /**
         * Scroll the comment into view on the screen
         */
        commentRef.current.scrollIntoView({ behaviour: "smooth", block: "center" });
    }

    /**
     * Does this comment need to show at a particular time?
     */
    if (comment?.secondsAt) {
        return (
            <div ref={commentRef} className={["cview-comment", toggled ? "toggled" : ""].join(" ")} style={{ top: `${comment.y_pos}%`, left: `${comment.x_pos}%`, display: display ? "block" : "none" }}>
                <div className="cview-comment-inner"></div>
            </div>
        );
    } else {
        return (
            <div ref={commentRef} className={["cview-comment", toggled ? "toggled" : ""].join(" ")} style={{ top: `${comment.y_pos}%`, left: `${comment.x_pos}%` }}>
                <div className="cview-comment-inner"></div>
            </div>
        );
    }
}

export default Comment;
