import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";

/**
 * UI components
 */
import { CaretDownIcon, CaretUpIcon } from "../../../utils/svgs";

/**
 * Functional component to return the revision ratio card on the dashboard
 * 
 * @param {object} props Props object should contain currentPeriod & previousPeriod values
 */
function RevisionRatio(props) {
    const [currentRatio, setCurrentRatio] = useState(0);
    const [previousRatio, setPreviousRatio] = useState(0);
    const [difference, setDifference] = useState(0);
    const [differenceDirection, setDifferenceDirection] = useState("NEUTRAL");

    /**
     * Deconstruct the user preferences from the props
     */
    const { currentPeriod, previousPeriod } = props;

    /**
     * When the currentPeriod & previousPeriod are updated from the dashboard
     */
    useEffect(() => {

    }, [currentPeriod, previousPeriod]);

    /**
     * When the currentRatio and previousRatio are updated
     */
    useEffect(() => {
        /**
         * Work out the difference between the two as a percentage
         */
        const difference = Math.round((currentRatio - previousRatio) * 100);
        /**
         * Update the state with the relevant direction
         */
        if (difference === 0) {
            setDifferenceDirection("NEUTRAL");
        } else if (difference > 0) {
            setDifferenceDirection("POSITIVE");
        } else if (difference < 0) {
            setDifferenceDirection("NEGATIVE");
        }
        /**
         * Push the difference into the local state too
         */
        setDifference(difference);
    }, [currentRatio, previousRatio]);

    return (
        <div className="statistic-card">
            <h6>Revision Ratio</h6>
            <h5>
                <span>{currentRatio}</span>
                <small className={differenceDirection}>
                    {/* Is the difference neutral */}
                    {(differenceDirection === "NEUTRAL") && "-"}

                    {/* Is the difference positive */}
                    {(differenceDirection === "POSITIVE") && <CaretUpIcon />}

                    {/* Or is the difference negative */}
                    {(differenceDirection === "NEGATIVE") && <CaretDownIcon />}

                    {difference}%
                </small>
            </h5>
        </div>
    );
}

export default RevisionRatio;