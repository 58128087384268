import React, { useRef, useEffect, useContext } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { db, auth, getTimestamp } from "../../../utils/firebase";
import { getUserAgent } from "../../../utils/helpers";
import CanvasDraw from "react-canvas-draw";

/**
 * Functional component for the 
 * 
 * @returns Returns the HTML markup and functionality for the drawing tile
 */
function Drawing(props) {
    /**
     * Get the set canvas data function from the content context, we'll need to store the data 
     * in here so it can be synced with the comment and saved into the database
     */
    const {
        ids,
        media,
        feedbackTool,
        brushColour,
        brushSize,
        undoDraw,
        setUndoDraw,
        saveState,
        setSaveState,
        canvasData,
        setCanvasData,
        feedbackComment,
        setFeedbackComment,
        setShowFeedbackComment,
    } = useContext(ContentContext);

    /**
     * If there is some existing canvas data passed in via the props
     */
    useEffect(() => {
        setTimeout(() => {
            props.canvasData && loadSaveData(props.loadData);
        }, 100);
    }, [props.containerSize]);

    /**
     * If the user has toggle an undo on the canvas
     */
    useEffect(() => {
        /**
         * If we are in an undo state from the context
         */
        undoDraw && undoCanvasDraw();
    }, [undoDraw]);

    /**
     * If the user has toggle an undo on the canvas
     */
    useEffect(() => {
        /**
         * If we are in an undo state from the context
         */
        saveState && saveCanvasFeedback();
    }, [saveState]);

    /**
     * Canvas ref
     */
    const canvasRef = useRef(null);

    /**
     * Get the save data for the drawing from the canvas api
     * 
     * @type {const}
     */
    const getSaveData = () => {
        /**
         * Get the save data
         */
        const canvasSaveData = canvasRef.current.getSaveData();
        /**
         * Push the save data into content context
         */
        setCanvasData(canvasSaveData);
    }

    /**
     * Load the save data for the drawing into the canvas api
     * 
     * @type {const}
     */
    const loadSaveData = () => {
        /**
         * Load the drawing data
         */
        canvasRef.current && canvasRef.current.loadSaveData(props.canvasData);
    }

    /**
     * Undoes the last drawing made on the canvas
     * 
     * @type {const}
     */
    const undoCanvasDraw = () => {
        /**
         * Undo the last action
         */
        canvasRef.current.undo();
        /**
         * Then update the context back to a false for the undo to wait for another call
         */
        setUndoDraw(false);
    }

    /**
     * Save the feedback data from the canvas api and text comment from the context into the database
     * 
     * @type {const}
     */
    const saveCanvasFeedback = async () => {
        /**
         * Make sure we have either comments or a drawing to save
         */
        if (canvasData) {
            /**
             * Cancel off the save state
             */
            setSaveState(false);
            /**
             * Deconstruct the IDs needed from the content context
             */
            const { client, project, content, file } = ids;
            /**
             * Get the current timestamp
             */
            const timestamp = await getTimestamp();
            /**
             * Add the feedback to the content sub-collection in the database
             */
            await db.collection(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments`).add({
                user: auth.currentUser.uid,
                date: timestamp,
                status: "active",
                task: null,
                comment: feedbackComment,
                useragent: getUserAgent(),
                type: feedbackTool,
                canvasData: canvasData,
                secondsAt: media.secondsAt || null,
            });
            /**
             * Reset canvas data & context
             */
            canvasRef.current.clear();
            setFeedbackComment("");
            setShowFeedbackComment(false);
        }
    }

    return (
        <CanvasDraw
            hideGrid
            ref={canvasRef}
            brushColor={brushColour}
            lazyRadius={0}
            catenaryColor="transparent"
            brushRadius={props.disabled ? 0 : brushSize}
            disabled={props.disabled}
            immediateLoading={true}
            onChange={() => getSaveData()}
            canvasWidth={props.containerSize.width}
            canvasHeight={props.containerSize.height}
            style={{ backgroundColor: "transparent", }} />
    );
}

export default Drawing;