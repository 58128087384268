import React, { useContext } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { HuePicker } from "react-color";
import "./tools.scss";

/**
 * Icons
 */
import { PinIcon, PenIcon, CircleIcon, UndoIcon, SaveIcon, CommentIcon } from "../../../utils/svgs";
import DrawingCommentWindow from "../comment-window/types/drawing";

/**
 * Functional component to return the HTML markup for the feedback tools selctor.
 * 
 * The current selected tool is then pushed to the content context for use in the other components.
 */
function FeedbackTools() {
    /**
     * Deconstruct the content context to expose update functions
     */
    const {
        feedbackTool,
        setFeedbackTool,
        brushColour,
        setBrushColour,
        brushSize,
        setBrushSize,
        setUndoDraw,
        setSaveState,
        showFeedbackComment,
        setShowFeedbackComment,
    } = useContext(ContentContext);

    return (
        <div className="content-feedback-tools">
            {/* Pins */}
            <div className={["content-feedback-tool", (feedbackTool === "PIN") && "active"].join(" ")}
                onClick={() => setFeedbackTool("PIN")} >
                <PinIcon />
            </div>

            {/* Pen */}
            <div className={["content-feedback-tool", (feedbackTool === "PEN") && "active"].join(" ")}
                onClick={() => setFeedbackTool("PEN")} >
                <PenIcon />
            </div>

            {/* If the pen is selected, show the brush size and colour selectors */}
            {feedbackTool === "PEN" &&
                <div className="content-feedback-tool-extras">
                    <div className="content-feedback-tool-buttons">
                        {/* Save button */}
                        <div className="feedback-tool-button" onClick={() => setSaveState(true)}>
                            <SaveIcon />
                        </div>

                        {/* Toggle comment window */}
                        <div className="feedback-comment-wrapper">
                            {/* Comment button */}
                            <div className={["feedback-tool-button", showFeedbackComment && "active"].join(" ")}
                                onClick={() => setShowFeedbackComment((showFeedbackComment) => !showFeedbackComment)}>
                                <CommentIcon />
                            </div>

                            {/* Comment window */}
                            <DrawingCommentWindow />
                        </div>

                        {/* Undo button */}
                        <div className="feedback-tool-button" onClick={() => setUndoDraw(true)}>
                            <UndoIcon />
                        </div>
                    </div>

                    {/* Brush colour toggle */}
                    <div className="content-feedback-tool-colours">
                        <HuePicker
                            width={16}
                            height={200}
                            direction="vertical"
                            color={brushColour}
                            onChange={(color) => setBrushColour(color.hex)} />
                    </div>

                    {/* Brush size toggle */}
                    <div className="content-feedback-tool-brush-size">
                        {/* Small */}
                        <div className={["brush-size-circle small", (brushSize === 4) && "active"].join(" ")}
                            onClick={() => setBrushSize(4)}>
                            <CircleIcon />
                        </div>

                        {/* Medium */}
                        <div className={["brush-size-circle medium", (brushSize === 8) && "active"].join(" ")}
                            onClick={() => setBrushSize(8)}>
                            <CircleIcon />
                        </div>

                        {/* Large */}
                        <div className={["brush-size-circle large", (brushSize === 12) && "active"].join(" ")}
                            onClick={() => setBrushSize(12)}>
                            <CircleIcon />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default FeedbackTools;