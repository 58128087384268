import React, { useState, useContext, useEffect, useRef } from "react";
import { ContentContext } from "../../../../../../utils/providers/content";
import { db } from "../../../../../../utils/firebase";

/**
 * UI components
 */
import Button from "../../../../../design-system/ui/button/button";
import { VerticalDotsIcon } from "../../../../../../utils/svgs";

/**
 * Functional component for displaying the reply comment data on the screen
 */
function Reply(props) {
    const [toggled, setToggled] = useState(false);
    const [menuShowing, setMenuShowing] = useState(false);

    /**
     * Get the relevant IDs for this content from the content context
     */
    const { ids, setSeekToSeconds, currentComment, setCurrentComment } = useContext(ContentContext);

    /**
     * Deconstruct the comment data from the props
     */
    const { replyID, commentID } = props;
    const { id, status, user, date, comment, useragent, secondsAt } = props.reply;

    /**
     * Setup a ref for the menu
     */
    const menuRef = useRef(null);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Function to check the target of the click event
         */
        const clickedOffMenu = (e) => {
            /**
             * If the click was outside the menu and wasn't on the toggle button
             */
            if (!menuRef.current.contains(e.target) && e.target.id !== `vertical-menu-toggle_${id}`) {
                /**
                 * Hide the menu
                 */
                setMenuShowing(false);
            }
        }
        /**
         * Setup the listener on the window for the click event
         */
        window.addEventListener("mousedown", clickedOffMenu);
        /**
         * Remove the document listener
         */
        return () => {
            window.removeEventListener("mousedown", clickedOffMenu);
        }
    }, []);

    /**
     * Remove the comment document from the file
     */
    const removeComment = () => {
        /**
         * Get the IDs from the 'ids' property on the content context
         */
        const { client, project, content, file } = ids;
        /**
         * Update the comment record in the database with a complete status
         */
        db.doc(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments/${commentID}/replies/${replyID}`)
            .delete();
    };

    /**
     * Toggle the showing status of the comment element
     */
    const toggleComment = () => {
        /**
         * Flip the staus of the toggled status in the state
         */
        setToggled((toggled) => !toggled);
    };

    /**
     * Whent he current comment ID is updated from the context
     */
    useEffect(() => {
        /**
         * Set the local toggled attribute to the result of whether the current ID and 
         * current Comment ID match
         */
        setToggled(currentComment === id);
    }, [currentComment]);

    /**
     * When the toggled status of the comment changes
     */
    useEffect(() => {
        /**
         * Is the current comment now toggled?
         */
        if (toggled) {
            /**
             * Set the current comment ID into the context
             */
            setCurrentComment(id);
            /**
             * Is there a secondsAt attribute?
             */
            if (secondsAt) {
                /**
                 * Calculate the second we need to seek the video to
                 */
                const split = secondsAt.split(":");
                const minutes = parseInt(+split[0]);
                const seconds = parseInt(split[1]);
                const seekToSeconds = parseFloat(minutes * 60 + seconds);
                /**
                 * Push it to the context
                 */
                setSeekToSeconds(seekToSeconds);
            }
        } else {
            /**
             * If it was un-toggled and the current comment in context is this one
             */
            if (currentComment === id) {
                /**
                 * Empty the current comment
                 */
                setCurrentComment("");
            }
        }
    }, [toggled]);

    /**
     * Passing the state toggle through here first so we can cancel the propagation of the click
     */
    const toggleMenuShowing = (e) => {
        /**
         * Stop the 
         */
        e.stopPropagation();
        /**
         * Toggle the menu to show for the comment actions
         */
        setMenuShowing(!menuShowing);
    }

    return (
        <div
            id={id}
            onClick={() => toggleComment()}
            className={[
                "comment-element",
                status,
                (currentComment === id) && "showing",
            ].join(" ")} >

            <div className="connecting-line" />

            <div className="ce-upper">
                <div className="ce-name">
                    <p>
                        {user} <span>{date}</span>
                    </p>

                    <div
                        iid={`vertical-menu-toggle_${id}`}
                        className="vertical-menu-toggle"
                        onClick={(e) => toggleMenuShowing(e)}>
                        <VerticalDotsIcon />
                    </div>

                    <div
                        ref={menuRef}
                        className={["vertical-menu", menuShowing && "is-showing"].join(" ")}>
                        <p className="delete-comment" onClick={(e) => removeComment(e)}>
                            Delete
                        </p>
                    </div>
                </div>
                <div className="ce-comment">
                    <p>
                        {comment}
                    </p>
                </div>
            </div>

            <div className="ce-lower">
                {/* Description of what device was used to view the content */}
                <div className="ce-meta">
                    <p>
                        Comment left on a{" "}
                        <span>
                            {useragent.os.name} {useragent.platform.type}
                        </span>{" "}
                        with{" "}
                        <span>
                            {useragent.browser.name} {useragent.browser.version?.split(".")[0]}
                        </span>
                        . Viewport dimensions were{" "}
                        <span>
                            {useragent.window.viewportWidth}x{useragent.window.viewportHeight}
                        </span>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Reply;
