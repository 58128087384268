import React from 'react';
import ReactDOM from 'react-dom';

/**
 * App entry point
 */
import App from './components/app/app';

/**
 * Global app styles
 */
import "./assets/utils/reset.scss";
import "./index.scss";

import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import reducerUser from './store/reducers/user';
import reducerOnboard from './store/reducers/onboard';
import reducerAlerts from './store/reducers/alerts';

const reducer = combineReducers({
  user: reducerUser,
  onboard: reducerOnboard,
  alerts: reducerAlerts
});
const store = createStore(reducer);

/**
 * Render the app to the DOM
 */
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);