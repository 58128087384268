import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";

/**
 * UI components
 */
import { CaretDownIcon, CaretUpIcon } from "../../../utils/svgs";

/**
 * Functional component to return the new clients stats card on the dashboard
 * 
 * @param {object} props Props object should contain currentPeriod & previousPeriod values
 */
function NewClients(props) {
    const [currentClients, setCurrentClients] = useState(0);
    const [previousClients, setPreviousClients] = useState(0);
    const [difference, setDifference] = useState(0);
    const [differenceDirection, setDifferenceDirection] = useState("NEUTRAL");

    /**
     * Deconstruct the user preferences from the props
     */
    const { currentPeriod, previousPeriod } = props;

    /**
     * When the currentPeriod & previousPeriod are updated from the dashboard
     */
    useEffect(() => {
        /**
         * Fetch the clients assigned in the current period
         */
        currentPeriod && db.collection(`users/${auth.currentUser.uid}/clients`)
            .where("assigned", ">", new Date(currentPeriod))
            .get().then((currentClientDocs) => {
                /**
                 * Update the state with the client documents found
                 */
                setCurrentClients(currentClientDocs.size);
            });
        /**
         * Then fetch the clients that are assigned in the previous period
         */
        previousPeriod && db.collection(`users/${auth.currentUser.uid}/clients`)
            .where("assigned", "<", new Date(currentPeriod))
            .where("assigned", ">=", new Date(previousPeriod))
            .get().then((previousClientDocs) => {
                /**
                 * Update the state with the client documents found
                 */
                setPreviousClients(previousClientDocs.size);
            });
    }, [currentPeriod, previousPeriod]);

    /**
     * When the currentClients and previousClients are updated
     */
    useEffect(() => {
        /**
         * Work out the difference between the two as a percentage
         */
        const difference = Math.round((currentClients - previousClients) * 100);
        /**
         * Update the state with the relevant direction
         */
        if (difference === 0) {
            setDifferenceDirection("NEUTRAL");
        } else if (difference > 0) {
            setDifferenceDirection("POSITIVE");
        } else if (difference < 0) {
            setDifferenceDirection("NEGATIVE");
        }
        /**
         * Push the difference into the local state too
         */
        setDifference(difference);
    }, [currentClients, previousClients]);

    return (
        <div className="statistic-card">
            <h6>New Clients</h6>
            <h5>
                <span>{currentClients}</span>
                <small className={differenceDirection}>
                    {/* Is the difference neutral */}
                    {(differenceDirection === "NEUTRAL") && "-"}

                    {/* Is the difference positive */}
                    {(differenceDirection === "POSITIVE") && <CaretUpIcon />}

                    {/* Or is the difference negative */}
                    {(differenceDirection === "NEGATIVE") && <CaretDownIcon />}

                    {difference}%
                </small>
            </h5>
        </div>
    );
}

export default NewClients;