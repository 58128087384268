import React, { useEffect, useState, useContext, useRef } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { db, auth } from "../../../utils/firebase";
import { getUserAgent } from "../../../utils/helpers";
import firebase from "firebase";
import "./comment-window.scss";

/**
 * UI Components
 */
import TextArea from "../../../components/design-system/ui/inputs/textarea";
import Button from "../../../components/design-system/ui/button/button";

/**
 * Functional component that shows the inputs needed for the user to leave a comment when they
 * have clicked on the feedback tile.
 *
 * The functionality to update the database with the new comment also sits in this functional component
 * to keep things in the same please, this will probably need changing when the drawing aspect comes into play.
 */
function CommentWindow(props) {
    const [displayWindow, setDisplayWindow] = useState(false);
    const [comment, setComment] = useState("");
    const [addingComment, setAddingComment] = useState(false);
    const [commentWindowStyles, setCommentWindowstyles] = useState({});
    const [flipX, setFlipX] = useState(false);
    const [flipY, setFlipY] = useState(false);

    /**
     * Setup a ref on the comment window div
     */
    const commentWindowRef = useRef(null);

    /**
     * Deconstruct the container size from the props
     */
    const { containerSize } = props;

    /**
     * Get the cursor positions and media data from the content context
     */
    const { ids, content, cursorX, cursorY, cursorXPixels, cursorYPixels, media } = useContext(ContentContext);

    /**
     * Component update on the props show, xPos and yPos being updated
     */
    useEffect(() => {
        /**
         * Toggle the display as required
         */
        setDisplayWindow(props.show);
        /**
         * Update the styling of the window to appear where required
         */
        setCommentWindowstyles({ top: `${cursorY}%`, left: `${cursorX}%` });
        /**
         * Get the height and width of the comment window
         */
        const commentWindowHeight = commentWindowRef.current.clientHeight;
        const commentWindowWidth = commentWindowRef.current.clientWidth;
        /**
         * Is the comment window going to appear too far over to the right
         */
        if ((cursorXPixels + commentWindowWidth) > containerSize?.width) {
            /**
             * Toggle the state to flip the comment window
             */
            setFlipX(true);
        } else {
            /**
             * Set the state back to not flip
             */
            setFlipX(false);
        }
        /**
         * Is the comment window going to appear too far along the bottom
         */
        if ((cursorYPixels + commentWindowHeight) > containerSize?.height) {
            /**
             * Toggle the state to flip the comment window
             */
            setFlipY(true);
        } else {
            /**
             * Set the state back to not flip
             */
            setFlipY(false);
        }
    }, [props.show, cursorX, cursorY, displayWindow]);

    /**
     * Saves the comment to the database
     */
    const saveComment = async () => {
        /**
         * Toggle the leave feedback button
         */
        setAddingComment(true);
        /**
         * Deconstruct the IDs needed from the content context
         */
        const { client, project, content, file } = ids;
        /**
         * Add the comment to the content sub-collection in the database
         */
        await db.collection(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments`).add({
            user: auth.currentUser.uid,
            date: firebase.firestore.FieldValue.serverTimestamp(),
            status: "active",
            task: null,
            comment: comment,
            x_pos: cursorX,
            y_pos: cursorY,
            useragent: getUserAgent(),
            secondsAt: media.secondsAt || null,
        });
        /**
         * Reset the comment field and enable the button again
         */
        setComment("");
        setAddingComment(false);
        props.cancel();
    };

    return (
        <div
            ref={commentWindowRef}
            className={[
                "feedback-comment-window",
                displayWindow && "showing",
                flipX && "flip-x",
                flipY && "flip-y",
            ].join(" ")}
            style={commentWindowStyles}>
            <TextArea
                value={comment}
                placeholder="Add comment..."
                onChange={setComment} />

            <Button
                label="Leave Feedback"
                loading={addingComment}
                loadingText="Saving feedback..."
                onClick={() => saveComment()}
                disabled={!comment} />

            <Button
                label="Cancel"
                type="NEGATIVE"
                onClick={() => props.cancel()} />

            {/* Show the seconds paused at if the content type is a video */}
            {content.type.format === "video" &&
                <p className="feedback-comment-seconds">Comment pinned at: {media.secondsAt}</p>
            }
        </div>
    );
}

export default CommentWindow;
