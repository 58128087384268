import React, { useState, useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import firebase from "firebase";
import { NavLink, Redirect } from "react-router-dom";
import "./login.scss";

/**
 * UI components
 */
import FeevalLogo from "../../assets/images/feeval-logo.png";
import { EyeOpenIcon, EyeSlashedIcon } from "../../utils/svgs";
import { ReactComponent as SideAccents } from "../../assets/images/signup-accents.svg";
import Input from "../../components/design-system/ui/inputs/input";
import Button from "../../components/design-system/ui/button/button";

/**
 * Functional component to return the login page
 */
function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showingPassword, setShowingPassword] = useState(false);

    /**
     * Deconstruct the user object from the Auth context
     */
    const { user } = useContext(AuthContext);

    /**
     * Check the inputs from the user to make sure they are all valid before we start ot set 
     * them up with a user account and sync them into stripe
     */
    const checkUserInputs = () => {
        /**
         * Show the user we are loading and reset the errors
         */
        setLoading(true);
        setErrors({});
        /**
         * Setup an object for holding any errors
         */
        let inputErrors = {};
        /**
         * Check the email against a regex for a valid email syntax
         */
        const checkEmail = /\S+@\S+\.\S+/;
        if (!checkEmail.test(email)) {
            inputErrors["email"] = "Please enter your email address";
        }
        /**
         * Check the password to make sure it's not empty
         */
        if (password.length === 0) {
            inputErrors["password"] = "Your password couldn't have been empty";
        }
        /**
         * Check to see if there were any errors added into the object
         */
        if (Object.keys(inputErrors).length === 0) {
            /**
             * Start the process of logging the user in
             */
            processLogin();
        } else {
            /**
             * Push the errors into the state
             */
            setErrors(inputErrors);
            setLoading(false);
        }
    }

    /**
     * Process the login using the details in the state and load an errors into the state 
     * for showing on the front-end
     */
    const processLogin = async () => {
        /**
         * Attempt to log the user into the platform
         */
        await firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                /**
                 * If the login was successful, the auth context will update the user object 
                 * we are listening for above and we'll redirect them based on it
                 */
            }).catch((err) => {
                /**
                 * If there was an error logging the user in, push an appropriate error 
                 * message to the front-end
                 */
                switch (err.code) {
                    /**
                     * User not found
                     */
                    case 'auth/user-not-found':
                        setErrors({ global: 'There is no account associated with these credentials. Did you mean to sign up instead?' });
                        break;
                    /**
                     * Wrong password
                     */
                    case 'auth/wrong-password':
                        setErrors({ global: 'Please check your credentials and try again.' });
                        break;
                    /**
                     * Default catch for any other errors
                     */
                    default:
                        setErrors({ global: 'There was an error. Please try again or contact us if you continue to have problems.' });
                }
                /**
                 * Reset the state
                 */
                setLoading(false);
            });
    }

    /**
     * If the user is logged in
     */
    if (user.logged_in) {
        if (user.is_agency) {
            /**
             * Take agency users to their dashboard
             */
            return <Redirect to="/dashboard" />
        } else {
            /**
             * Take client users to their projects list
             */
            return <Redirect to="/projects" />
        }
    } else {
        return (
            <div id="signup-wrapper">
                <div className="signup-logo">
                    <img src={FeevalLogo} alt="Feeval Platform" />
                </div>

                <div className="signup-input-column">
                    <h1>Welcome Back!</h1>
                    <p>Login below and let's get back to it...</p>

                    <div className="signup-inputs">
                        <Input
                            type="text"
                            label="Email"
                            showLabel={true}
                            placeholder="Email:"
                            value={email}
                            onChange={setEmail}
                            error={errors.email} />

                        <div className="password-input-wrapper">
                            <div
                                className="password-view-toggle"
                                onMouseDown={() => setShowingPassword(true)}
                                onMouseUp={() => setShowingPassword(false)}>
                                {!showingPassword && <EyeOpenIcon />}
                                {showingPassword && <EyeSlashedIcon />}
                            </div>

                            <Input
                                type={showingPassword ? "text" : "password"}
                                label="Password"
                                showLabel={true}
                                placeholder="Password:"
                                value={password}
                                onChange={setPassword}
                                error={errors.password} />
                        </div>

                        <small className="forgotten-password-link">Forgotten your password? <NavLink to="/password-reset">Reset it here</NavLink></small>
                    </div>

                    {/* If there was a global error (wrong password etc) */}
                    {errors.global &&
                        <p className="global-login-error">{errors.global}</p>
                    }

                    <small>By logging into FEEVAL, you're agreeing to our <a href="https://www.feeval.com/docs/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://www.feeval.com/docs/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></small>

                    <Button
                        label="Login"
                        loading={loading}
                        loadingText="Logging you in..."
                        onClick={() => checkUserInputs()} />

                    <small>Not with us yet? <NavLink to="/signup">Signup here</NavLink></small>
                </div>

                <div className="side-accents">
                    <SideAccents />
                </div>
            </div>
        );
    }
}

export default Login;