import React from "react";
import useProfileImage from "../../../hooks/useProfileImage";

/**
 * Functional component to return the user profile image or initials
 * 
 * @param {object} props Props object should contain a userID value 
 */
function User(props) {
    /**
     * Deconstruct the userID from the props
     */
    const { userID } = props;

    /**
     * Get the user initials from a hook
     */
    const profileImage = useProfileImage(userID);

    return (
        <div className="recent-project-user">
            {profileImage}
        </div>
    );
}

export default User;