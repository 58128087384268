import React, { useEffect, useState } from "react";
import { db, arrayUnion, arrayRemove } from "../../../../utils/firebase";

/**
 * UI components
 */
import Toggle from "../../../ui/toggle/toggle";

/**
 * Displays the project passed down in the list for toggling access to users and marking as requiring approval
 *
 * @param {object} props
 * @returns HTML markup for the project list item seen on the user edit panel for the clients
 */
function Project(props) {
    const [isRequired, setIsRequired] = useState(false);

    /**
     * On component load, load the status of the user in the required approvals array
     */
    useEffect(() => {
        db.doc(`clients/${props.clientID}/projects/${props.projectID}`).onSnapshot((projectDoc) => {
            /**
             * If the  project exists
             */
            if (projectDoc.exists) {
                /**
                 * Update the state
                 */
                setIsRequired(projectDoc.data().requiresApproval?.includes(props.userID));
            }
        });
    }, []);

    /**
     * Add or remove the users ID from the 'requiresApproval' array on the project document
     *
     * @type {const}
     * @param {string} projectID ID of the project the action is to be performed on
     */
    const toggleUserAsRequired = () => {
        /**
         * If we currently require their approval, we'll remove it here
         */
        if (isRequired) {
            db.doc(`clients/${props.clientID}/projects/${props.projectID}`).set({
                requiresApproval: arrayRemove(props.userID),
            }, { merge: true });
        } else {
            /**
             * Otherwise we need to assign them to the requiresApproval array and grant access to the project
             */
            db.doc(`clients/${props.clientID}/projects/${props.projectID}`).set({
                users: arrayUnion(props.userID),
                requiresApproval: arrayUnion(props.userID),
            }, { merge: true });
            /**
             * Assign them access to the project
             */
            db.doc(`users/${props.userID}`).set({
                projects: arrayUnion(props.projectID),
            }, { merge: true });
        }
    };

    return (
        <div className="userli-project">
            <div className="userli-project-title">
                <p>{props.title}</p>
            </div>
            <div className="userli-project-toggles">
                <div className="userli-project-toggle" onClick={props.toggleAccess}>
                    <Toggle on={props.allowed} />
                </div>
                <div className="userli-project-toggle" onClick={() => toggleUserAsRequired()}>
                    <Toggle on={isRequired} />
                </div>
            </div>
        </div>
    );
}

export default Project;
