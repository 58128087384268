import React from "react";
import { db, auth } from "../../../../utils/firebase";
import "./notification.scss";

/**
 * UI components
 */
import Toggle from "../../../design-system/ui/toggle/toggle";

/**
 * Functional component to return the notification toggle
 */
function Notification(props) {
    /**
     * Push an update to the users document reflecting the notification setting
     */
    const toggleNotification = async () => {
        /**
         * Assign the inverse of the toggled state to the update query
         */
        const updateQuery = {}
        updateQuery[`notifications.${props.type}`] = !props.toggled;
        /**
         * Update the document
         */
        await db.doc(`users/${auth.currentUser.uid}`).update(updateQuery);
        /**
         * Bubble the toggled notification up the props
         */
        props.bubbleToggle(props.type, !props.toggled);
    }

    return (
        <div className={["notification", props.type].join(" ")}>
            <div className="notif-info">
                {props.children}
            </div>
            <div className="notif-toggle-wrap">
                <Toggle on={props.toggled} onClick={() => toggleNotification()} />
            </div>
        </div>
    );
}

export default Notification;