import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";

/**
 * Custom hook to return the relevant profile image URL for the user. The link returned is the 
 * 
 * @param {string} userID User ID of the document to collect the profile image URL for
 * @returns Relevant link for the profile picture
 */
function useProfileImage(userID) {
    const [imageURL, setImageURL] = useState("");
    const [initials, setInitials] = useState("");

    /**
     * When the hook is loaded in with the userID parameter
     */
    useEffect(() => {
        /**
         * Setup a listener on the database document for that user
         */
        const unsubscribe = db.doc(`users/${userID}`).onSnapshot((userDoc) => {
            /**
             * Does the user document exist?
             */
            if (userDoc.exists) {
                /**
                 * Deconstruct the profile image object on the users document
                 */
                const { profile_picture, name, first_name, last_name, email } = userDoc.data();
                /**
                 * Is there a profile_picture object present on the user document?
                 */
                if (profile_picture && profile_picture.original) {
                    /**
                     * Update the state with the smallest version of the profile picture
                     */
                    if (profile_picture._256) {
                        setImageURL(<img src={profile_picture._256} alt="Profile" />);
                    } else if (profile_picture._528) {
                        setImageURL(<img src={profile_picture._528} alt="Profile" />);
                    } else {
                        setImageURL(<img src={profile_picture.original} alt="Profile" />);
                    }
                } else {
                    /**
                     * If there is no profile picture object, we need to return the initials
                     */
                    if (first_name && last_name) {
                        setInitials(
                            <div className="profile-initials">
                                {first_name?.substring(0, 1)}
                                {last_name?.substring(0, 1)}
                            </div>
                        );
                    } else if (name) {
                        /**
                         * Split the name if that's all we have
                         */
                        const splitName = name.split(" ");
                        /**
                         * Set the initials using that instead
                         */
                        setInitials(
                            <div className="profile-initials">
                                {splitName[0]?.substring(0, 1)}
                                {splitName[1]?.substring(0, 1)}
                            </div>
                        );
                    } else {
                        /**
                         * If all else fails, use the first letter of their email
                         */
                        setInitials(
                            <div className="profile-initials">
                                {email?.substring(0, 1)}
                            </div>
                        );
                    }
                }
            }
        });

        /**
         * Unsubscribe from the listener when the component is unloaded
         */
        return () => unsubscribe();
    }, [userID]);

    /**
     * Return the current URL in the state
     */
    return imageURL || initials;
}

export default useProfileImage;