import React from "react";
import useProfileImage from "../../../../hooks/useProfileImage";

function User(props) {

    const { userID, name, email } = props;

    const profilePicture = useProfileImage(userID);

    return (
        <div className="active-user">
            <div className={["tooltip"].join(" ")}>
                <div className="tooltip-text">
                    <p>{name || email}</p>
                </div>
            </div>
            <div className="active-user-image">
                {profilePicture}
            </div>
        </div>
    );
}

export default User;