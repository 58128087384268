import React, { useState, useContext } from "react";
import { auth, fun } from "../../../utils/firebase";
import { AlertsContext } from "../../../utils/providers/alerts";

/**
 * UI components
 */
import Input from "../../design-system/ui/inputs/input";
import Button from "../../design-system/ui/button/button";

/**
 *
 *
 * @returns HTML markup for the inviting new users to the agency
 */
function InviteUser(props) {
    const [inviteEmail, setInviteEmail] = useState("");
    const [adding, setAdding] = useState(false);

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     *
     *
     * @type {const}
     */
    const assignEmailToAgency = async () => {
        /**
         * Update the state
         */
        setAdding(true);
        /**
         * Make a call to the cloud function to start the invite processes
         */
        const inviteUser = fun.httpsCallable("assignEmailToAgency");
        inviteUser({
            email: inviteEmail,
            agencyID: props.agencyID,
            addedBy: auth.currentUser.uid,
        });
        /**
         * Show an alert to say the action is complete
         */
        pushAlert({
            title: "User Invited",
            body: `We're inviting ${inviteEmail} to the agency and letting them know with via email.`
        });
        /**
         * Update the state
         */
        setAdding(false);
        setInviteEmail("");
    };

    return (
        <div className="invite-user-window">
            <Input
                placeholder="User email"
                value={inviteEmail}
                onChange={(email) => setInviteEmail(email)}
            />

            <Button
                loading={adding}
                loadingText="Assigning..."
                label="Assign"
                onClick={() => assignEmailToAgency()}
                disabled={inviteEmail.length === 0} />
        </div>
    );
}

export default InviteUser;
