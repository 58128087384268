import React, { useEffect, useState, useContext } from "react";
import { db, fun } from "../../../utils/firebase";
import { ModalContext } from "../../../utils/providers/modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import moment from "moment";
import "./billing.scss";

/**
 * Card icons
 */
import Visa from "../../../assets/images/visa.png";
import Mastercard from "../../../assets/images/mastercard.png";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";
import Invoices from "./invoices/invoices";
import { LoadingIcon } from "../../../utils/svgs";

/**
 * Functional component to return the HTML markup for the agency billing in scope
 */
function BillingDetails(props) {
    const [billing, setBilling] = useState(null);
    const [customerID, setCustomerID] = useState(null);
    const [addingCard, setAddingCard] = useState(false);
    const [cardError, setCardError] = useState("");
    const [subscription, setSubscription] = useState(false);
    const [cancelingSubscription, setCancelingSubscription] = useState(false);

    const [removingCard, setRemovingCard] = useState(false);

    const [couponStart, setCouponStart] = useState("");
    const [couponEnd, setCouponEnd] = useState("");

    /**
     * Get the agencyID from the props
     */
    const { agencyID } = props;

    /**
     * Deconstruct the showModal function from the modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the agencies database document
         */
        const unsubscribe = db.doc(`agencies/${agencyID}`).onSnapshot((agencySnap) => {
            /**
             * Make sure the agency 
             */
            if (agencySnap.exists) {
                /**
                 * Get the billing details from the agency document
                 */
                const { stripe, subscription } = agencySnap.data();
                /**
                 * Set the billing details into the state
                 */
                setBilling(stripe || null);
                setCustomerID(stripe.customerID || null);
                setSubscription(subscription || null);
                /**
                 * Is there a coupon present on the agencies subscription document
                 */
                if (subscription?.coupon) {
                    /**
                     * Pull the data from the coupon object
                     */
                    const { start, end } = subscription.coupon;
                    /**
                     * Parse the dates from second timestamps to strings
                     */
                    const startingDate = moment(start, "X").format("DD/MM/YYYY");
                    const endingDate = moment(end, "X").format("DD/MM/YYYY");
                    /**
                     * Push the strings into the state
                     */
                    start && setCouponStart(startingDate);
                    end && setCouponEnd(endingDate);
                }
            }
        });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * Use the stripe react hooks
     */
    const stripe = useStripe();
    const elements = useElements();

    /**
     * Handle the submission of the card elements form
     */
    const addPaymentSource = async () => {
        /**
         * Update the UI
         */
        setAddingCard(true);
        setCardError("");
        /**
         * Make sure we have loaded the stripe libaries correctly
         */
        if (!stripe || !elements) {
            return;
        }
        /**
         * Build the card element object form the form field
         */
        const cardElement = elements.getElement(CardElement);
        /**
         * Create the source with stripe
         */
        const { error, source } = await stripe.createSource(cardElement);
        /**
         * If there was an error
         */
        if (error) {
            console.log(error);
        } else {
            /**
             * Payment method updated
             */
            const updatePaymentSource = fun.httpsCallable("paymentSourceUpdated");
            const paymentSourceResult = await updatePaymentSource({
                customer: customerID,
                source,
                agencyID,
            });
            /**
             * Was there an error passed back?
             */
            if (paymentSourceResult.data.error) {
                /**
                 * Push it into the state to show in the dom
                 */
                setCardError(paymentSourceResult.data.error);
            }
        }
        /**
         * Update the UI
         */
        setAddingCard(false);
    };

    /**
     * Remove the payment method from the agencies account
     */
    const removePaymentSource = async () => {
        /**
         * Show a spinner over the card block
         */
        setRemovingCard(true);
        /**
         * Call the cloud function to remove the
         */
        const removePaymentSource = fun.httpsCallable("paymentSourceRemoved");
        await removePaymentSource({
            customer: customerID,
            source: billing.payment_source,
            agencyID,
        });
        /**
         * Hide the spinner
         */
        setRemovingCard(false);
    }

    /**
     * Show a modal before cancelling the subscription to notify of the licenses being removed
     */
    const preCancellation = () => {
        /**
         * Show a modal for confirmation first
         */
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            body: "By canceling your subscription, all users for your agency on premium licenses will lose their functionality.",
            cancel: {
                label: "Cancel",
                action: () => { return null; },
            },
            next: {
                label: "Yes, please",
                action: () => cancelSubscription(),
            }
        });
    }

    /**
     * Cancel the subscription through a cloud function invokation
     */
    const cancelSubscription = async () => {
        /**
         * Show a spinner on the cancel button
         */
        setCancelingSubscription(true);
        /**
         * Call the cloud function to remove the
         */
        const cancelSubscriptionFunc = fun.httpsCallable("subscriptionCancel");
        await cancelSubscriptionFunc({
            agencyID,
            subscriptionID: subscription.subscriptionID,
        });
        /**
         * Hide the spinner
         */
        setCancelingSubscription(false);
    }

    return (
        <>
            <div className="edit-details-block">
                <h2>Payment Method</h2>
                <p>Add/update your payment card</p>

                {/* Is there no billing object setup for this agency? */}
                {!billing?.payment_source &&
                    <>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        color: "#4E5C70",
                                        fontFamily: '"Rubik", sans-serif',
                                        fontSmoothing: "antialiased",
                                        fontSize: "15px",
                                        fontWeight: "400",
                                        "::placeholder": {
                                            fontSize: "14px",
                                            opacity: "0.5",
                                            color: "#4E5C70",
                                        },
                                    },
                                    invalid: {
                                        color: "#fa755a",
                                        iconColor: "#fa755a",
                                    },
                                },
                            }}
                        />

                        {/* Was there an error adding the card? */}
                        {cardError &&
                            <p className="source-addition-error">{cardError}</p>
                        }

                        <Button
                            label="Add Card"
                            disabled={!stripe || addingCard}
                            loading={addingCard}
                            loadingText="Adding Card..."
                            onClick={() => addPaymentSource()} />
                    </>
                }

                {/* If there is a payment source present */}
                {billing?.payment_source &&
                    <div className="billing-card">
                        {/* When the card is being removed */}
                        <div className={["bc-removing", removingCard && "is-active"].join(" ")}>
                            <p>Removing...</p>
                            <div className="bc-removal-spinner">
                                <LoadingIcon />
                            </div>
                        </div>

                        <div className="bc-brand">
                            {/* Visa card icon */}
                            {(billing?.billing.brand === "Visa") &&
                                <img src={Visa} alt="Card Brand" />
                            }

                            {/* Mastercard card icon */}
                            {(billing?.billing.brand === "MasterCard") &&
                                <img src={Mastercard} alt="Card Brand" />
                            }
                        </div>
                        <div className="bc-number">
                            <p>{billing?.billing.brand} .... {billing?.billing.last4}</p>
                            <small>Expires {billing?.billing.expiry}</small>
                        </div>
                        <div className="bc-remove">
                            <p onClick={() => removePaymentSource()}>remove</p>
                        </div>
                    </div>
                }
            </div>

            <div className="edit-details-block">
                <h2>Subscription</h2>
                <p>Details about your subscription licenses with us</p>
                {/* If there is no subscription setup for this agency */}
                {!subscription?.subscriptionID &&
                    <small>No active subscription setup, add a license to a user to get started</small>
                }
                {/* If there is a subscription active for the agency */}
                {subscription?.subscriptionID &&
                    <div className="cancel-subscription-block">
                        {/* Subscription licenses & price */}
                        <div className="subscription-licenses">
                            <p>{subscription.licences} license{(subscription.licences > 1 || subscription.licences === 0) ? "s" : ""}</p>
                            <span>£{subscription.licences * 8} monthly</span>
                        </div>

                        {/* Has the subscription already been canceled, show the resume button and the cancel period */}
                        {subscription.cancelled &&
                            <>
                                <p className="subscription-period-end">Your subscription was cancelled and will expire on {moment(subscription.period_end, "X").format("DD/MM/YYYY")}</p>
                                <p className="subscription-period-end">To resume, assign a premium license to a user</p>
                            </>
                        }

                        {/* Is the subscription not yet canceled, show the cancel button */}
                        {!subscription.cancelled &&
                            <Button
                                label="Cancel subscription"
                                onClick={() => preCancellation()}
                                className="NEGATIVE"
                                loading={cancelingSubscription}
                                loadingText="Cancelling your subscription" />
                        }
                    </div>
                }
            </div>

            {subscription?.coupon &&
                <div className="edit-details-block">
                    <h2>Coupons</h2>
                    <p>Any coupons or credit notes applied to your agency</p>

                    <div className="cancel-subscription-block">
                        <div className="subscription-licenses">
                            <p>{subscription.coupon.name} ({subscription.coupon.percent_off}% off{subscription.coupon.duration_in_months ? ` for ${subscription.coupon.duration_in_months} months` : ""})</p>
                            <span>Active from {couponStart} {couponEnd ? `until ${couponEnd}` : ""}</span>
                        </div>
                    </div>
                </div>
            }

            <Invoices agencyID={agencyID} />
        </>
    );
}

/**
 * Load the Stripe APIs with the correct public keys
 */
let stripePubKey;
if (window.location.hostname === "app.feeval.com") {
    /**
     * Get the live stripe key
     */
    stripePubKey = "pk_live_e1D4oKEJXvcrPWcPrSdr7Dvg00KCIb9KCI";
} else {
    /**
     * Get the development stripe key
     */
    stripePubKey = "pk_test_Rd0zw2JsNKRNSW0filKUiFFj00Q5oy6Emb";
}

/**
 * Create the stripe promise
 */
const stripePromise = loadStripe(stripePubKey);

/**
 * Return method for the BillingDetails object wrapped in the stripe promise
 */
function Billing(props) {
    return (
        <Elements stripe={stripePromise}>
            <BillingDetails agencyID={props.agencyID} />
        </Elements>
    );
};

export default Billing;