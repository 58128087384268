import React, { useContext } from "react";
import { auth } from "../../../utils/firebase";
import { AuthContext, logoutRequest } from "../../../utils/providers/auth";
import FeevalLogo from "../../../assets/images/feeval-logo.png";
import { SignoutIcon } from "../../../utils/svgs";
import "./navigation.scss";

/**
 * Hooks
 */
import useProfileImage from "../../../hooks/useProfileImage";

/**
 * Navigation types
 */
import ClientNav from "./types/client";
import AgencyNav from "./types/agency";

/**
 * Functional component for the app Navigation markup
 */
function Navigation() {
    /**
     * Deconstruct the user object from the auth provider
     */
    const { user } = useContext(AuthContext);

    /**
     * Get the profile picture link for this user
     */
    const userProfilePicture = useProfileImage(auth.currentUser.uid);

    return (
        <nav id="app-navigation">
            <div id="nav-desktop">
                {/* Navigation logo */}
                <div className="nav-logo">
                    <img src={FeevalLogo} alt="FEEVAL Logo" />
                    <span className="nav-version">v0.1.0</span>
                </div>

                {/* Do we need to print the agency or client navigation? */}
                {user.agencies?.length > 0 ? <AgencyNav agencies={user.agencies} /> : <ClientNav />}

                {/* Profile picture and logout button */}
                <div className="nav-user" onClick={() => logoutRequest()}>
                    <div className="nav-profile-image">
                        {/* User profile picture URL or initials */}
                        {userProfilePicture}
                    </div>
                    <div className="nav-user-name">
                        <p>
                            {user.name ? user.name : auth.currentUser.displayName}
                            <small>Signout</small>
                        </p>
                    </div>
                    <div className="nav-user-signout">
                        <SignoutIcon />
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;