import React, { useRef, useState, useContext, useEffect } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { db } from "../../../utils/firebase";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import "./content-types.scss";

/**
 * UI Components
 */
import Feedback from "../../../components/content/feedback-tile/feedback";
import Drawing from "../../../components/content/drawings/drawing";
import CommentsOverlay from "../../../components/content/comments-overlay/comments-overlay";

/**
 * Container for outputting video formats in the review panels
 */
const ImageContainer = (props) => {
    const [imageURL, setImageURL] = useState("");
    const [imageType, setImageType] = useState("");
    const [containerSize, setContainerSize] = useState({});

    /**
     * Ref for the content holder
     */
    const contentContainerRef = useRef(null);

    /**
     * Get the required data from the content context
     */
    const { ids, content, feedbackTool } = useContext(ContentContext);

    /**
     * On the update of the content from context
     */
    useEffect(() => {
        /**
         * Fetch the file data from the database
         */
        fetchFileURL();
    }, [content]);

    /**
     * On window resize event
     */
    useEffect(() => {
        /**
         * When the window is resized
         */
        const windowResized = () => {
            /**
             * Get the size of the content container
             */
            const containerHeight = contentContainerRef.current.getBoundingClientRect().height;
            const containerWidth = contentContainerRef.current.getBoundingClientRect().width;
            /**
             * Update the state with those values
             */
            setContainerSize({
                height: containerHeight,
                width: containerWidth,
            });
        }
        /**
         * Add the event listener on the window resize event
         */
        window.addEventListener("resize", windowResized);
        /**
         * Remove the event listener on component unmount
         */
        return () => window.removeEventListener("resize", windowResized);
    });

    /**
     * 
     */
    const calcSizeForContainer = () => {
        /**
         * Notify the parent component the media is loaded in
         */
        props.onLoad();
        /**
         * Get the size of the content container
         */
        const containerHeight = contentContainerRef.current.getBoundingClientRect().height;
        const containerWidth = contentContainerRef.current.getBoundingClientRect().width;
        /**
         * Update the state with those values
         */
        setContainerSize({
            height: containerHeight,
            width: containerWidth,
        });
    }

    /**
     *
     *
     * @type {const}
     * @returns Sets the access URL for the file to the state
     */
    const fetchFileURL = async () => {
        /**
         * Fetch the file data from the database
         */
        const fileData = await db
            .doc(`clients/${ids.client}/projects/${ids.project}/content/${ids.content}/files/${ids.file}`)
            .get()
            .then((fileDoc) => {
                return fileDoc.exists ? fileDoc.data() : false;
            });
        /**
         * Was there file data returned
         */
        if (fileData) {
            /**
             * Update the state with the manifest URL
             */
            setImageURL(fileData.sizes?.original);
            setImageType(fileData.format);
        }
    };

    return (
        <div className="view-component image">
            <div ref={contentContainerRef} className="content-media-holder" id="content-media-holder">
                {/* Image tag */}
                {imageType === "application/pdf" ? (
                    <Document file={imageURL}>
                        <Page pageNumber={1} />
                    </Document>
                ) : (
                    <img
                        alt=""
                        id="content-image"
                        src={imageURL}
                        style={imageURL ? {} : { display: "none" }}
                        onLoad={() => calcSizeForContainer()} />
                )}

                {/* Feedback tile */}
                <Feedback containerSize={containerSize} />

                {/* Drawing tile */}
                <div className={["content-feedback-tile pen", (feedbackTool === "PEN") && "is-active"].join(" ")}>
                    <Drawing containerSize={containerSize} />
                </div>

                {/* Comments overlay */}
                <CommentsOverlay containerSize={containerSize} />
            </div>
        </div>
    );
};

export default ImageContainer;
