import React, { useEffect, useState } from "react";

/**
 * Takes in an array of images and prints out HTML markup for displaying them into a collage.
 * 
 * @param {array} images Array of image URLs for generating into the collage
 */
function useImageCollage(images) {
    const [imagesRowOne, setImagesRowOne] = useState([]);
    const [imagesRowTwo, setImagesRowTwo] = useState([]);

    /**
     * When the images array is updated
     */
    useEffect(() => {
        /**
         * Reset both the rows
         */
        setImagesRowOne([]);
        setImagesRowTwo([]);
        /**
         * If there is just one image
         */
        if (images) {
            if (images.length === 1) {
                setImagesRowOne(images);
            } else {
                /**
                 * Get every other element from index 0 for the first row
                 */
                for (let i = 0; i < images.length; i += 2) {
                    setImagesRowOne((imagesRowOne) => [...imagesRowOne, images[i]]);
                }
                /**
                 * Get every other element from index 1 in the array for the second row
                 */
                for (let i = 1; i < images.length; i += 2) {
                    setImagesRowTwo((imagesRowTwo) => [...imagesRowTwo, images[i]]);
                }
            }
        }
    }, [images]);

    return (
        <div className="image-collage">
            <div className="collage-row">
                {/* Print the first row */}
                {imagesRowOne.map((image) =>
                    <div className="image-collage-item" key={image}>
                        <img src={image} alt="Preview Collage Item" />
                    </div>
                )}
            </div>

            {/* Row 2 is only shown when there is more than 1 image to display */}
            {(imagesRowTwo.length > 0) &&
                <div className="collage-row">
                    {/* Print the second row */}
                    {imagesRowTwo.map((image) =>
                        <div className="image-collage-item" key={image}>
                            <img src={image} alt="Preview Collage Item" />
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

export default useImageCollage;