export const defaultNotifications = {
    USER_SIGNUP_AGENCY: true,
    USER_SIGNUP_TO_AGENCY: true,
    USER_SIGNUP_CLIENT: true,
    CONTENT_UPLOADED: true,
    CONTENT_APPROVED_AGENCY: true,
    CONTENT_READY_FOR_DOWNLOAD: true,
    CONTENT_DOWNLOADED: true,
    FEEDBACK_LEFT: true,
    TASK_ASSIGNED: true,
    TASK_COMPLETED: true,
    PROJECT_ASSIGNED: true,
    CLIENT_ASSIGNED: true,
    INVITED_TO_AGENCY: true,
    MARKETING: true,
}