import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faInfoCircle, faCog } from '@fortawesome/free-solid-svg-icons';

/**
 * Functional component to return the HTML markup for the client user navigation
 */
function ClientNav() {
    /**
     * Get the pathname for the current page from the react router
     */
    const { pathname } = useLocation();

    return (
        <>
            {/* Management */}
            <div className="nav-block">
                <h6>Manage</h6>
                <ul>
                    <li>
                        <NavLink to="/projects" activeClassName="active">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faFolderOpen} />
                            </div>
                            Projects
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* Account tabs */}
            <div className="nav-block">
                <h6>Account</h6>
                <ul>
                    <li>
                        <NavLink
                            to="/settings/edit"
                            activeClassName="active"
                            isActive={() => [
                                "/settings/edit",
                                "/settings/security",
                                "/settings/notifications",
                            ].includes(pathname)}>
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faCog} />
                            </div>
                            Settings
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* Help & support */}
            <div className="nav-block">
                <h6>Help</h6>
                <ul>
                    <li>
                        <a href="https://www.feeval.com/support/" target="_blank" rel="noopener noreferrer">
                            <div className="nav-item-icon">
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                            Support Docs
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default ClientNav;