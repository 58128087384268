import React, { useState, useEffect, useContext } from "react";
import { db, auth, storage } from "../../../utils/firebase";

/**
 * Contexts
 */
import { AlertsContext } from "../../../utils/providers/alerts";
import { AuthContext } from "../../../utils/providers/auth";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";
import Input from "../../design-system/ui/inputs/input";
import File from "../../design-system/ui/inputs/file";

/**
 * Required to generate the ID for the new content document
 */
const random = require("randomstring");

/**
 * Functional component to return the edit page for the users settings
 */
function Edit() {
    const [userName, setUserName] = useState("");
    const [savingName, setSavingName] = useState(false);
    const [profilePicture, setProfilePicture] = useState({});
    const [profilePictureURL, setProfilePictureURL] = useState({});
    const [uploadingProfilePicture, setUploadingProfilePicture] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [errors, setErrors] = useState({});

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Pull the user object from the auth context
     */
    const { user } = useContext(AuthContext);

    /**
     * When the user object is updated
     */
    useEffect(() => {
        /**
         * Push the name into the local state
         */
        setUserName(user.name || "");
    }, [user]);

    /**
     * When the profile picture file is updated in the state
     */
    useEffect(() => {
        /**
         * If there is a file present in the state
         */
        if (profilePicture[0]) {
            /**
             * Reset any erorrs we might have in the local state
             */
            setErrors({ ...errors, profilePicture: "" });
            /**
             * Get the file details from the file object
             */
            const { size, type } = profilePicture[0];
            /**
             * Setup some paramters for allowed file types and get the size in MB
             */
            const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
            const sizeInMB = size / 1024 / 1024;
            /**
             * First make sure it matches the allowed types
             */
            if (!allowedFormats.includes(type)) {
                /**
                 * Push an error to the state if it doesn't match
                 */
                setErrors({ ...errors, profilePicture: "This file format is not supported" });
            } else if (sizeInMB >= 5) {
                /**
                 * Push an error if the size of the file is too big
                 */
                setErrors({ ...errors, profilePicture: "Please use a file less than 5MB" });
            }
        }
    }, [profilePicture]);

    /**
     * Save the new display name into the users document
     */
    const saveName = async () => {
        /**
         * Update the state
         */
        setSavingName(true);
        /**
         * Save the name into the database
         */
        await db.doc(`users/${auth.currentUser.uid}`).set({
            name: userName,
        }, { merge: true });
        /**
         * Show an alert to say it's been changed
         */
        pushAlert({
            title: "Name updated",
        });
        /**
         * Update the state
         */
        setSavingName(false);
    }

    /**
     * Upload the new profile picture for the user
     */
    const uploadProfilePicture = async () => {
        /**
         * Update the state
         */
        setUploadingProfilePicture(true);
        /**
         * Upload the file
         */
        const originalURL = await handleFileUpload(profilePicture[0]);
        /**
         * Save the file info into the database
         */
        await db.doc(`users/${auth.currentUser.uid}`).set({
            profile_picture: {
                _64: null,
                _256: null,
                _512: null,
                original: originalURL,
            },
        }, { merge: true });
        /**
         * Show an alert to feedback to the user
         */
        pushAlert({
            type: "SUCCESS",
            title: "Profile picture updated"
        });
        /**
         * Update the state
         */
        setProfilePicture({});
        setUploadProgress(0);
        setUploadingProfilePicture(false);
    }

    /**
     * Handles the uploading of the file into the sotrage bucket
     */
    const handleFileUpload = (file) => {
        /**
         * Generate a new ID for using on the document references for this file
         */
        const uploadID = random.generate(20);
        /**
         * Build out some metadata for the image
         */
        const meta = {
            customMetadata: {
                userID: auth.currentUser.uid,
                fileID: uploadID,
                fileName: file.name,
                uploadedBy: auth.currentUser.uid,
                awaitingTranscode: true,
                type: "profile_picture",
            },
        };
        /**
         * Move the file into firebase storage
         */
        const upload = storage.ref(`users/${auth.currentUser.uid}/profile_pictures/${uploadID}/${uploadID}`).put(file, meta);
        /**
         * Return a promise listening to the upload progress
         */
        return new Promise((resolve, reject) => {
            /**
             * Setup the monitor for the file
             */
            monitorUploadProgress(upload, file.id).then(() => {
                /**
                 * When it's complete, get the downloadURL from the callback
                 */
                upload.snapshot.ref.getDownloadURL().then((url) => {
                    /**
                     * Update the state with the file whose upload is complete
                     */
                    setProfilePictureURL(url);
                    /**
                     * Resolve the promise
                     */
                    resolve(url);
                });
            });
        });
    };

    /**
     * Attach a listener onto the upload job to stream the progress into state
     */
    const monitorUploadProgress = (upload, fileID) => {
        return new Promise((resolve, reject) => {
            /**
             * Setup a listener on the upload process
             */
            upload.on("state_changed", (snapshot) => {
                /**
                 * Work out a progress percentage
                 */
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                /**
                 * Update the state with the progress of this file
                 */
                setUploadProgress(progress);
            }, (error) => console.log(error), () => {
                resolve();
            });
        });
    };

    return (
        <>
            <div className="edit-details-block">
                <h2>Update Name</h2>
                <p>Change the display name your clients see across FEEVAL</p>
                <Input
                    placeholder="Full name:"
                    value={userName}
                    onChange={setUserName} />
                <Button
                    label="Save Name"
                    loading={savingName}
                    loadingText="Saving..."
                    disabled={userName.length === 0}
                    onClick={() => saveName()} />
            </div>

            <div className="edit-details-block">
                <h2>Profile Picture</h2>
                <p>Choose a profile picture for clients to see on user panels</p>
                <File
                    placeholder="Choose file:"
                    onChange={setProfilePicture}
                    note="File sizes up to 5MB permitted"
                    showFileNames={true}
                    accept={["image/jpeg", "image/jpg", "image/png"]}
                    error={errors.profilePicture} />
                <Button
                    label="Save Picture"
                    loading={uploadingProfilePicture}
                    loadingText={`Uploading (${uploadProgress}%)...`}
                    disabled={(!profilePicture?.length || errors.profilePicture)}
                    onClick={() => uploadProfilePicture()} />
            </div>
        </>
    )
}

export default Edit;