import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";

/**
 * Custom hook to return the relevant logo image URL for a client.
 * 
 * @param {string} clientID ClientID ID of the document to collect the profile image URL for
 * @returns Relevant link for the logo image
 */
function useClientLogo(clientID) {
    const [imageURL, setImageURL] = useState("");
    const [initials, setInitials] = useState("");

    /**
     * When the hook is loaded in with the clientID parameter
     */
    useEffect(() => {
        /**
         * Setup a listener on the database document for that client
         */
        const unsubscribe = db.doc(`clients/${clientID}`).onSnapshot((clientDoc) => {
            /**
             * Does the client document exist?
             */
            if (clientDoc.exists) {
                /**
                 * Deconstruct the logo image object on the clients document
                 */
                const { logo, name } = clientDoc.data();
                /**
                 * Is there a logo object present on the client document?
                 */
                if (logo && logo.original) {
                    /**
                     * Update the state with the smallest version of the logo
                     */
                    if (logo._256) {
                        setImageURL(<img src={logo._256} alt="Client Profile" />);
                    } else if (logo._528) {
                        setImageURL(<img src={logo._528} alt="Client Profile" />);
                    } else {
                        setImageURL(<img src={logo.original} alt="Client Profile" />);
                    }
                } else {
                    /**
                     * If there is no logo present, split the name and use initials instead
                     */
                    const splitName = name.split(" ");
                    /**
                     * Set the initials using that instead
                     */
                    setInitials(
                        <div className="initials">
                            {splitName[0] && splitName[0]?.substring(0, 1)}
                            {splitName[1] && splitName[1]?.substring(0, 1)}
                        </div>
                    );
                }
            }
        });

        /**
         * Unsubscribe from the listener when the component is unloaded
         */
        return () => unsubscribe();
    }, [clientID]);

    /**
     * Return the current URL in the state
     */
    return imageURL || initials;
}

export default useClientLogo;