import React, { useContext } from "react";
import { ContentContext } from "../../../../utils/providers/content";
import "../comment-window.scss";

/**
 * UI Components
 */
import TextArea from "../../../../components/design-system/ui/inputs/textarea";

/**
 * Functional component to return the textarea for the comment window
 */
function DrawingCommentWindow() {
    /**
     * Get the cursor positions and media data from the content context
     */
    const { showFeedbackComment, feedbackComment, setFeedbackComment } = useContext(ContentContext);

    return (
        <div className={["feedback-comment-window drawing", showFeedbackComment && "is-active"].join(" ")}>
            <TextArea
                placeholder="Add comment..."
                value={feedbackComment}
                onChange={setFeedbackComment} />
        </div>
    );
}

export default DrawingCommentWindow;
