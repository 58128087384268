import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { db } from "../../utils/firebase";
import moment from "moment";
import "./dashboard.scss";

/**
 * UI components
 */
import RecentProject from "../../components/dashboard/recent/recent";
import NewClients from "../../components/dashboard/stats/clients";
import Downloads from "../../components/dashboard/stats/downloads";
import Approvals from "../../components/dashboard/stats/approvals";
import Tasks from "../../components/dashboard/stats/tasks";
import RevisionRatio from "../../components/dashboard/stats/revision-ratio";
import Invite from "../../components/dashboard/invite";

/**
 * Functional component for returning the dashboard view for agency users
 */
function Dashboard() {
    const [preferences, setPreferences] = useState([]);
    const [recentProjects, setRecentProjects] = useState([]);
    const [agencyInvites, setAgencyInvites] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState(null);
    const [previousPeriod, setPreviousPeriod] = useState(null);

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * When the user object is fetched from the context
     */
    useEffect(() => {
        /**
         * If there is a user ID present on the object
         */
        if (user.uid) {
            /**
             * Setup a listener on the database for their document
             */
            const unsubscribe = db.doc(`users/${user.uid}`).onSnapshot((userSnap) => {
                /**
                 * Deconstruct the data we need from the snapshot
                 */
                const { preferences, invites } = userSnap.data();
                /**
                 * Update the state with the snapshot values
                 */
                setPreferences(preferences || []);
                setAgencyInvites(invites || []);
            });
            /**
             * 
             */
            return () => unsubscribe();
        }
    }, [user]);

    /**
     * 
     */
    useEffect(() => {
        /**
         * If there is a user ID present on the object
         */
        if (user.uid) {
            /**
             * Setup a listener on the database collection for the users projects, ordered by the "lastSeen" 
             * attribute and limited to the most recent 3
             */
            const unsubscribe = db.collection(`users/${user.uid}/projects`)
                .orderBy("lastSeen", "desc")
                .limit(3)
                .onSnapshot((projectsSnap) => {
                    /**
                     * Loop over each of the projects found
                     */
                    projectsSnap.forEach((projectDoc) => {
                        /**
                         * Update them into the state
                         */
                        setRecentProjects((recentProjects) => [...recentProjects, {
                            id: projectDoc.id,
                            ...projectDoc.data(),
                        }]);
                    });
                });
            /**
             * Remove the listener on the component unload
             */
            return () => unsubscribe();
        }
    }, [user]);

    /**
     * When the user preferences are updated
     */
    useEffect(() => {
        /**
         * Perform a switch case to generate the dates needed for the chosen period
         */
        switch (preferences["DASHBOARD_STATS_PERIOD"]) {
            case "week":
                setCurrentPeriod(moment().subtract(1, "weeks"));
                setPreviousPeriod(moment().subtract(2, "weeks"));
                break;
            case "biweek":
                setCurrentPeriod(moment().subtract(2, "weeks"));
                setPreviousPeriod(moment().subtract(4, "weeks"));
                break;
            case "month":
                setCurrentPeriod(moment().subtract(1, "months"));
                setPreviousPeriod(moment().subtract(2, "months"));
                break;
            case "quater":
                setCurrentPeriod(moment().subtract(3, "months"));
                setPreviousPeriod(moment().subtract(6, "months"));
                break;
            case "year":
                setCurrentPeriod(moment().subtract(1, "years"));
                setPreviousPeriod(moment().subtract(2, "years"));
                break;
            default:
                setCurrentPeriod(moment().subtract(1, "weeks"));
                setPreviousPeriod(moment().subtract(2, "weeks"));
                break;
        }
    }, [preferences]);

    return (
        <div className="dashboard-tiles">
            {/* Does this user have any agency invites outstanding? */}
            {(agencyInvites.length > 0) &&
                <div className="dashboard-invites">
                    {agencyInvites.map((invite) => <Invite key={invite} id={invite} />)}
                </div>
            }

            {/* Are there any recent projects to display */}
            {(recentProjects.length > 0) &&
                <div className="recent-projects">
                    <h1 className="dashboard-title">Recent Projects</h1>

                    <div className="recent-project-folders">
                        {/* Loop over the recent projects and print them into the DOM */}
                        {recentProjects.map((project) => <RecentProject key={project.id} project={project} />)}
                    </div>
                </div>
            }

            {/* Account statistics */}
            <h1 className="dashboard-title">Account statistics</h1>

            <div className="dashboard-statistics">
                <div className="upper-row">
                    {/* New clients statistic */}
                    <NewClients currentPeriod={currentPeriod} previousPeriod={previousPeriod} />

                    {/* Downloads statistic */}
                    <Downloads currentPeriod={currentPeriod} previousPeriod={previousPeriod} />

                    {/* Approvals statistic */}
                    <Approvals currentPeriod={currentPeriod} previousPeriod={previousPeriod} />

                    {/* Completed tasks statistic */}
                    <Tasks currentPeriod={currentPeriod} previousPeriod={previousPeriod} />

                    {/* Revision ratio statistic */}
                    <RevisionRatio currentPeriod={currentPeriod} previousPeriod={previousPeriod} />
                </div>

                <div className="lower-row">
                    <div className="statistic-card">
                        <h6>Activity</h6>
                    </div>
                    <div className="statistic-card">
                        <h6>Outstanding Tasks</h6>
                    </div>
                    <div className="statistic-card">
                        <h6>FEEVAL News</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;