import React, { useState } from "react";
import { fun } from "../../utils/firebase";
import { NavLink } from "react-router-dom";
import "./password.scss";

/**
 * UI components
 */
import FeevalLogo from "../../assets/images/feeval-logo.png";
import { ReactComponent as SideAccents } from "../../assets/images/signup-accents.svg";
import Input from "../../components/design-system/ui/inputs/input";
import Button from "../../components/design-system/ui/button/button";

/**
 * Functional component to return the password reset page
 */
function PasswordReset() {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);

    /**
     * Check the email is valid before we attempt to submit it for a reset link
     */
    const checkUserInputs = () => {
        /**
         * Show the user we are loading and reset the errors
         */
        setLoading(true);
        setErrors({});
        /**
         * Setup an object for holding any errors
         */
        let inputErrors = {};
        /**
         * Check the email against a regex for a valid email syntax
         */
        const checkEmail = /\S+@\S+\.\S+/;
        if (!checkEmail.test(email)) {
            inputErrors["email"] = "Please enter your email address";
        }
        /**
         * Check to see if there were any errors added into the object
         */
        if (Object.keys(inputErrors).length === 0) {
            /**
             * Start the process of sending the reset link
             */
            processResetLink();
        } else {
            /**
             * Push the errors into the state
             */
            setErrors(inputErrors);
            setLoading(false);
        }
    }

    /**
     * Attempt to generate a reset link with the email given by the user. If the account exists, an email 
     * will be sent to them with the link, otherwise it'll return an error to show in the front-end
     */
    const processResetLink = async () => {
        /**
         * Get a reference to the reset link cloud function
         */
        const linkRequestFunction = fun.httpsCallable("requestPasswordResetLink");
        /**
         * Call the function with the email given by the user
         */
        const linkRequestResult = await linkRequestFunction({
            email,
        });
        /**
         * Deconstruct any errors that were returned
         */
        const { error } = linkRequestResult.data;
        /**
         * Was there an error passed back down/
         */
        if (error) {
            /**
             * Set the front-end to show an error message
             */
            setErrors({ global: "We can't find that email address, check your spelling and try again or login if you already have an account." });
        } else {
            /**
             * If there was no error returned, hide the email field and show the message
             */
            setComplete(true);
        }
        /**
         * Reset the state
         */
        setLoading(false);
    }

    return (
        <div id="signup-wrapper">
            <div className="signup-logo">
                <img src={FeevalLogo} alt="Feeval Platform" />
            </div>

            <div className="signup-input-column">
                <h1>Password Reset</h1>
                <p>Let's get you moving again...</p>

                {/* If we are waiting for a verdict on the reset link generation */}
                {!complete &&
                    <>
                        <div className="signup-inputs">
                            <Input
                                type="text"
                                label="Email"
                                showLabel={true}
                                placeholder="Email:"
                                note="We'll email you a link where you can reset your password"
                                value={email}
                                onChange={setEmail}
                                error={errors.email} />
                        </div>

                        {/* If there was a global error (wrong password etc) */}
                        {errors.global &&
                            <p className="global-login-error">{errors.global}</p>
                        }

                        <Button
                            label="Reset password"
                            loading={loading}
                            loadingText="Sending reset link..."
                            onClick={() => checkUserInputs()} />

                        <small>Remembered it? <NavLink to="/login">Login here</NavLink></small>
                    </>
                }

                {/* If we have got a reset link on the way */}
                {complete &&
                    <>
                        <p className="password-link-sent">
                            We've emailed you a password reset link, please follow the instructions there.
                        </p>

                        <small>Remembered it? <NavLink to="/login">Login here</NavLink></small>
                    </>
                }
            </div>

            <div className="side-accents">
                <SideAccents />
            </div>
        </div>
    );
}

export default PasswordReset;