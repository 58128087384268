import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";
import moment from "moment";

/**
 * UI components
 */
import { CaretDownIcon, CaretUpIcon } from "../../../utils/svgs";

/**
 * Functional component to return the downloads stats card on the dashboard
 * 
 * @param {object} props Props object should contain currentPeriod & previousPeriod values
 */
function Downloads(props) {
    const [currentDownloads, setCurrentDownloads] = useState(0);
    const [previousDownloads, setPreviousDownloads] = useState(0);
    const [difference, setDifference] = useState(0);
    const [differenceDirection, setDifferenceDirection] = useState("NEUTRAL");

    const [tempDownloads, setTempDownloads] = useState([]);

    /**
     * Deconstruct the user preferences from the props
     */
    const { currentPeriod, previousPeriod } = props;

    /**
     * When the currentPeriod & previousPeriod are updated from the dashboard
     * 
     * @todo Probably not the most efficient way of handling this. Need to look into another way of 
     * collecting downloadedBy numbers and storing them somewhere instead of looping through all 
     * the projects/content and files assigned to the current user.
     */
    useEffect(() => {
        /**
         * Make sure we have both a current and previous perdiod available
         */
        if ((currentPeriod?.unix() > 0) && (previousPeriod?.unix() > 0)) {
            /**
             * Setup an array for holding the downloads
             */
            const cDownloads = [];
            const pDownloads = [];
            /**
             * Fetch the clients assigned in the current period
             */
            db.collection(`users/${auth.currentUser.uid}/projects`)
                .get().then((projectDocs) => {
                    /**
                     * Loop over each project 
                     */
                    projectDocs.forEach((project) => {
                        /**
                         * Get the clientID from the project
                         */
                        const { client } = project.data();
                        /**
                         * Make a call to the content collection for this project within the client
                         */
                        db.collection(`clients/${client}/projects/${project.id}/content`)
                            .get().then((contentDocs) => {
                                /**
                                 * Loop through the content documents
                                 */
                                contentDocs.forEach((content) => {
                                    /**
                                     * Make a call to the content collection for this project within the client
                                     */
                                    db.collection(`clients/${client}/projects/${project.id}/content/${content.id}/files`)
                                        .get().then((fileDocs) => {
                                            /**
                                             * Loop through the content documents
                                             */
                                            fileDocs.forEach((file) => {
                                                /**
                                                 * Get the downloadedBy array from the file document
                                                 */
                                                const { downloadedBy } = file.data();
                                                /**
                                                 * If there is some downloads recorded
                                                 */
                                                if (downloadedBy && Object.keys(downloadedBy).length > 0) {
                                                    /**
                                                     * Loop through the downloadedBy records
                                                     */
                                                    for (const download in downloadedBy) {
                                                        /**
                                                         * Get the timestamp of the download
                                                         */
                                                        const timestamp = downloadedBy[download].seconds;
                                                        /**
                                                         * Get the timestamp forthe current & previous period
                                                         */
                                                        const currentPeriodTimestamp = moment(currentPeriod).unix();
                                                        const previousPeriodTimestamp = moment(previousPeriod).unix();
                                                        /**
                                                         * Is the timestamp of the download greater than the currentPeriod 
                                                         * (would suggest it has been downloaded in the current period)
                                                         */
                                                        if (timestamp > currentPeriodTimestamp) {
                                                            /**
                                                             * Push the download into the current array
                                                             */
                                                            cDownloads.push(`${download}@${timestamp}`);
                                                            /**
                                                             * Update the state
                                                             */
                                                            setCurrentDownloads(cDownloads?.length);
                                                        } else if ((timestamp <= currentPeriodTimestamp) && (timestamp > previousPeriodTimestamp)) {
                                                            /**
                                                             * If the timestamp falls between the previous period
                                                             */
                                                            pDownloads.push(`${download}@${timestamp}`);
                                                            /**
                                                             * Update the state
                                                             */
                                                            setPreviousDownloads(pDownloads?.length);
                                                        }
                                                    }
                                                }
                                            });
                                        });
                                });
                            });
                    });
                });
        }
    }, [currentPeriod, previousPeriod]);

    /**
     * When the currentDownloads and previousDownloads are updated
     */
    useEffect(() => {
        /**
         * Work out the difference between the two as a percentage
         */
        const difference = Math.round((currentDownloads - previousDownloads) * 100);
        /**
         * Update the state with the relevant direction
         */
        if (difference === 0) {
            setDifferenceDirection("NEUTRAL");
        } else if (difference > 0) {
            setDifferenceDirection("POSITIVE");
        } else if (difference < 0) {
            setDifferenceDirection("NEGATIVE");
        }
        /**
         * Push the difference into the local state too
         */
        setDifference(difference);
    }, [currentDownloads, previousDownloads]);

    return (
        <div className="statistic-card">
            <h6>Downloads</h6>
            <h5>
                <span>{currentDownloads}</span>
                <small className={differenceDirection}>
                    {/* Is the difference neutral */}
                    {(differenceDirection === "NEUTRAL") && "-"}

                    {/* Is the difference positive */}
                    {(differenceDirection === "POSITIVE") && <CaretUpIcon />}

                    {/* Or is the difference negative */}
                    {(differenceDirection === "NEGATIVE") && <CaretDownIcon />}

                    {difference}%
                </small>
            </h5>
        </div>
    );
}

export default Downloads;