import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { db, auth } from "../../../utils/firebase";
import "./recent.scss";

/**
 * UI components
 */
import { ReactComponent as FolderSVG } from "../../../assets/images/recent-project-folder.svg";
import User from "./users";

/**
 * Functional component to fetch the details for a recent project to print into the dashboard DOM
 * 
 * @param {object} props Props object passed in should contain a projectID
 */
function RecentProject(props) {
    const [clientName, setClientName] = useState("");
    const [projectTitle, setProjectTitle] = useState("");
    const [users, setUsers] = useState([]);

    /**
     * Deconstruct the projectID from the props
     */
    const { project } = props;

    /**
     * When the projectID is updated
     */
    useEffect(() => {
        /**
         * Wrap the fetch queries in async tags
         */
        async function fetchProjectDetails() {
            /**
             * Get the project document on the users subcollection
             */
            const clientID = await db.doc(`users/${auth.currentUser.uid}/projects/${project.id}`).get().then((projectDoc) => {
                /**
                 * If the user has access to this project
                 */
                if (projectDoc.exists) {
                    /**
                     * Return the clientID
                     */
                    return projectDoc.data().client;
                } else {
                    /**
                     * Or return false
                     */
                    return false;
                }
            });
            /**
             * If a clientID was found
             */
            if (clientID) {
                /**
                 * Fetch their name from the database
                 */
                await db.doc(`clients/${clientID}`).get().then((clientDoc) => {
                    /**
                     * If the client still exists
                     */
                    if (clientDoc.exists) {
                        /**
                         * Set their name into the local state
                         */
                        setClientName(clientDoc.data().name);
                    }
                });
                /**
                 * Also then fetch the users assigned to this project
                 */
                await db.doc(`clients/${clientID}/projects/${project.id}`).get().then((projectDoc) => {
                    /**
                     * Make sure the project document exists
                     */
                    if (projectDoc.exists) {
                        /**
                         * Deconstruct the users from the project document
                         */
                        const { title, users } = projectDoc.data();
                        /**
                         * Update the state
                         */
                        setProjectTitle(title);
                        setUsers(users);
                    } else {
                        /**
                         * Set the local state to not link to the project
                         */
                        return false;
                    }
                });
            }
        }
        /**
         * Call the async fetch function
         */
        fetchProjectDetails();
    }, [project]);

    return (
        <NavLink to={`/project/${project.id}/timeline`} className="recent-project">
            <div className="project-details">
                {/* Project title and client name */}
                <h1>{projectTitle}</h1>
                <h2><span>for</span> {clientName}</h2>

                {/* Users assigned to the project */}
                <div className="project-users">
                    <p>SHARED WITH</p>

                    <div className="project-user-images">
                        {(users.length > 0) &&
                            users.map((user) => <User key={user} userID={user} />)
                        }
                    </div>
                </div>
            </div>

            {/* Folder SVG to sit behind the text */}
            <FolderSVG />
        </NavLink>
    );
}

export default RecentProject;