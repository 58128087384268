import firebase from "firebase";
import "firebase/functions";

let config;

/**
 * Get the hostname of the current environment
 */
const { hostname } = window.location;

/**
 * Pull the relevant project config into the scope
 */
if (hostname === "localhost" || hostname === "stage.feeval.com" || hostname === "feeval-dev.web.app") {
    /**
     * Are we in a development environment
     */
    config = {
        apiKey: "AIzaSyC9VFyEvkkY1dfNE6Wy3Qfo15WLrObGFyE",
        authDomain: "feeval-dev.firebaseapp.com",
        databaseURL: "https://feeval-dev.firebaseio.com",
        projectId: "feeval-dev",
        storageBucket: "feeval-dev.appspot.com",
        messagingSenderId: "240802091439",
        appId: "1:240802091439:web:3e29069f71cc37c53d1f5a",
        measurementId: "G-YMT3G9N729",
    };
} else {
    /**
     * Are we in the live environment
     */
    config = {
        apiKey: "AIzaSyDUGchFR4GKbFJqr9Ww6EcgH35A0G4fbnc",
        authDomain: "feevalapp-b5edf.firebaseapp.com",
        databaseURL: "https://feevalapp-b5edf.firebaseio.com",
        projectId: "feevalapp",
        storageBucket: "feevalapp.appspot.com",
        messagingSenderId: "338206736599",
        appId: "1:338206736599:web:75b32472d96a2e46657092",
        measurementId: "G-0MT67H8J0Y",
    };
}

/**
 * Initialise the app
 */
firebase.initializeApp(config);

/**
 * Firebase analytics
 */
firebase.analytics();

/**
 * Firebase performance monitoring
 */
const performance = firebase.performance();

/**
 * Firebase authentication
 */
const auth = firebase.auth();

/**
 * Firestore
 */
const db = firebase.firestore();

/**
 * Firebase functions
 */
const fun = firebase.functions();

/**
 * Firebase storage
 */
const storage = firebase.storage();

/**
 * Array union function from firestore
 */
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

/**
 * Array remove function from firestore
 */
const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

/**
 * Grabs a timestamp value from firestore
 */
const getTimestamp = async () => {
    return firebase.firestore.Timestamp.fromDate(new Date());
};

/**
 * Increments a document by 1
 */
const increment = firebase.firestore.FieldValue.increment(1);

/**
 * Increments a document by a given amount
 */
const incrementBy = (by) => {
    firebase.firestore.FieldValue.increment(by);
};

/**
 * Decrements a document by 1
 */
const decrement = firebase.firestore.FieldValue.increment(-1);

/**
 * Decrements a document by a given amount
 */
const decrementBy = (by) => {
    firebase.firestore.FieldValue.increment(-Math.abs(parseInt(by)));
};

/**
 * Firebase services
 */
export {
    firebase,
    db,
    auth,
    fun,
    storage,
    performance,
};

/**
 * Export database functions
 */
export {
    arrayUnion,
    arrayRemove,
    increment,
    getTimestamp,
    incrementBy,
    decrement,
    decrementBy,
};
