import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";
import "./users.scss";

/**
 * UI components
 */
import InviteUser from "./invite";
import ClientStakeholder from "./stakeholder/stakeholder";

/**
 *
 */
function Users(props) {
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    /**
     * Deconstruct the client ID from props
     */
    const { clientID } = props;

    /**
     * When the client ID is present
     */
    useEffect(() => {
        /**
         * Setup a listener on the clients document to pull users
         */
        const unsubscribe = db.doc(`clients/${clientID}`)
            .onSnapshot((clientSnapshot) => {
                /**
                 * Update the state with the users
                 */
                setUsers(clientSnapshot.data().users);
            });
        /**
         * Remove the listener when component unloads
         */
        return () => unsubscribe();
    }, [clientID]);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch the users & projects for this client
         */
        fetchClientProjects();
        checkForUserAdministrate();
    }, []);

    /**
     * Fetch the projects that are iunder this client to pass down to the stakeholder for 
     * assigning access permission
     */
    const fetchClientProjects = () => {
        db.collection(`clients/${props.clientID}/projects`)
            .get().then((clientProjects) => {
                clientProjects.forEach((project) => {
                    setProjects((projects) => [...projects, { id: project.id, title: project.data().title }]);
                });
            });
    };

    /**
     * Check if the current user has administrative rights on the client
     */
    const checkForUserAdministrate = () => {
        db.doc(`users/${auth.currentUser.uid}/clients/${props.clientID}`)
            .get().then((clientDoc) => {
                /**
                 * Does this user have a record of the client in their collection
                 */
                if (clientDoc.exists) {
                    /**
                     * Add the permissions to the state
                     */
                    setIsAdmin(clientDoc.data().permissions.ADMINISTRATE);
                } else {
                    /**
                     * Otherwise just push a false statement
                     */
                    setIsAdmin(false);
                }
            });
    };

    return (
        <div className="client-users">
            {/* Invite user row */}
            <InviteUser
                currentUsers={users}
                clientID={props.clientID}
                agencyID={props.agencyID} />

            {/* Print the users to the DOM */}
            {users.map((user) => (
                <ClientStakeholder
                    key={user}
                    userID={user}
                    clientID={props.clientID}
                    projects={projects}
                    canRemove={isAdmin} />
            ))}
        </div>
    );
}

export default Users;
