import React, { useState, useContext, useEffect } from "react";
import { db, auth, storage } from "../../../utils/firebase";

/**
 * Contexts
 */
import { AlertsContext } from "../../../utils/providers/alerts";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";
import Input from "../../design-system/ui/inputs/input";
import File from "../../design-system/ui/inputs/file";

/**
 * Required to generate the ID for the new content document
 */
const random = require("randomstring");

/**
 * Functional component to return the HTML markup for editing the agency in scope
 */
function Edit(props) {
    const [tradingName, setTradingName] = useState("");
    const [originalTradingName, setOriginalTradingName] = useState("");
    const [savingName, setSavingName] = useState(false);
    const [agencyLogo, setAgencyLogo] = useState({});
    const [agencyLogoURL, setAgencyLogoURL] = useState("");
    const [uploadingLogo, setUploadingLogo] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [agencyLogoError, setAgencyLogoError] = useState("");

    /**
     * Deconstruct the agencyID from the props
     */
    const { agencyID } = props;

    /**
     * Deconstruct the pushAlert function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull the agency details in from firestore
         */
        db.doc(`agencies/${agencyID}`).get().then((agencyDoc) => {
            /**
             * Deconstruct the data we want from the agency document
             */
            const { name } = agencyDoc.data();
            /**
             * Set the name into the local state
             */
            setTradingName(name);
            setOriginalTradingName(name);
        });
    }, [agencyID]);

    /**
     * Save the new agency name into their database document
     */
    const saveAgencyName = () => {
        /**
         * Update the state
         */
        setSavingName(true);
        /**
         * Set the agency name into the database
         */
        db.doc(`agencies/${agencyID}`).set({
            name: tradingName,
        }, { merge: true });
        /**
         * Show an alert to feedback to the user
         */
        pushAlert({
            type: "SUCCESS",
            title: "Trading name updated"
        });
        /**
         * Update the state
         */
        setSavingName(false);
    }

    /**
     * Upload the new client logo to the storage bucket and assign the original URL to their document, 
     * a cloud function will take over and save the thumbnail copies when they're ready
     */
    const uploadAgencyLogo = async () => {
        /**
         * Update the state
         */
        setUploadingLogo(true);
        /**
         * Upload the file
         */
        const logoURL = await handleFileUpload(agencyLogo[0]);
        /**
         * Save the file info into the database
         */
        await db.doc(`agencies/${agencyID}`).set({
            logo: {
                _64: null,
                _256: null,
                _512: null,
                original: logoURL,
            },
        }, { merge: true });
        /**
         * Show an alert to feedback to the user
         */
        pushAlert({
            type: "SUCCESS",
            title: "Logo uploaded"
        });
        /**
         * Update the state
         */
        setAgencyLogo({});
        setUploadProgress(0);
        setUploadingLogo(false);
    }

    /**
     * Handles the uploading of the file into the sotrage bucket
     */
    const handleFileUpload = (file) => {
        /**
         * Generate a new ID for using on the document references for this file
         */
        const uploadID = random.generate(20);
        /**
         * Build out some metadata for the image
         */
        const meta = {
            customMetadata: {
                agencyID,
                fileID: uploadID,
                fileName: file.name,
                uploadedBy: auth.currentUser.uid,
                awaitingTranscode: true,
                type: "agency_logo",
            },
        };
        /**
         * Move the file into firebase storage
         */
        const upload = storage.ref(`agencies/${agencyID}/logos/${uploadID}/${uploadID}`).put(file, meta);
        /**
         * Return a promise listening to the upload progress
         */
        return new Promise((resolve, reject) => {
            /**
             * Setup the monitor for the file
             */
            monitorUploadProgress(upload, file.id).then(() => {
                /**
                 * When it's complete, get the downloadURL from the callback
                 */
                upload.snapshot.ref.getDownloadURL().then((url) => {
                    /**
                     * Update the state with the file whose upload is complete
                     */
                    setAgencyLogoURL(url);
                    /**
                     * Resolve the promise
                     */
                    resolve(url);
                });
            });
        });
    };

    /**
     * Attach a listener onto the upload job to stream the progress into state
     */
    const monitorUploadProgress = (upload, fileID) => {
        return new Promise((resolve, reject) => {
            /**
             * Setup a listener on the upload process
             */
            upload.on("state_changed", (snapshot) => {
                /**
                 * Work out a progress percentage
                 */
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                /**
                 * Update the state with the progress of this file
                 */
                setUploadProgress(progress);
            }, (error) => console.log(error), () => {
                resolve();
            });
        });
    };

    return (
        <>
            <div className="edit-details-block">
                <h2>Update Name</h2>
                <p>Change the display name your clients see across FEEVAL.</p>
                <Input
                    placeholder="Trading name:"
                    value={tradingName}
                    onChange={setTradingName} />
                <Button
                    label="Save Name"
                    loading={savingName}
                    loadingText="Saving..."
                    disabled={originalTradingName === tradingName}
                    onClick={() => saveAgencyName()} />
            </div>

            <div className="edit-details-block">
                <h2>Change Logo</h2>
                <p>Set a logo to display for your clients across FEEVAL.</p>
                <File
                    placeholder="Choose file:"
                    onChange={setAgencyLogo}
                    note="File sizes up to 5MB permitted"
                    showFileNames={true}
                    accept={["image/jpeg", "image/jpg", "image/png"]}
                    error={agencyLogoError} />
                <Button
                    label="Save Logo"
                    loading={uploadingLogo}
                    loadingText={`Uploading (${uploadProgress}%)...`}
                    disabled={(!agencyLogo?.length || agencyLogoError)}
                    onClick={() => uploadAgencyLogo()} />
            </div>
        </>
    );
}

export default Edit;