import React, { useRef, useEffect, useState } from "react";
import "./select.scss";

/**
 * Caret for the side of the dropdown inputfield
 */
import { CaretDownIcon, StarsIcon } from "../../../../utils/svgs";

/**
 * Functional component for the select component single-select options
 *
 * @param {object} props Props passed into the select component
 * @returns HTML markup and functionality for the select component
 */
function Select(props) {
    const [showingDropdown, setShowingDropdown] = useState(false);

    /**
     * Close the dropdown list and return the value back up the props
     */
    const optionSelected = (option) => {
        /**
         * Hide the dropdown
         */
        setShowingDropdown(false);
        /**
         * Push the update up the props
         */
        props.onSelect(option);
    };

    /**
     * Listen for a click outside the dropdown ref
     * 
     * @type {const}
     */
    const ClickedOutside = (dropdownRef) => {
        useEffect(() => {
            /**
             * Check for the click to be outside the select field
             */
            const checkForOutsideClick = (event) => {
                /**
                 * Is the click outside?
                 */
                if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                    /**
                     * Hide the dropdown
                     */
                    setShowingDropdown(false);
                }
            }
            /**
             * Bind on the component load
             */
            document.addEventListener("mousedown", checkForOutsideClick)
            return () => {
                /**
                 * Unbind on component unload
                 */
                document.removeEventListener("mousedown", checkForOutsideClick);
            }
        }, [dropdownRef]);
    }

    /**
     * Dropdown ref
     */
    const dropdownRef = useRef(null);
    /**
     * Bind onto the click event
     */
    ClickedOutside(dropdownRef);

    return (
        <div className={["ui-select-wrapper", props.className, props.error && "has-error"].join(" ")} ref={dropdownRef}>
            {/* Placeholder to sit above the field when there is a value */}
            {((props.placeholder || props.label) && props.showLabel) &&
                <div className={["ui-input-upper-placeholder", props.value?.length > 0 && "active"].join(" ")}>
                    {props.label || props.placeholder}
                </div>
            }

            {/* Input section of the input */}
            <div className={[
                "ui-select-upper",
                props.value && "has-value",
                showingDropdown && "dropdown-showing",].join(" ")}
                onClick={() => setShowingDropdown((showingDropdown) => !showingDropdown)} >
                <p className="ui-select-placeholder">
                    {props.value || props.placeholder}
                </p>

                {/* Dropdown chevron  */}
                <div className="ui-select-icon">
                    <CaretDownIcon />
                </div>
            </div>

            {/* Select dropdown, visible once clicked */}
            <div className={["ui-select-dropdown", showingDropdown && "is-showing"].join(" ")}>
                {/* Print out all the options passed in */}
                {Object.entries(props.options).map(([option, value]) => (
                    <div
                        key={option}
                        className={["ui-select-option", option.endsWith("_isLocked") && "is-locked"].join(" ")}
                        onClick={() => optionSelected({ option, value })}>
                        {value}
                        {/* If this option is locked (becuase it's a premium feature) */}
                        {option.endsWith("_isLocked") &&
                            <div className="premium-flag">
                                <p>Premium</p>
                                <StarsIcon />
                            </div>
                        }
                    </div>
                ))}
            </div>

            {/* Is there an error or note to display */}
            {props.error ? <small>{props.error}</small> :
                props.note && <small>{props.note}</small>}
        </div>
    );
}

export default Select;
