import React, { useEffect, useContext, useState } from "react";
import firebase from "firebase";
import { db, auth, arrayUnion } from "../../../utils/firebase";
import { AlertsContext } from "../../../utils/providers/alerts";
import "./add.scss";

/**
 * UI components
 */
import Input from "../../design-system/ui/inputs/input";
import Button from "../../design-system/ui/button/button";
import { PlusIcon } from "../../../utils/svgs";

/**
 * Functional component for adding a project to a specific client
 */
function AddProject(props) {
    const [formToggled, setFormToggled] = useState(false);
    const [projectTitle, setProjectTitle] = useState("");
    const [addingProject, setAddingProject] = useState(false);

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Get the clientID from the props
     */
    const { clientID } = props;

    /**
     * When the formToggled is updated
     */
    useEffect(() => {
        /**
         * Clear the inputs
         */
        setProjectTitle("");
    }, [formToggled]);

    /**
     * Create the new project inside the client in scope
     */
    const createProject = async () => {
        /**
         * Add the new project to the clients "projects" sub collection
         */
        const projectID = await db.collection(`clients/${clientID}/projects`).add({
            title: projectTitle,
            approved: false,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            users: arrayUnion(auth.currentUser.uid),
        }).then((projectDoc) => { return projectDoc.id });
        /**
         * Add the project ID to the current users projects array
         */
        await db.doc(`users/${auth.currentUser.uid}`).set({
            projects: arrayUnion(projectID),
        }, { merge: true });
        /**
         * Then create a new document for it with the clientID and assigned since attributes
         */
        await db.doc(`users/${auth.currentUser.uid}/projects/${projectID}`).set({
            client: props.clientID,
            since: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * When we are done, show an alert and reset the state
         */
        pushAlert({ type: "SUCCESS", title: "Project created" });
        setAddingProject(false);
        setFormToggled(false);
    }

    return (
        <div className="add-project-form">
            {/* Is the form currently not on show */}
            {!formToggled &&
                <div className="add-toggle" onClick={() => setFormToggled(true)}>
                    <PlusIcon />
                </div>
            }

            {/* Is the form currently showing */}
            {formToggled &&
                <div className="add-inputs">
                    <Input
                        value={projectTitle}
                        onChange={setProjectTitle}
                        placeholder="Project Title:" />

                    <Button
                        label="Create Project"
                        loading={addingProject}
                        loadingText="Creating..."
                        onClick={() => createProject()}
                        disabled={!projectTitle} />

                    <Button
                        type="NEGATIVE"
                        label="Cancel"
                        onClick={() => setFormToggled(false)} />
                </div>
            }
        </div>
    );
}

export default AddProject;