import React, { useState, useEffect, useContext } from "react";
import { EyeOpenIcon, EyeSlashedIcon } from "../../../utils/svgs";
import { auth, db } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * Contexts
 */
import { AuthContext } from "../../../utils/providers/auth";
import { AlertsContext } from "../../../utils/providers/alerts";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";
import Input from "../../design-system/ui/inputs/input";

/**
 * Functional component to return the security page for the users settings
 */
function Security() {
    const [newPassword, setNewPassword] = useState("");
    const [savingPassword, setSavingPassword] = useState(false);
    const [showingPassword, setShowingPassword] = useState(false);
    const [unlinking, setUnlinking] = useState({});
    const [linking, setLinking] = useState({});
    const [errors, setErrors] = useState({});

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Deconstruct the user object from the auth context
     */
    const { user } = useContext(AuthContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Check to see if there was a successful auth account link
         */
        auth.getRedirectResult().then((result) => {
            /**
             * If there are credentials returned
             */
            if (result.credential) {
                /**
                 * Setup a new object for storing the update query
                 */
                const updateQuery = {};
                /**
                 * Perform a switch case to see which auth provider has been used
                 */
                switch (result.credential.providerId) {
                    case "google.com":
                        /**
                         * Mark the google social account as connected
                         */
                        updateQuery[`connected_accounts.GOOGLE`] = true;
                }
                /**
                 * Make sure there was an update method to perform on the database
                 */
                if (Object.keys(updateQuery).length !== 0) {
                    /**
                     * Push the signin method to the database
                     */
                    db.doc(`users/${auth.currentUser.uid}`).update(updateQuery);
                    /**
                     * Push an alert to say it's been connected
                     */
                    pushAlert({
                        type: "SUCCESS",
                        title: "Account synced",
                        body: "Your accounts are now connected and you can use to sign in.",
                    });
                }
            }
        }).catch(() => {
            /**
             * If there was an error
             */
            pushAlert({
                type: "ERROR",
                title: "Syncing error",
                body: "There was a problem syncing your account, please re-login and try again.",
            });
        });
    }, []);

    /**
     * Update the users new password
     */
    const updatePassword = async () => {
        /**
         * Update the front-end
         */
        setSavingPassword(true);
        /**
         * Change the users password
         */
        await auth.currentUser.updatePassword(newPassword)
            .then(() => {
                /**
                 * Show an alert to say the password was updated
                 */
                pushAlert({
                    title: "Password updated",
                });
            }).catch((err) => {
                /**
                 * If there was an error logging resetting the password
                 */
                switch (err.code) {
                    /**
                     * Requires a re-login
                     */
                    case "auth/requires-recent-login":
                        setErrors({ password: "Please log in to verify and try again" });
                        break;
                    /**
                     * Default catch for any other errors
                     */
                    default:
                        setErrors({ password: "Please try a different password" });
                }
            });
        /**
         * Update the front-end
         */
        setSavingPassword(false);
        setNewPassword("");
    }

    /**
     * Link the given provider with the current user
     */
    const linkSocialAccount = async (provider) => {
        /**
         * Setup a 
         */
        let linkProvider;
        /**
         * Switch case to add the 
         */
        switch (provider) {
            case "GOOGLE":
                /**
                 * Show a spinner for the google button
                 */
                setLinking({ GOOGLE: true });
                /**
                 * Set the auth provider to GOOGLE
                 */
                linkProvider = new firebase.auth.GoogleAuthProvider();
        }
        /**
         * Start the account linking process
         */
        await auth.currentUser.linkWithRedirect(linkProvider)
            .catch(() => {
                /**
                 * If there was an error
                 */
                pushAlert({
                    type: "ERROR",
                    title: "Syncing error",
                    body: "There was a problem syncing your account, please re-login and try again.",
                });
            });
        /**
         * Reset the spinners on the buttons
         */
        setLinking({});
    }

    /**
     * Unlink the given provider from the current user
     */
    const unlinkSocialAccount = async (provider) => {
        /**
         * Setup a new object for storing the update query
         */
        const updateQuery = {};
        /**
         * Perform a switch case for the different providers
         */
        switch (provider) {
            case "GOOGLE":
                /**
                 * Set the button to show the unlinking
                 */
                setUnlinking({ GOOGLE: true });
                /**
                 * Mark the google social account as connected
                 */
                updateQuery[`connected_accounts.GOOGLE`] = firebase.firestore.FieldValue.delete();
                /**
                 * Unlink the google provider from the current user
                 */
                await auth.currentUser.unlink(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
        }
        /**
         * Make sure there was an update method to perform on the database
         */
        if (Object.keys(updateQuery).length !== 0) {
            /**
             * Push the signin method to the database
             */
            db.doc(`users/${auth.currentUser.uid}`).update(updateQuery);
            /**
             * Inform the user it has been un-synced
             */
            pushAlert({
                type: "SUCCESS",
                title: "Account removed",
                body: "Your accounts have been unsynced",
            });
        }
        /**
         * Reset the front-end
         */
        setUnlinking({});
    }

    return (
        <>
            <div className="edit-details-block">
                <h2>Social Profiles</h2>
                <p>Link your social profiles to speed up logging in</p>

                {user.connected_accounts?.GOOGLE &&
                    <Button
                        label="Unlink from Google"
                        loading={unlinking.GOOGLE}
                        loadingText="Unlinking your Google account"
                        onClick={() => unlinkSocialAccount("GOOGLE")} />
                }

                {!user.connected_accounts?.GOOGLE &&
                    <Button
                        label="Connect with Google"
                        loading={linking.GOOGLE}
                        loadingText="Sending you to Google"
                        onClick={() => linkSocialAccount("GOOGLE")} />
                }
            </div>

            <div className="edit-details-block">
                <h2>Change Password</h2>
                <p>Choose a new password for logging in</p>

                <div className="password-input-wrapper">
                    <div
                        className="password-view-toggle"
                        onMouseDown={() => setShowingPassword(true)}
                        onMouseUp={() => setShowingPassword(false)}>
                        {!showingPassword && <EyeOpenIcon />}
                        {showingPassword && <EyeSlashedIcon />}
                    </div>

                    <Input
                        type={showingPassword ? "text" : "password"}
                        placeholder="New password:"
                        value={newPassword}
                        onChange={setNewPassword}
                        error={errors.password} />
                </div>

                <Button
                    label="Update Password"
                    loading={savingPassword}
                    loadingText="Updating..."
                    disabled={newPassword.length < 8}
                    onClick={() => updatePassword()} />
            </div>
        </>
    )
}

export default Security;