import React, { useRef, useEffect, useContext, useState } from "react";
import { ContentContext } from "../../../utils/providers/content";
import CanvasDraw from "react-canvas-draw";

/**
 * Functional component for the 
 * 
 * @returns Returns the HTML markup and functionality for the drawing tile
 */
function PastDrawing(props) {
    const [display, setDisplay] = useState(true);
    const [isActiveComment, setIsActiveComment] = useState(true);

    /**
     * Deconstruct the comment from the props
     */
    const { comment, containerSize, canvasData, loadData } = props;

    /**
     * Get the current comment ID from the content context
     */
    const { media, currentComment } = useContext(ContentContext);

    /**
     * If there is some existing canvas data passed in via the props
     */
    useEffect(() => {
        setTimeout(() => {
            canvasData && loadSaveData(loadData);
        }, 100);
    }, [containerSize, loadData]);

    /**
     * On update of either the comments secondsAt or the medias secondsAt. It's likely going to the 
     * media secondsAt value changing here as the video plays out.
     */
    useEffect(() => {
        if ((comment.secondsAt !== null) && (media.secondsAt !== null)) {
            /**
             * Do the seconds at match on the comment and where the media currently is?
             */
            comment.secondsAt === media.secondsAt ? setDisplay(true) : setDisplay(false);
        }
    }, [comment.secondsAt, media.secondsAt]);
    console.log("Display:", display);

    /**
     * When the current comment changes in the context
     */
    useEffect(() => {
        /**
         * Is there a comment selected
         */
        if (currentComment?.length > 0) {
            /**
             * Does the current comment ID match this drawings document
             */
            if (currentComment === comment.id) {
                /**
                 * Show the full opacity version
                 */
                setIsActiveComment(true);
            } else {
                /**
                 * Tone the opacity down if not the active comment
                 */
                setIsActiveComment(false);
            }
        } else {
            /**
             * this would either be when the drawing first loads in or a comment is de-selected
             */
            setIsActiveComment(true);
        }
    }, [currentComment]);

    /**
     * Canvas ref
     */
    const canvasRef = useRef(null);

    /**
     * Load the save data for the drawing into the canvas api
     * 
     * @type {const}
     */
    const loadSaveData = () => {
        if (comment?.secondsAt) {
            if (comment.secondsAt === media.secondsAt) {
                /**
                 * Load the drawing data
                 */
                canvasRef.current && canvasRef.current.loadSaveData(canvasData);
            }
        } else {
            /**
             * Load the drawing data
             */
            canvasRef.current && canvasRef.current.loadSaveData(canvasData);
        }
    }

    /**
     * When the display toggle is set in the local state
     */
    useEffect(() => {
        /**
         * If it's true, load the canvas data onto the page
         */
        display && loadSaveData();
    }, [display]);

    return (
        <>
            {/* Is the drawing being displayed over a video? */}
            {(comment.secondsAt && media.secondsAt) &&
                <div className={[
                    "drawing-opacity-control",
                    isActiveComment && "is-active",
                ].join(" ")}
                    style={{ opacity: display ? 1 : 0 }}>
                    <CanvasDraw
                        disabled
                        hideGrid
                        ref={canvasRef}
                        lazyRadius={0}
                        brushRadius={0}
                        catenaryColor="transparent"
                        immediateLoading={true}
                        canvasWidth={containerSize.width || 100}
                        canvasHeight={containerSize.height || 100}
                        style={{ backgroundColor: "transparent", }} />
                </div>
            }

            {/* Is the drawing being displayed over an image/document? */}
            {(!comment.secondsAt || !media.secondsAt) &&
                <div className={["drawing-opacity-control", isActiveComment && "is-active"].join(" ")}>
                    <CanvasDraw
                        disabled
                        hideGrid
                        ref={canvasRef}
                        lazyRadius={0}
                        brushRadius={0}
                        catenaryColor="transparent"
                        immediateLoading={true}
                        canvasWidth={containerSize.width || 100}
                        canvasHeight={containerSize.height || 100}
                        style={{ backgroundColor: "transparent", }} />
                </div>
            }
        </>
    );
}

export default PastDrawing;