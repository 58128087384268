import React, { useEffect, useState, useContext } from "react";
import { db, auth, arrayRemove } from "../../../../utils/firebase";
import "./user.scss";

/**
 * Contexts
 */
import { ModalContext } from "../../../../utils/providers/modal";
import { AlertsContext } from "../../../../utils/providers/alerts";

/**
 * Hooks
 */
import useProfilePicture from "../../../../hooks/useProfileImage";

/**
 * UI components
 */
import { CaretDownIcon, CaretUpIcon, StarsIcon, TrashIcon } from "../../../../utils/svgs";
import Permission from "../../../client/users/stakeholder/permission";
import Premium from "./attributes/premium";

/**
 * 
 */
function AgencyUser(props) {
    const [user, setUser] = useState({});
    const [toggled, setToggled] = useState(false);
    const [permissions, setPermissions] = useState({
        MANAGE_CLIENTS: false,
        MANAGE_USERS: false,
        ADMINISTRATE: false,
    });
    const [premiumUsers, setPremiumUsers] = useState([]);
    const [permissionString, setPermissionString] = useState("");
    const [subscription, setSubscription] = useState("");

    /**
     * Deconstruct the showModal function from the Modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Deconstruct the pushAlert function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Deconstruct the IDs we need from the props
     */
    const { userID, agencyID } = props;

    /**
     * Get the user profile picture 
     */
    const userProfilePicture = useProfilePicture(userID);

    /**
     * when the userID is loaded from props
     */
    useEffect(() => {
        /**
         * Get the users document from the database
         */
        const unsubscribe = db.doc(`users/${userID}`)
            .onSnapshot((userDoc) => {
                /**
                 * Do they exist?
                 */
                if (userDoc.exists) {
                    /**
                     * Update the state with the details
                     */
                    setUser(userDoc.data());
                } else {
                    /**
                     * Otherwise just push a false statement
                     */
                    setUser(false);
                }
            });
        /**
         * Remove the listener when the component unmounts
         */
        return () => unsubscribe();
    }, [userID]);

    /**
     * When both the userID and clientID are available from props
     */
    useEffect(() => {
        /**
         * Then get the client record on this users document
         */
        db.doc(`agencies/${agencyID}/users/${userID}`)
            .get().then((agencyDoc) => {
                /**
                 * Does this user have a record of the client in their collection
                 */
                if (agencyDoc.exists) {
                    /**
                     * Add the permissions to the state
                     */
                    setPermissions(agencyDoc.data().permissions);
                }
            });
    }, [userID, agencyID]);

    /**
     * When the agencyID is updated through the props
     */
    useEffect(() => {
        /**
         * Fetch the agency data from
         */
        const unsubscribe = db.doc(`agencies/${agencyID}`)
            .onSnapshot((agencyDoc) => {
                /**
                 * Make sure the agency exists
                 */
                if (agencyDoc.exists) {
                    /**
                     * Deconstruct the premium users and subscription objects from the agency document
                     */
                    const { premium_users, subscription } = agencyDoc.data();
                    /**
                     * Set both objects into the local state
                     */
                    setPremiumUsers(premium_users || []);
                    setSubscription(subscription?.subscriptionID);
                }
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, [agencyID]);

    /**
     * On update of the permissions, re-generate the string
     */
    useEffect(() => {
        /**
         * Push the various permissions into an array
         */
        let perms = [];
        if (permissions.MANAGE_CLIENTS) {
            perms.push("Manage Clients");
        }
        if (permissions.MANAGE_USERS) {
            perms.push("Manage Users");
        }
        if (permissions.ADMINISTRATE) {
            perms.push("Administrate");
        }
        /**
         * Update the state
         */
        setPermissionString(perms.length > 0 ? perms.join(", ") : "No Permissions");
    }, [permissions]);

    /**
     * Ran when the user primary tab of the list item is clicked
     */
    const toggleUserView = (e) => {
        /**
         * Make sure the delete button wasn't the target fo the click
         */
        if (e.target.id !== "remove") {
            /**
             * Toggle the view state
             */
            setToggled((toggled) => !toggled);
        }
    };

    /**
     * Runs through some logic for toggling the user permissions
     */
    const toggleUserPermission = (type) => {
        /**
         * Make sure the user isn't editing their own permissions
         */
        if (props.userID !== auth.currentUser.uid) {
            /**
             * Update the database with the new permission
             */
            db.doc(`agencies/${agencyID}/users/${userID}`).update({
                [`permissions.${type}`]: !permissions[type],
            });
            /**
             * Update the state to reflect new permissions
             */
            setPermissions({ ...permissions, [type]: !permissions[type] });
        } else {
            /**
             * If they are, show an alert
             */
            pushAlert({
                type: "ERROR",
                title: "Can't toggle permission",
                body: "You can't edit your own permissions, please ask your admin for help."
            });
        }
    };

    /**
     * Check to make sure the user is happy witht he removal through the use of a modal
     */
    const checkToRemoveFromAgency = () => {
        /**
         * Show a modal before continuing
         */
        showModal({
            type: "ERROR",
            title: "Are you sure?",
            body: "Removing the user will mean they no longer have access to this agency.",
            cancel: {
                label: "Cancel",
                action: () => { return null },
            },
            next: {
                label: "Yes, please",
                action: () => removeUserFromAgency(),
            }
        });
    }

    /**
     * Remove the user from the agency account
     */
    const removeUserFromAgency = () => {
        /**
         * Update the database documents
         */
        db.doc(`users/${userID}/agencies/${agencyID}`).delete();
        db.doc(`users/${userID}`).update({
            agencies: arrayRemove(agencyID)
        });
        db.doc(`agencies/${agencyID}/users/${userID}`).delete();
        db.doc(`agencies/${agencyID}`).update({
            users: arrayRemove(userID),
            premium_users: arrayRemove(userID),
        });
    };

    return (
        <div className={["userli", toggled ? "open" : ""].join(" ")}>
            {/* Primary user details */}
            <div className="userli-primary" onClick={(e) => toggleUserView(e)}>
                <div className="userli-image">
                    <div className="userli-image-wrap">
                        {userProfilePicture}
                    </div>
                    {/* If this user is a premium user, show a small star icon */}
                    {premiumUsers.includes(userID) &&
                        <div className="premium-stars">
                            <StarsIcon />
                        </div>
                    }
                </div>
                <div className="userli-name">
                    <p>{user.name || "Anonymous User"}</p>
                </div>
                <div className="userli-email">
                    <p>{user.email}</p>
                </div>
                <div className="userli-permissions">
                    <p>{permissionString}</p>
                </div>
                {(props.canRemove && (props.userID !== auth.currentUser.uid)) && (
                    <div className="userli-remove" id="remove" onClick={() => checkToRemoveFromAgency()}>
                        <TrashIcon />
                    </div>
                )}
                <div className="userli-chev">
                    {toggled ? <CaretUpIcon /> : <CaretDownIcon />}
                </div>
            </div>
            {/* Secondary user details (visible when the primary tab is clicked) */}
            <div className="userli-secondary">
                {/* Premium toggle logic */}
                <Premium
                    userID={userID}
                    agencyID={agencyID}
                    premiumUsers={premiumUsers}
                    subscription={subscription} />

                {/* Permissions */}
                <div className="userli-heading">
                    <h1>Permissions</h1>
                </div>
                <div className="userli-permissions-block">
                    <Permission toggled={permissions.MANAGE_CLIENTS} toggle={() => toggleUserPermission("MANAGE_CLIENTS")}>
                        <h1>Manage Clients</h1>
                        <p>Allows user to create and remove clients under the agency</p>
                    </Permission>
                    <Permission toggled={permissions.MANAGE_USERS} toggle={() => toggleUserPermission("MANAGE_USERS")}>
                        <h1>Manage Users</h1>
                        <p>Allows access for user to add and remove users from the agency</p>
                    </Permission>
                    <Permission toggled={permissions.ADMINISTRATE} toggle={() => toggleUserPermission("ADMINISTRATE")}>
                        <h1>Administrate</h1>
                        <p>Allows access for user to edit agency details including subscription</p>
                    </Permission>
                </div>
            </div>
        </div>
    );
}

export default AgencyUser;
