import React from 'react';
import { Switch, Route } from 'react-router-dom';

/**
 * Route components
 */
import Login from '../../../containers/login/login';
import Signup from '../../../containers/signup/signup';
import PasswordReset from '../../../containers/password/password';
import Content from '../../../containers/content/content';
import Error404 from '../../../containers/errors/404';

/**
 * Returns the routes for generic pages.
 * 
 * This route setup is loaded when the above Routes component recognises the user is on a generic 
 * page such as login or signup where it isn't nessescary to load in all the applicaiton routes.
 */
function GenericRoutes() {
    return (
        <Switch>
            {/* Login & Signup */}
            <Route path="/login" exact component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/content/:id" component={Content} />

            {/* Error pages */}
            <Route path="*" component={Error404} />
        </Switch>
    );
}

export default GenericRoutes;