import React from 'react';
import ReactSlider from 'react-slider';

function Timeline(props) {
    return (
        <ReactSlider
            className="timeline-container"
            thumbClassName="timeline-pin"
            trackClassName="timeline-track"
            min={0}
            max={props.maximum}
            value={props.value}
            onAfterChange={value => props.onSeekEnd(value)}
            renderThumb={(props, state) => <div {...props}></div>}
        />
    );
}

export default Timeline;