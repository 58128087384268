// Import action types
import * as actionTypes from '../actions';

// Setup an inital state
const initState = {
    user: null
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload
            }

        case actionTypes.CLIENT_ONBOARD:
            return {
                ...state,
                user: {
                    ...state.user,
                    type: 'client'
                }
            }

        default:
            return state;
    }
};

export default reducer;