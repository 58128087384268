import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";

/**
 * UI components
 */
import { CaretDownIcon, CaretUpIcon } from "../../../utils/svgs";

/**
 * Functional component to return the task stats card on the dashboard
 * 
 * @param {object} props Props object should contain currentPeriod & previousPeriod values
 */
function Tasks(props) {
    const [currentTasks, setCurrentTasks] = useState(0);
    const [previousTasks, setPreviousTasks] = useState(0);
    const [difference, setDifference] = useState(0);
    const [differenceDirection, setDifferenceDirection] = useState("NEUTRAL");

    /**
     * Deconstruct the user preferences from the props
     */
    const { currentPeriod, previousPeriod } = props;

    /**
     * When the currentPeriod & previousPeriod are updated from the dashboard
     */
    useEffect(() => {

    }, [currentPeriod, previousPeriod]);

    /**
     * When the currentTasks and previousTasks are updated
     */
    useEffect(() => {
        /**
         * Work out the difference between the two as a percentage
         */
        const difference = Math.round((currentTasks - previousTasks) * 100);
        /**
         * Update the state with the relevant direction
         */
        if (difference === 0) {
            setDifferenceDirection("NEUTRAL");
        } else if (difference > 0) {
            setDifferenceDirection("POSITIVE");
        } else if (difference < 0) {
            setDifferenceDirection("NEGATIVE");
        }
        /**
         * Push the difference into the local state too
         */
        setDifference(difference);
    }, [currentTasks, previousTasks]);

    return (
        <div className="statistic-card">
            <h6>Completed Tasks</h6>
            <h5>
                <span>{currentTasks}</span>
                <small className={differenceDirection}>
                    {/* Is the difference neutral */}
                    {(differenceDirection === "NEUTRAL") && "-"}

                    {/* Is the difference positive */}
                    {(differenceDirection === "POSITIVE") && <CaretUpIcon />}

                    {/* Or is the difference negative */}
                    {(differenceDirection === "NEGATIVE") && <CaretDownIcon />}

                    {difference}%
                </small>
            </h5>
        </div>
    );
}

export default Tasks;