import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import "./settings.scss";

/**
 * UI components
 */
import Tile from "../../components/design-system/structure/tile/tile";
import Title from "../../components/design-system/structure/title/title";
import Navigation from "../../components/design-system/structure/navigation/navigation";

/**
 * Routes
 */
import Edit from "../../components/settings/edit/edit";
import Security from "../../components/settings/security/security";
import Notifications from "../../components/settings/notifications/notifications";
import Error404 from "../errors/404";

/**
 * Functional component to return the settings page for the user
 */
function Settings(props) {

    /**
     * Deconstruct the url parmaters from the props
     */
    const { url } = props.match;

    return (
        <Tile fullPage={true}>
            <Title>
                <h1>Profile Settings</h1>
            </Title>

            {/* Navigation routes */}
            <Navigation>
                {/* Edit details link */}
                <NavLink activeClassName="is-active" to={`${url}/edit`}>
                    Details
                </NavLink>

                {/*  Security link */}
                <NavLink activeClassName="is-active" to={`${url}/security`}>
                    Security
                </NavLink>

                {/*  Notifications link */}
                <NavLink activeClassName="is-active" to={`${url}/notifications`}>
                    Notifications
                </NavLink>
            </Navigation>

            {/* Expose routes based on access level of current user */}
            <Switch>
                <Route path="/settings/edit" exact component={() =>
                    <Edit />} />

                <Route path="/settings/security" exact component={() =>
                    <Security />} />

                <Route path="/settings/notifications" exact component={() =>
                    <Notifications />} />

                {/* Any other page that doesn't have a route */}
                <Route path="*" component={Error404} />
            </Switch>
        </Tile>
    );
}

export default Settings;