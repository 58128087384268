import React, { useEffect, useState } from "react";
import { db } from "../../../../../utils/firebase";
import moment from "moment";
import "./replies.scss";

/**
 * UI components
 */
import Reply from "./reply/reply";

/**
 * Functional component to return the replies to the comment in scope
 */
function Replies(props) {
    const [replies, setReplies] = useState([]);

    /**
     * Deconstruct the comment ID and IDs for the comment from the props
     */
    const { comment } = props;
    const { client, project, content, file } = props.ids;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the replies for the current comment
         */
        const unsubscribe = db.collection(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments/${comment}/replies`)
            .orderBy("created")
            .onSnapshot((repliesSnap) => {
                /**
                 * Loop over the changes passed down
                 */
                repliesSnap.docChanges().forEach(async (change) => {
                    /**
                     * Added or modified case, both included here as almost same logic applies
                     */
                    if (change.type === "added" || change.type === "modified") {
                        /**
                         * Deconstruct the attributes of the comment from the changed document
                         */
                        const { user, created, comment, useragent } = change.doc.data();
                        /**
                         * Build the date object from the comment
                         */
                        const commentDate = moment.unix(created?.seconds).format("Do MMM YYYY HH:mm");
                        /**
                         * Get the users details (name and email) who left the comment
                         */
                        const replyUser = await db.doc(`users/${user}`)
                            .get().then((userDoc) => {
                                /**
                                 * If the user document exists
                                 */
                                if (userDoc.exists) {
                                    /**
                                     * Deconstruct the name and email for the user
                                     */
                                    const { name, email } = userDoc.data();
                                    /**
                                     * Return either their name or email
                                     */
                                    return name ? name : email;
                                }
                            });
                        /**
                         * Build the comment object to push onto the state
                         */
                        const replyElement = {
                            id: change.doc.id || null,
                            user: replyUser,
                            date: commentDate || null,
                            comment: comment || null,
                            useragent: useragent || null,
                        };
                        /**
                         * Was this comment added or ammended?
                         */
                        if (change.type === "added") {
                            /**
                             * If it was added, push it to the array
                             */
                            setReplies((replies) => [...replies, replyElement]);
                        } else {
                            /**
                             * If it was modified, find and update the current comment in the array
                             */
                            setReplies((replies) => {
                                /**
                                 * Create a new mutable array to update
                                 */
                                let updatedReplies = [...replies];
                                /**
                                 * Loop over the replies passed in from the update state
                                 */
                                for (let i in replies) {
                                    /**
                                     * If the changed document matches the one on this iteration
                                     */
                                    if (replies[i].id === change.doc.id) {
                                        /**
                                         * Push the new comment data to the updatedReplies array
                                         */
                                        updatedReplies[i] = replyElement;
                                        break;
                                    }
                                }
                                /**
                                 * Return the updated array to set the new state
                                 */
                                return updatedReplies;
                            });
                        }
                    }
                    /**
                     * Removed case
                     */
                    if (change.type === "removed") {
                        /**
                         * Remove the reply from the array using it's ID
                         */
                        setReplies((replies) => replies.filter((replyElement) => replyElement.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * Are there any replies to display?
     */
    if (replies.length > 0) {
        /**
         * Print them under the comment
         */
        return (
            <div className={["replies-column", props.status].join(" ")}>
                {[...replies].map((reply, index) => (
                    <Reply key={reply.id} replyID={reply.id} commentID={comment} reply={reply} />
                ))}
            </div>
        );
    } else {
        return null;
    }
}

export default Replies;