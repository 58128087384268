import React, { useState, createContext } from "react";

/**
 * Setup a context for storing data associated to the modal
 */
const ModalContext = createContext();

/**
 * Create a context for the Alerts across the app. To run an alert the context can be accessed 
 * through the function which will push a new alert onto the local state here for displaying
 * 
 * @returns Provider for the alerts context
 */
const ModalProvider = ({ children }) => {
    const [modal, setModal] = useState({});

    /**
     * Push a new alert onto the state, thus passing it down the context
     * 
     * @type {const}
     */
    const showModal = ({ type, title, body, cancel, next }) => {
        /**
         * Update the state with the new alert
         */
        setModal({
            type, title, body, cancel, next
        });
    }

    /**
     * Dismiss the modal (remove the data from context & state)
     * 
     * @type {const}
     */
    const dismissModal = () => {
        /**
         * Remove the modal data from the state, hiding it from view
         */
        setModal({});
    }

    return (
        <ModalContext.Provider value={{ modal, showModal, dismissModal }}>
            {children}
        </ModalContext.Provider>
    );
}

export { ModalContext, ModalProvider };
