import React, { useEffect, useState, createContext } from "react";
import { auth, db } from "../firebase";
import firebase from "firebase";

/**
 * Log the user out
 */
const logoutRequest = () => {
    /**
     * Sign the user out of the local auth context
     */
    firebase.auth().signOut().then(() => {
        /**
         * Redirect the user back to the root when they're logged out
         */
        window.location.href = "/";
    });
};

/**
 * Setup a context for storing the user object
 */
const AuthContext = createContext();

/**
 * Creating the auth provider to wrap the application in for providing the user object
 *
 * @param {Object} params
 * @returns Authentication provider to wrap the application in
 */
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({
        loading: true,
        logged_in: false,
    });

    /**
     * On component load (when the app is first loaded)
     */
    useEffect(() => {
        /**
         * Listen for changes on the auth
         */
        auth.onAuthStateChanged((user) => {
            /**
             * Was there a user document returned
             */
            if (user) {
                /**
                 * Setup a listener on their document to stream any changes into the context
                 */
                db.doc(`users/${user.uid}`).onSnapshot((userSnap) => {
                    /**
                     * Get the agencies from the user document
                     */
                    const { agencies } = userSnap.data();
                    setUser({
                        loading: false,
                        logged_in: true,
                        is_agency: agencies && agencies.length > 0,
                        uid: user.uid,
                        ...userSnap.data(),
                    });
                });
            } else {
                /**
                 * Otherwise set the auth context to reflect a no-login
                 */
                setUser({
                    loading: false,
                    logged_in: false,
                });
            }
        });
    }, []);

    return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

export { logoutRequest, AuthContext, AuthProvider };