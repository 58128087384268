import React, { useContext } from "react";
import firebase from "firebase";
import { ContentContext } from "../../../utils/providers/content";
import { AlertsContext } from "../../../utils/providers/alerts";
import { db, auth, } from "../../../utils/firebase";

/**
 * UI components
 */
import Button from "../../design-system/ui/button/button";

/**
 * Functional component printing the HTML makrup for the actions a user can perform on the content pages.
 * 
 * @returns HTML markup for the content actions 'approve' and 'reject'
 */
function Actions() {
    /**
     * Get the content IDs from the content context
     */
    const { ids, content } = useContext(ContentContext);

    /**
     * Get the push function for alerts
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Handles the click event of the approve button for the content page.
     * 
     * Here we just update the database with the content status. There is a cloud function that listens
     * for the documents to be updated and fires the emails off instead of clogging up the front end.
     * 
     * @type {const}
     */
    const approveContent = () => {
        /**
         * Get the IDs from the content context variable
         */
        const { client, project, content, file } = ids;
        /**
         * Get the current timestamp and build an update string
         */
        const updateQuery = {}
        updateQuery[`approvedBy.${auth.currentUser.uid}`] = firebase.firestore.FieldValue.serverTimestamp();
        /**
         * Push the current users ID onto the approvedBy array for this file
         */
        db.doc(`clients/${client}/projects/${project}/content/${content}/files/${file}`).update(updateQuery);
        /**
         * Push an alert
         */
        pushAlert({
            type: "SUCCESS",
            title: "Content approved",
            body: "We'll let stakeholders know via email that you've approved this content"
        });
    }

    /**
     * Handles the click event of reject button for the content page.
     * 
     * Here we just update the database with the content status. There is a cloud function that listens 
     * for the documents to be updated and fires the emails off instead of clogging up the front end.
     * 
     * @type {const}
     */
    const rejectContent = () => {
        /**
         * Get the IDs from the content context variable
         */
        const { client, project, content, file } = ids;
        /**
         * Get the current timestamp and build an update string
         */
        const updateQuery = {}
        updateQuery[`rejectedBy.${auth.currentUser.uid}`] = firebase.firestore.FieldValue.serverTimestamp();
        /**
         * Push the current users ID onto the rejectedBy array for this file
         */
        db.doc(`clients/${client}/projects/${project}/content/${content}/files/${file}`).update(updateQuery);
        /**
         * Push an alert
         */
        pushAlert({
            type: "SUCCESS",
            title: "Content rejected",
            body: "We'll let stakeholders know via email that there is still work to be done"
        });
    }

    return (
        <div className="actions">
            <p className="title small">Content Action</p>
            <div className="buttons">
                {!content?.approved &&
                    <Button label="Approve" type="POSITIVE" className="is-small" onClick={() => approveContent()} />
                }

                {!content?.rejected &&
                    <Button label="Reject" type="NEGATIVE" className="is-small" onClick={() => rejectContent()} />
                }
            </div>
        </div>
    );
}

export default Actions;