import React, { useState, createContext } from "react";

/**
 * Setup a context for storing details on the content object
 */
const ContentContext = createContext();

/**
 * Create a context to hold details on the content object the user has loaded.
 *
 * This will provide children with the access they need for generic data about the content such as
 * timestamp (if it's a video), cursor positions, comment text etc.
 *
 * @return Context provider for the content page & children to access
 */
const ContentProvider = ({ children }) => {
    const [ids, setIDs] = useState(null);
    const [content, setContent] = useState(null);
    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    const [cursorXPixels, setCursorXPixels] = useState(0);
    const [cursorYPixels, setCursorYPixels] = useState(0);
    const [media, setMedia] = useState(0);
    const [seekToSeconds, setSeekToSeconds] = useState(0);
    const [currentComment, setCurrentComment] = useState("");
    const [canvasData, setCanvasData] = useState("");
    const [feedbackTool, setFeedbackTool] = useState("PIN");
    const [brushColour, setBrushColour] = useState("#FFF");
    const [brushSize, setBrushSize] = useState(8);
    const [undoDraw, setUndoDraw] = useState(false);
    const [saveState, setSaveState] = useState(false);
    const [showFeedbackComment, setShowFeedbackComment] = useState(false);
    const [feedbackComment, setFeedbackComment] = useState("");

    return <ContentContext.Provider
        value={{
            ids,
            setIDs,
            cursorX,
            setCursorX,
            cursorY,
            setCursorY,
            cursorXPixels,
            setCursorXPixels,
            cursorYPixels,
            setCursorYPixels,
            media,
            setMedia,
            content,
            setContent,
            seekToSeconds,
            setSeekToSeconds,
            currentComment,
            setCurrentComment,
            canvasData,
            setCanvasData,
            feedbackTool,
            setFeedbackTool,
            brushColour,
            setBrushColour,
            brushSize,
            setBrushSize,
            undoDraw,
            setUndoDraw,
            saveState,
            setSaveState,
            showFeedbackComment,
            setShowFeedbackComment,
            feedbackComment,
            setFeedbackComment,
        }}>
        {children}
    </ContentContext.Provider>;
};

export { ContentContext, ContentProvider };
