import React, { useContext, useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import { AuthContext } from "../../utils/providers/auth";
import "./clients.scss";

/**
 * UI components & structure blocks
 */
import Client from "../../components/client/client";
import AddClient from "../../components/client/add/add";
import Tile from "../../components/design-system/structure/tile/tile";
import Title from "../../components/design-system/structure/title/title";

/**
 * Functional component to return a display list of the clients this user has access to
 */
function Clients() {
    const [clients, setClients] = useState([]);
    const [sortedClients, setSortedClients] = useState([]);
    const [displayMode, setDisplayMode] = useState("CARD");

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * When the user UID is loaded from the context
     */
    useEffect(() => {
        /**
         * Setup a listener on the users "clients" collection to stream a list of them into the 
         * local state here
         */
        const unsubscribe = db.collection(`users/${user.uid}/clients`)
            .onSnapshot((clientsSnap) => {
                /**
                 * Loop over the client documents found
                 */
                clientsSnap.docChanges().forEach((change) => {
                    /**
                     * Client document added
                     */
                    if (change.type === "added") {
                        setClients((clients) => [
                            ...clients,
                            { id: change.doc.id, ...change.doc.data() }
                        ]);
                    }
                    /**
                     * Client document updated
                     */
                    if (change.type === "modified") {
                        setClients((clients) => {
                            let updatedClients = [...clients];
                            for (let i in clients) {
                                if (clients[i].id === change.doc.id) {
                                    updatedClients[i] = { id: change.doc.id, ...change.doc.data() };
                                    break;
                                }
                            }
                            return updatedClients;
                        });
                    }
                    /**
                     * Client document removed
                     */
                    if (change.type === "removed") {
                        setClients((clients) => clients.filter((client) => client.id !== change.doc.id));
                    }
                });
            });
        /**
         * Unsubscribe from the listener on component unload
         */
        return () => unsubscribe();
    }, [user.uid]);

    /**
     * When the project content snapshot updates the state
     */
    useEffect(() => {
        /**
         * Sort function, sorting by created seconds
         */
        const sortByName = (a, b) => {
            return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
        }
        /**
         * Sort the content into date order
         */
        setSortedClients([...clients.sort(sortByName)]);
    }, [clients]);

    /**
     * Append the client name passed back up from the listener on the client component to the local 
     * state here for sorting the client cards alphabetically.
     */
    const appendName = (clientID, name) => {
        /**
         * Update the relevant client object in the state
         */
        setClients((clients) => {
            let updatedClients = [...clients];
            for (let i in clients) {
                if (clients[i].id === clientID) {
                    updatedClients[i] = { ...clients[i], name };
                    break;
                }
            }
            return updatedClients;
        });
    }

    return (
        <Tile fullPage={true}>
            <Title hasBorder={true}>
                <h1>Clients</h1>
            </Title>

            <div className={["clients-display", displayMode].join(" ")}>
                {/* Add client form */}
                <AddClient />

                {/* Print out all the clients loaded in the state */}
                {sortedClients.map((client) =>
                    <Client
                        key={client.id}
                        type="CARD"
                        client={client}
                        appendName={appendName} />
                )}
            </div>
        </Tile>
    );
}

export default Clients;