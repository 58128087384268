import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { db, auth } from "../../../../../utils/firebase";

/**
 * Functional component to just return a link to the users panel for the agencies
 */
function AgencyLink(props) {
    const [agencyTitle, setAgencyTitle] = useState("");
    const [hasPermission, setHasPermission] = useState(false);

    /**
     * Deconstruct the agency Id from the props
     */
    const { agencyID } = props;

    /**
     * When we have a agency ID passed in
     */
    useEffect(() => {
        /**
         * Setup a listener on the agency database
         */
        const unsubscribe = db.doc(`agencies/${agencyID}`)
            .onSnapshot((agencySnap) => {
                /**
                 * Deconstruct the data from the agency document
                 */
                const { name } = agencySnap.data();
                /**
                 * Update the state
                 */
                setAgencyTitle(name);
            });
        /**
         * Unsubscribe from the listener on component unmount
         */
        return () => unsubscribe();
    }, [agencyID]);

    /**
     * When the agencyID is updated
     */
    useEffect(() => {
        /**
         * Setup a listener on the current users document in the users subcollection for 
         * this agency
         */
        const unsubscribe = db.doc(`agencies/${agencyID}/users/${auth.currentUser.uid}`)
            .onSnapshot((userSnap) => {
                /**
                 * Check to make sure the user is assigned to the agency
                 */
                if (userSnap.exists) {
                    /**
                     * Deconstruct the permissions for this user
                     */
                    const { permissions } = userSnap.data();
                    /**
                     * Push the MANAGE_USERS permission to the local state
                     */
                    setHasPermission(permissions.MANAGE_USERS);
                }
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, [agencyID]);

    if (hasPermission) {
        /**
         * If the user has access to manage the agency users
         */
        return (
            <li>
                <NavLink to={`/agency/${agencyID}/users`} activeClassName="active">
                    {agencyTitle}
                </NavLink>
            </li>
        );
    } else {
        /**
         * If they dont
         */
        return null;
    }
}

export default AgencyLink;