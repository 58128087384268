import React from "react";
import "./badge.scss";

/**
 * Functional component for a small badge
 * 
 * @param {object} props Props passed into the badge component
 * @returns HTML markup for the badge
 */
function Badge(props) {
    return (
        <span className={["ui-badge", props.type].join(" ")}>
            {props.label}
        </span>
    );
}

export default Badge;