import React from "react";
import "./radio-button.scss";

/**
 * Functional component for the radio-button element
 *
 * @param {object} props Props passed into the radio-button component
 * @returns HTML markup and functionality for the radio-button component
 */
function RadioButton(props) {
    return (
        <div
            className={["ui-radio-button", props.className].join(" ")}
            onClick={() => props.onClick()}
        >
            <div className={["ui-radio-button-box", props.checked && "is-checked"].join(" ")} />
            <label>{props.label}</label>
        </div>
    );
}

export default RadioButton;
