import React from "react";
import "./errors.scss";

/**
 * Functional component to return the 404 error page
 */
function Error404() {
    return (
        <div className="error-page-wrap">
            <h1>
                <span>404</span>Page not found
            </h1>
        </div>
    );
}

export default Error404;