// Import action types
import * as actionTypes from '../actions';

// Setup an inital state
const initState = {
    alerts: []
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.ALERT_PUSH:
            return {
                ...state,
                alerts: [...state.alerts, action.payload]
            }

        default:
            return state;
    }
};

export default reducer;