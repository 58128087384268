import bowser from "bowser";

/**
 * Returns an object containing information about the users device
 */
export const getUserAgent = () => {
    /**
     * Get the userAgent from the browser
     */
    const browser = bowser.parse(window.navigator.userAgent);
    /**
     * Pull the data we require
     */
    const windowProps = {
        location: window.location.pathname,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        viewportHeight: window.innerHeight,
        viewportWidth: window.innerWidth,
        pixelDepth: window.screen.pixelDepth
    }
    /**
     * Return the full browser object along with our refined list
     */
    return { ...browser, window: windowProps };
}