import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";
import moment from "moment";

/**
 * UI components
 */
import { CaretDownIcon, CaretUpIcon } from "../../../utils/svgs";

/**
 * Functional component to return the approval stats card on the dashboard
 * 
 * @param {object} props Props object should contain currentPeriod & previousPeriod values
 */
function Approvals(props) {
    const [currentApprovals, setCurrentApprovals] = useState(0);
    const [previousApprovals, setPreviousApprovals] = useState(0);
    const [difference, setDifference] = useState(0);
    const [differenceDirection, setDifferenceDirection] = useState("NEUTRAL");

    /**
     * Deconstruct the user preferences from the props
     */
    const { currentPeriod, previousPeriod } = props;

    /**
     * When the currentPeriod & previousPeriod are updated from the dashboard
     */
    useEffect(() => {
        /**
         * Make sure we have both a current and previous period available
         */
        if ((currentPeriod?.unix() > 0) && (previousPeriod?.unix() > 0)) {
            /**
             * Setup an array for holding the downloads
             */
            const cApprovals = [];
            const pApprovals = [];
            /**
             * Fetch the clients assigned in the current period
             */
            db.collection(`users/${auth.currentUser.uid}/projects`)
                .get().then((projectDocs) => {
                    /**
                     * Loop over each project 
                     */
                    projectDocs.forEach((project) => {
                        /**
                         * Get the clientID from the project
                         */
                        const { client } = project.data();
                        /**
                         * Make a call to the content collection for this project within the client
                         */
                        db.collection(`clients/${client}/projects/${project.id}/content`)
                            .get().then((contentDocs) => {
                                /**
                                 * Loop through the content documents
                                 */
                                contentDocs.forEach((content) => {
                                    /**
                                     * Make a call to the content collection for this project within the client
                                     */
                                    db.collection(`clients/${client}/projects/${project.id}/content/${content.id}/files`)
                                        .get().then((fileDocs) => {
                                            /**
                                             * Loop through the content documents
                                             */
                                            fileDocs.forEach((file) => {
                                                /**
                                                 * Get the approvedBy array from the file document
                                                 */
                                                const { approvedBy } = file.data();
                                                /**
                                                 * If there is some downloads recorded
                                                 */
                                                if (approvedBy && Object.keys(approvedBy).length > 0) {
                                                    /**
                                                     * Loop through the approvedBy records
                                                     */
                                                    for (const approval in approvedBy) {
                                                        /**
                                                         * Get the timestamp of the approval
                                                         */
                                                        const timestamp = approvedBy[approval].seconds;
                                                        /**
                                                         * Get the timestamp forthe current & previous period
                                                         */
                                                        const currentPeriodTimestamp = moment(currentPeriod).unix();
                                                        const previousPeriodTimestamp = moment(previousPeriod).unix();
                                                        /**
                                                         * Is the timestamp of the approval greater than the currentPeriod 
                                                         * (would suggest it has been approved in the current period)
                                                         */
                                                        if (timestamp > currentPeriodTimestamp) {
                                                            /**
                                                             * Push the approval into the current array
                                                             */
                                                            cApprovals.push(`${approval}@${timestamp}`);
                                                            /**
                                                             * Update the state
                                                             */
                                                            setCurrentApprovals(cApprovals?.length);
                                                        } else if ((timestamp <= currentPeriodTimestamp) && (timestamp > previousPeriodTimestamp)) {
                                                            /**
                                                             * If the timestamp falls between the previous period
                                                             */
                                                            pApprovals.push(`${approval}@${timestamp}`);
                                                            /**
                                                             * Update the state
                                                             */
                                                            setPreviousApprovals(pApprovals?.length);
                                                        }
                                                    }
                                                }
                                            });
                                        });
                                });
                            });
                    });
                });
        }
    }, [currentPeriod, previousPeriod]);

    /**
     * When the currentApprovals and previousApprovals are updated
     */
    useEffect(() => {
        /**
         * Work out the difference between the two as a percentage
         */
        const difference = Math.round((currentApprovals - previousApprovals) * 100);
        /**
         * Update the state with the relevant direction
         */
        if (difference === 0) {
            setDifferenceDirection("NEUTRAL");
        } else if (difference > 0) {
            setDifferenceDirection("POSITIVE");
        } else if (difference < 0) {
            setDifferenceDirection("NEGATIVE");
        }
        /**
         * Push the difference into the local state too
         */
        setDifference(difference);
    }, [currentApprovals, previousApprovals]);

    return (
        <div className="statistic-card">
            <h6>Approvals</h6>
            <h5>
                <span>{currentApprovals}</span>
                <small className={differenceDirection}>
                    {/* Is the difference neutral */}
                    {(differenceDirection === "NEUTRAL") && "-"}

                    {/* Is the difference positive */}
                    {(differenceDirection === "POSITIVE") && <CaretUpIcon />}

                    {/* Or is the difference negative */}
                    {(differenceDirection === "NEGATIVE") && <CaretDownIcon />}

                    {difference}%
                </small>
            </h5>
        </div>
    );
}

export default Approvals;