import React, { useEffect, useState, useContext } from "react";
import { AlertsContext } from "../../utils/providers/alerts";
import { db, auth, arrayRemove } from "../../utils/firebase";
import "./invite.scss";

/**
 * Functional component to return an invite row on the users dashboard
 */
function Invite(props) {
    const [invite, setInvite] = useState(false);
    const [from, setFrom] = useState("");
    const [agency, setAgency] = useState("");

    /**
     * Deconstruct the ID for the invite from the props
     */
    const { id } = props;

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the invite from the database
         */
        db.doc(`invites/${id}`).get().then((inviteDoc) => {
            /**
             * Make sure the invite exists
             */
            if (inviteDoc.exists) {
                /**
                 * Set the invite data into the state
                 */
                setInvite(inviteDoc.data());
            }
        });
    }, []);

    /**
     * When the invite object is updated
     */
    useEffect(() => {
        /**
         * We need to get the user document who invited them
         */
        db.doc(`users/${invite.fromID}`).get().then((userDoc) => {
            /**
             * Check the user record exists
             */
            if (userDoc.exists) {
                /**
                 * Set their name into the lcoal state
                 */
                setFrom(userDoc.data().name || userDoc.data().email);
            }
        });
        /**
         * Then get the agency name
         */
        db.doc(`agencies/${invite.agencyID}`).get().then((agencyDoc) => {
            /**
             * Check the agency exists
             */
            if (agencyDoc.exists) {
                /**
                 * Set the agency name into the state
                 */
                setAgency(agencyDoc.data().name);
            }
        });
    }, [invite]);

    /**
     * Accept the invite 
     */
    const acceptInvite = () => {
        /**
         * Update the invite document in the database, a cloud funciton will handle adding 
         * the agency documents etc
         */
        db.doc(`invites/${id}`).set({
            status: "accepted",
        }, { merge: true });
        /**
         * Remove the invite from this users array on their database document
         */
        db.doc(`users/${auth.currentUser.uid}`).set({
            invites: arrayRemove(id),
        }, { merge: true });
        /**
         * Push an alert into the dom
         */
        pushAlert({
            type: "SUCCESS",
            title: "Invite accepted",
        });
    }

    /**
     * Ignore/decline the invite
     */
    const ignoreInvite = () => {
        /**
         * Update the invite document in the database, a cloud funciton will handle adding 
         * the agency documents etc
         */
        db.doc(`invites/${id}`).set({
            status: "declined",
        }, { merge: true });
        /**
         * Remove the invite from this users array on their database document
         */
        db.doc(`users/${auth.currentUser.uid}`).set({
            invites: arrayRemove(id),
        }, { merge: true });
        /**
         * Push an alert into the dom
         */
        pushAlert({
            title: "Invite declined",
        });
    }

    /**
     * If we have all the data needed to print the invite
     */
    if (invite && from && agency) {
        /**
         * Return the invite row for acceptance
         */
        return (
            <div className="dashboard-invite-row">
                <p>{from} has invited you to join {agency}</p>
                <div className="invite-actions">
                    <button className="invite-action ignore" onClick={() => ignoreInvite()}>
                        Ignore
                    </button>
                    <button className="invite-action accept" onClick={() => acceptInvite()}>
                        Accept
                    </button>
                </div>
            </div>
        );
    } else {
        /**
         * Return nothing until the invite data is loaded in
         */
        return (
            <div className="dashboard-invite-row">
                <p>Loading invite...</p>
            </div>
        );
    }
}

export default Invite;