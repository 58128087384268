import React from "react";
import "./errors.scss";

/**
 * Functional component to return the 403 error page
 */
function Error403() {
    return (
        <div className="error-page-wrap">
            <h1>
                <span>403</span>Unauthorised
            </h1>
        </div>
    );
}

export default Error403;