import React from 'react';
import './toggle.scss';

/**
 * Functional component for a toggle, when clicked the result will be passed back up the props
 * 
 * @param {object} props Props passed itno the Toggle component 
 * @returns HTML markup for the toggle component
 */
function Toggle(props) {
    return (
        <div className={["ui-toggle-wrapper", props.className, props.labelAfter && "is-flipped"].join(" ")}>
            {/* Is there a label to show next to it */}
            {props.label && <label>{props.label}</label>}

            {/* Toggle markup */}
            <div className={["ui-toggle", props.type, props.on && "is-on"].join(' ')} onClick={() => props.onClick()} />
        </div>
    );
};

export default Toggle;