import React, { useEffect, useState } from "react";
import { db } from "../../../../utils/firebase";
import moment from "moment";

/**
 * UI components
 */
import Badge from "../../../design-system/ui/badge/badge";

/**
 * Functional component to return the invoices attached to an agency account
 */
export default function Invoices({ agencyID }) {
    const [invoices, setInvoices] = useState([]);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the invoices subcollection for the agency
         */
        const unsubscribe = db.collection(`agencies/${agencyID}/invoices`)
            .orderBy("created", "desc")
            .limit(6)
            .onSnapshot((invoicesSnap) => {
                /**
                 * Loop over the changes passed down
                 */
                invoicesSnap.docChanges().forEach((change) => {
                    /**
                     * Invoice item added
                     */
                    if (change.type === "added") {
                        setInvoices((invoices) => [...invoices, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Invoice item modified
                     */
                    if (change.type === "modified") {
                        setInvoices((invoices) => {
                            let updatedInvoices = [...invoices];
                            for (let i in invoices) {
                                if (invoices[i].id === change.doc.id) {
                                    updatedInvoices[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedInvoices;
                        });
                    }
                    /**
                     * Invoice item removed
                     */
                    if (change.type === "removed") {
                        setInvoices((invoices) => invoices.filter((invoice) => invoice.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    return (
        <div className="edit-details-block">
            <h2>Invoices</h2>
            <p>A list of your 6 most recent invoices</p>
            <table className="invoices-table">
                <thead>
                    <tr>
                        <th>Invoice number</th>
                        <th>Date raised</th>
                        <th>Due date</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice) => (
                        <tr key={invoice.id}>
                            <td>
                                <p>{invoice.id === "_UPCOMING" ? "Upcoming" : invoice.number}</p>
                            </td>
                            <td>
                                <p>{moment(invoice.created, "X").format("DD/MM/YYYY")}</p>
                            </td>
                            <td>
                                {/* If there is a due date present for the invoice */}
                                {invoice.due_date &&
                                    <p>{moment(invoice.due_date, "X").format("DD/MM/YYYY")}</p>
                                }

                                {/* If there isn't we'll assume it's to be charged immediately */}
                                {!invoice.due_date &&
                                    <p>{moment(invoice.created, "X").format("DD/MM/YYYY")}</p>
                                }
                            </td>
                            <td>
                                <p>£{invoice.amount_due / 100}</p>
                            </td>
                            <td>
                                {(invoice.status === "draft") && <Badge label="Upcoming" />}
                                {(invoice.status === "void") && <Badge label="Void" />}
                                {(invoice.status === "open") && <Badge type="INFO" label="Open" />}
                                {(invoice.status === "paid") && <Badge type="POSITIVE" label="Paid" />}
                                {(invoice.status === "failed") && <Badge type="NEGATIVE" label="Failed" />}
                                {(invoice.status === "uncollectible") && <Badge type="NEGATIVE" label="Uncollectable" />}
                            </td>
                            <td className="invoice-action-buttons">
                                {/* If there is a hosted invoice available */}
                                {invoice.invoice_url &&
                                    <a href={invoice.invoice_url} target="_blank" rel="noopener noreferrer">
                                        View
                                    </a>
                                }

                                {/* If there is a invoice PDF available */}
                                {invoice.invoice_pdf_url &&
                                    <a href={invoice.invoice_pdf_url} target="_blank" rel="noopener noreferrer">
                                        Download
                                    </a>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}