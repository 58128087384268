import React from "react";
import { BrowserRouter } from 'react-router-dom';

/**
 * Global app styles
 */
import "./app.scss";

/**
 * Routes provider
 */
import AppRoutes from "../../utils/routes";

/**
 * Auth context provider
 */
import { AuthProvider } from "../../utils/providers/auth";

/**
 * Functional component for the App. Here we are just wrapping all the calls into the app with the 
 * browser router libary from react-router-dom. The actual route parsing is done in the AppRoutes 
 * functional component.
 */
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;