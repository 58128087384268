export const screen_sizes = {
    "3840x2160": "Desktop 4K",
    "2560x1440": "Desktop 1440p",
    "1920x1080": "Desktop 1080p",
    "1280x800": 'MacBook Pro 13"',
    "1440x900": 'MacBook Pro 15"',
    "1536x960": 'MacBook Pro 16"',
    "414x896": "iPhone XS Max",
    "375x812": "iPhone XS",
    "375x812": "iPhone X",
    "1024x1366": "iPad Pro",
    "768x1024": "iPad Mini",
    "411x869": "Google Pixel 4",
    "360x740": "Samsung Galaxy S9+",
    "360x740": "Samsung Galaxy S9",
    "412x892": "One Plus 7 Pro",
    "768x1024": "Nexus 9",
};
