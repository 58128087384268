import React, { useContext, useEffect, useState } from 'react';
import { db } from '../../../utils/firebase';

/**
 * Contexts
 */
import { AuthContext } from "../../../utils/providers/auth";
import { ContentContext } from "../../../utils/providers/content";

/**
 * UI components
 */
import Users from './users/users';
import Notes from './notes';
import Actions from './actions';
import Button from '../../design-system/ui/button/button';
import Select from '../../design-system/ui/select/select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

/**
 * Functional component that returns the HTML markup for the meta details that sit above the comments 
 * on the content review page.
 * 
 * @returns HTML markup for the 
 */
function ConentMeta(props) {
    const [toggled, setToggled] = useState(true);
    const [showBack, setShowBack] = useState(false);
    const [files, setFiles] = useState({});
    const [showNavigation, setShowNavigation] = useState(false);

    /**
     * Deconstruct the viewID from the props
     */
    const { viewID } = props;

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Get the relevant data for this content from the content context
     */
    const { content, ids } = useContext(ContentContext);

    /**
     * Check the referrer on whether to show a back button or not
     */
    useEffect(() => {
        /**
         * Check if we have a project ID in the context and if the referring URL to find a match 
         * to the projects timeline URL
         */
        if (ids?.project && document.referrer.endsWith(`/project/${ids.project}/timeline`)) {
            /**
             * Set the back button to show
             */
            setShowBack(true);
        }
    }, [ids]);

    /**
     * When the ids are updated
     */
    useEffect(() => {
        /**
         * Make sure the ids have loaded down from the context
         */
        if (ids) {
            /**
             * Deconstruct the IDs required from the data
             */
            const { client, project, content } = ids;
            /**
             * Pull a list of the content files from the database
             */
            db.collection(`clients/${client}/projects/${project}/content/${content}/files`)
                .orderBy("name")
                .get().then((fileDocs) => {
                    /**
                     * If there are multiple files for the content, show the navigation
                     */
                    fileDocs.size > 1 && setShowNavigation(true);
                    /**
                     * Loop through each of the files found
                     */
                    fileDocs.forEach((fileDoc) => {
                        /**
                         * If the current viewID doesn't match the file we are iterating over
                         */
                        if ((fileDoc.id !== viewID) && (!fileDoc.data().format.startsWith("application"))) {
                            /**
                             * Set the file ID into the state with the name for the dropdown
                             */
                            setFiles((files) => ({
                                ...files,
                                [fileDoc.id]: fileDoc.data().name,
                            }));
                        }
                    });
                });
        }
    }, [ids]);

    /**
     * Take in a content ID and redirect the user to the page to view it
     */
    const redirectToContent = (contentID) => {
        window.location.href = `/content/${contentID}`;
    }

    return (
        <div id="cview-meta">
            <div className={['cview-meta-wrap', toggled ? 'toggled' : ""].join(' ')}>
                <div className="cview-meta-inner">
                    <p className="title">{content?.title}</p>

                    {/* Active users */}
                    <Users viewID={viewID} />

                    {/* Agency notes */}
                    <Notes user={user} />

                    {showNavigation &&
                        <div className="content-navigation">
                            <p className="title small">Navigate content</p>
                            <Select
                                placeholder="Choose file"
                                options={files}
                                onSelect={(option) => redirectToContent(option.option)} />
                        </div>
                    }

                    {/* Approve and Reject button */}
                    <Actions />

                    {/* Back button to return to the timeline */}
                    {showBack &&
                        <Button id="back-button" label="Go Back" className="is-small" onClick={() => window.history.back()} />
                    }
                </div>
            </div>

            {/* Toggle markup for hiding the meta tab */}
            <div className="cview-meta-toggle" onClick={() => setToggled((toggled) => !toggled)}>
                <p>{toggled ? "Hide Details" : "Show Details"}</p>
                <FontAwesomeIcon icon={toggled ? faCaretUp : faCaretDown} />
            </div>
        </div >
    );
}

export default ConentMeta;