import React, { useEffect, useContext, useState } from "react";
import firebase from "firebase";
import { db, auth, arrayUnion } from "../../../utils/firebase";
import { AuthContext } from "../../../utils/providers/auth";
import { AlertsContext } from "../../../utils/providers/alerts";
import "./add.scss";

/**
 * UI components
 */
import Input from "../../design-system/ui/inputs/input";
import Select from "../../design-system/ui/select/select";
import Button from "../../design-system/ui/button/button";
import { PlusIcon } from "../../../utils/svgs";

/**
 * Functional component wrapping a form for the user to add a client and assign them to a 
 * specific agency account
 * 
 * @returns HTML markup and funcitonality for the add client form
 */
function AddClient() {
    const [formToggled, setFormToggled] = useState(false);
    const [agencies, setAgencies] = useState({});
    const [clientName, setClientName] = useState("");
    const [clientAgency, setClientAgency] = useState({});
    const [addingClient, setAddingClient] = useState(false);

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the pushAlert function from the alerts context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch the current users agencies from their context
         */
        if (user?.agencies) {
            /**
             * Loop over the agencies
             */
            user.agencies.forEach((agency) => {
                /**
                 * Fetch the user document for this agency
                 */
                db.doc(`agencies/${agency}/users/${auth.currentUser.uid}`).get().then(async (userDoc) => {
                    /**
                     * Does the document exist and does it contain the "MANAGE_CLIENTS" permission
                     */
                    if (userDoc.exists && userDoc.data().permissions?.MANAGE_CLIENTS) {
                        /**
                         * Get the agency name
                         */
                        const agencyName = await db.doc(`agencies/${agency}`).get().then((agencyDoc) => {
                            return agencyDoc.data().name
                        });
                        /**
                         * Then they can add to this agency
                         */
                        setAgencies((agencies) => { return { ...agencies, [agency]: agencyName } });
                    } else {
                        /**
                         * Otherwise they can't add
                         */
                        return false;
                    }
                });
            });
        }
    }, [user]);

    /**
     * Add the client to the specified agency
     */
    const addClient = async () => {
        /**
         * Set the state to loading
         */
        setAddingClient(true);
        /**
         * Add a new document to the clients collection and return the document ID
         */
        const clientID = await db.collection("clients").add({
            name: clientName,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            agency: clientAgency.option,
            adminUser: auth.currentUser.uid,
            admins: arrayUnion(auth.currentUser.uid),
            users: arrayUnion(auth.currentUser.uid),
        }).then((newClient) => { return newClient.id });
        /**
         * Add a document for this user to the new client
         */
        await db.doc(`clients/${clientID}/users/${auth.currentUser.uid}`).set({
            added: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Then create a document for this client on the user with the admin permissions
         */
        await db.doc(`users/${auth.currentUser.uid}/clients/${clientID}`).set({
            assigned: firebase.firestore.FieldValue.serverTimestamp(),
            permissions: {
                FEEDBACK: true,
                COLLABORATE: true,
                ADMINISTRATE: true
            }
        });
        /**
         * Then finally add the client ID to the agency document
         */
        await db.doc(`agencies/${clientAgency.option}`).update({
            clients: arrayUnion(clientID),
        });
        /**
         * When we are done, show an alert and reset the state
         */
        pushAlert({ type: "SUCCESS", title: "Client added" });
        setAddingClient(false);
        setFormToggled(false);
    }

    /**
     * When the formToggled is updated
     */
    useEffect(() => {
        /**
         * Clear the inputs
         */
        setClientName("");
        setClientAgency({});
    }, [formToggled]);

    return (
        <div className={["add-client-form", formToggled && "is-active"].join(" ")}>
            {/* Is the form currently not on show */}
            {!formToggled &&
                <div className="add-toggle" onClick={() => setFormToggled(true)}>
                    <PlusIcon />
                </div>
            }

            {/* Is the form currently showing */}
            {formToggled &&
                <div className="add-inputs">
                    <Input
                        value={clientName}
                        onChange={setClientName}
                        placeholder="Client Name:" />

                    <Select
                        placeholder="Choose Agency"
                        value={clientAgency.value}
                        onSelect={setClientAgency}
                        options={agencies} />

                    <Button
                        label="Add Client"
                        loading={addingClient}
                        loadingText="Adding..."
                        onClick={() => addClient()}
                        disabled={!clientName || !clientAgency.value} />

                    <Button
                        type="NEGATIVE"
                        label="Cancel"
                        onClick={() => setFormToggled(false)} />
                </div>
            }
        </div>
    );
}

export default AddClient;