import React, { useContext, useEffect, useState } from "react";
import { db, arrayUnion, arrayRemove } from "../../../../../utils/firebase";

/**
 * Contexts
 */
import { ModalContext } from "../../../../../utils/providers/modal";
import { AlertsContext } from "../../../../../utils/providers/alerts";

/**
 * UI components
 */
import Permission from "../../../../client/users/stakeholder/permission";

/**
 * Functional component to return the toggle switch for the users premium license
 */
function Premium(props) {
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    /**
     * Deconstruct the the data required from the props
     */
    const { userID, agencyID, premiumUsers, subscription } = props;

    /**
     * Deconstruct the showModal function from the Modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Deconstruct the pushAlert function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load, pull the billing details for the agency
     */
    useEffect(() => {
        /**
         * Get the agency document
         */
        db.doc(`agencies/${agencyID}`)
            .get().then((agencyDoc) => {
                /**
                 * Get the stripe object for the agency
                 */
                const { stripe } = agencyDoc.data();
                /**
                 * Push the payment_source into the state, or false if there isn't one
                 */
                setHasPaymentMethod(stripe.payment_source || false);
            });
    }, []);

    /**
     * We need to run through some logic before toggling the user license. The first being 
     * whether or not we are adding or removing the license.
     * 
     * If we are removing it, we nee dot subtract a license from the agency subscriptoin, if 
     * it leaves us with 0 licenses, then we also need to inform the admin that the 
     * subscription will expire at the end of the billing period.
     * 
     * If we are adding a license, check to see if there is a subscription setup for the agency, 
     * if there isn't we need to set one up before adding the license.
     */
    const toggleUserLicense = async () => {
        /**
         * Check first to see if the agency has a billing method attached
         */
        if (!hasPaymentMethod) {
            /**
             * Show an alert to say about adding a billing method
             */
            pushAlert({
                type: "ALERT",
                title: "No billing method",
                body: "Please setup a billing method before trying to assign a license",
            });
            /**
             * Close the toggle logic here
             */
            return false;
        }
        /**
         * Check to see if the premium users for the agency has this user already
         */
        if (premiumUsers.includes(userID)) {
            /**
             * Check to see if the length of this array is 1 (this means the current user in 
             * question would be the only person with a premium license)
             */
            if (premiumUsers.length === 1) {
                /**
                 * If it is, we need to show the user a modal to let them know the subscription 
                 * is going to be cancelled at the end of the billing period
                 */
                showModal({
                    type: "ALERT",
                    title: "Subscription Cancellation",
                    body: "By revoking, you are left with 0 licenses so we'll cancel your subscription at the end of the billing period.",
                    cancel: {
                        label: "Wait, Cancel",
                        action: () => { return null },
                    },
                    next: {
                        label: "Okay, Continue",
                        action: () => showRemoveLicenseModal(),
                    },
                });
            } else {
                /**
                 * If they are not going to be the last one left with a license, show a different
                 * modal saying the user will lose privaledges etc
                 */
                showRemoveLicenseModal();
            }
        } else {
            /**
             * First check to see if the agency doesn't currently have any premium users
             */
            if ((premiumUsers.length === 0) && !subscription) {
                /**
                 * If this is going to be the first user, we need to let them know a new subscription 
                 * will be setup from today
                 */
                showModal({
                    type: "INFO",
                    title: "Subscription Setup",
                    body: "This is your first license, we'll setup a subscription for you to view on your 'Billing' panel under Agency settings.",
                    cancel: {
                        label: "Cancel",
                        action: () => { return null },
                    },
                    next: {
                        label: "Okay, Continue",
                        action: () => showAddLicenseModal(),
                    },
                });
            } else {
                /**
                 * If the agency already has a subscription setup with Feeval, we just need to check they 
                 * are happy to add teh new license
                 */
                showAddLicenseModal();
            }
        }
    }

    /**
     * Show a modal saying about the cost per month
     */
    const showAddLicenseModal = () => {
        /**
         * Show the license adding notice 
         */
        showModal({
            type: "INFO",
            title: "Adding License",
            body: "You're adding a license for premium features, charged at £8 per month for this user, continue?",
            cancel: {
                label: "No, Cancel",
                action: () => { return null },
            },
            next: {
                label: "Yes Please",
                action: () => addUserLicense(),
            },
        });
    }

    /**
     * Show a modal about the fact the user will now lose premium features
     */
    const showRemoveLicenseModal = () => {
        /**
         * Show the license removal notice 
         */
        showModal({
            type: "ALERT",
            title: "Revoking License",
            body: "By revoking this users license, they'll lose all premium features, are you sure you want to continue?",
            cancel: {
                label: "No, Cancel",
                action: () => { return null },
            },
            next: {
                label: "Yes Please",
                action: () => removeUserLicense(),
            },
        });
    }

    /**
     * Add the new user to the premium license array for the agency
     */
    const addUserLicense = async () => {
        /**
         * Update the database record for the premium users
         */
        await db.doc(`agencies/${agencyID}`).set({
            premium_users: arrayUnion(userID),
        }, { merge: true });
        /**
         * Then show an alert
         */
        pushAlert({
            title: "Premium license assigned",
        });
    }

    /**
     * Remove the user in scope from the premium licenses
     */
    const removeUserLicense = async () => {
        /**
         * Update the database record for the premium users
         */
        await db.doc(`agencies/${agencyID}`).set({
            premium_users: arrayRemove(userID),
        }, { merge: true });
        /**
         * Then show an alert
         */
        pushAlert({
            title: "Premium license revoked",
        });
    }

    return (
        <>
            {/* Permissions */}
            <div className="userli-heading">
                <h1>Licenses</h1>
            </div>
            <div className="userli-permissions-block">
                <Permission toggled={premiumUsers?.includes(userID)} toggle={() => toggleUserLicense()}>
                    <h1>Is Premium</h1>
                    <p>Grant this user a premium license under your agency</p>
                </Permission>
            </div>
        </>
    );
}

export default Premium;