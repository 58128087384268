import React, { useEffect, useState } from "react";
import { NavLink, Switch, Route } from "react-router-dom";
import { db, auth } from "../../utils/firebase";

/**
 * UI components
 */
import Tile from "../../components/design-system/structure/tile/tile";
import Title from "../../components/design-system/structure/title/title";
import Navigation from "../../components/design-system/structure/navigation/navigation";

/**
 * Routes
 */
import Users from '../../components/agency/users/users';
import Edit from '../../components/agency/edit/edit';
import Billing from '../../components/agency/billing/billing';
import Error404 from "../errors/404";

/**
 * Functional component to return the routes that sit beneath the Agency route for assigned users,
 * billing and editing the basic details
 */
function Agency(props) {
    const [agencyName, setAgencyName] = useState("");
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(true);

    /**
     * Deconstruct the url parmaters from the props
     */
    const { url } = props.match;
    const { id } = props.match.params;

    /**
     * When we have the ID for the agency from the prop parameters
     */
    useEffect(() => {
        /**
         * Setup a listener on the user document under the agency to stream permissions back
         */
        const unsubscribe = db.doc(`agencies/${id}/users/${auth.currentUser.uid}`)
            .onSnapshot((userSnap) => {
                /**
                 * Make sure the user document on the agency is present 
                 */
                if (userSnap.exists) {
                    /**
                     * Deconstruct the permissions from the user document
                     */
                    const { permissions } = userSnap.data();
                    /**
                     * And then push them to the local state
                     */
                    setPermissions(permissions);
                    setLoading(false);
                }
            });
        /**
         * Remove the listener on unload
         */
        return () => unsubscribe();
    }, [id]);

    /**
    * When the permissions have been loaded into the state
    */
    useEffect(() => {
        /**
         * Make sure they have permission to load the agency data
         */
        if (!permissions.ADMINISTRATE) {
            /**
             * Close the function out here
             */
            return () => { };
        }
        /**
         * Otherwise we need to setup a listener on the agency database document
         */
        const unsubscribe = db.doc(`agencies/${id}`)
            .onSnapshot((agencyDoc) => {
                /**
                 * Make sure the agency document exists
                 */
                if (agencyDoc.exists) {
                    /**
                     * Deconstruct the agency name from the firestore document
                     */
                    const { name } = agencyDoc.data();
                    /**
                     * Set the agency name into the local state
                     */
                    setAgencyName(name);
                }
            });
        /**
         * Remove the listener on unload
         */
        return () => unsubscribe();
    }, [permissions]);

    /**
     * Does this user have the basic permission to manage users?
     */
    return (
        <Tile fullPage={true}>
            <Title>
                {/* Display the agency name along the top */}
                <h1>Manage {agencyName}</h1>
            </Title>

            {/* Navigation routes */}
            <Navigation>
                {/* Does the current user have permissions to administrate the agency */}
                {permissions.ADMINISTRATE &&
                    <>
                        {/* Edit details link */}
                        <NavLink activeClassName="is-active" to={`${url}/edit`}>
                            Details
                        </NavLink>
                    </>
                }

                {/* Does the current user have permissions to manage the users */}
                {permissions.MANAGE_USERS &&
                    <>
                        {/* Assign users link */}
                        <NavLink activeClassName="is-active" to={`${url}/users`}>
                            Users
                        </NavLink>
                    </>
                }

                {/* Does the current user have permissions to administrate the agency */}
                {permissions.ADMINISTRATE &&
                    <>
                        {/* Billing link */}
                        <NavLink activeClassName="is-active" to={`${url}/billing`}>
                            Billing
                        </NavLink>
                    </>
                }
            </Navigation>

            {!loading &&
                <>
                    {/* Expose routes based on access level of current user */}
                    <Switch>
                        {/* Assigned users */}
                        {permissions.MANAGE_USERS &&
                            <Route path="/agency/:id/users" exact component={() =>
                                <Users agencyID={id} />} />}

                        {/* Does the current user have administrative permissions? */}
                        {(permissions.ADMINISTRATE) &&
                            <>
                                {/* Edit details route */}
                                <Route path="/agency/:id/edit" exact component={() =>
                                    <Edit agencyID={id} />} />

                                {/* Billing details route */}
                                <Route path="/agency/:id/billing" exact component={() =>
                                    <Billing agencyID={id} />} />
                            </>
                        }

                        {/* Any other page that doesn't have a route */}
                        <Route path="*" component={Error404} />
                    </Switch>
                </>
            }
        </Tile>
    );
}

export default Agency;