import React, { useState, useEffect, useContext } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { db } from "../../../utils/firebase";

/**
 * Styles
 */
import "./comments-overlay.scss";

/**
 * UI components
 */
import Comment from "./comment/comment";
import PastDrawing from "../drawings/drawing-past";

/**
 * Functional component to pull and display the comments overlay on the content.
 *
 * @returns HTML markup to display the comments overlay
 */
function CommentsOverlay(props) {
    const [comments, setComments] = useState([]);

    /**
     * Get the IDs needed to listen for comments from the content provider
     */
    const { ids, media, currentComment } = useContext(ContentContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup comments listener if the IDs are present from the context
         */
        ids && listenForComments();
    }, [ids]);

    /**
     * Sets up a listener on the database record for this content to stream the comments
     * down into the state for displaying.
     *
     * @type {const}
     * @returns Updates the component state with the comments for this content
     */
    const listenForComments = () => {
        /**
         * Deconstruct the IDs for use
         */
        const { client, project, content, file } = ids;
        /**
         * Reference the document in the database
         */
        db.collection(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments`)
            .orderBy("date")
            .onSnapshot((snap) => {
                /**
                 * Loop over the changes passed down
                 */
                snap.docChanges().forEach(async (change) => {
                    /**
                     * Added or modified case, both included here as almost same logic applies
                     */
                    if (change.type === "added" || change.type === "modified") {
                        /**
                         * Deconstruct the attributes of the comment from the changed document
                         */
                        const { type, x_pos, y_pos, secondsAt, canvasData } = change.doc.data();
                        /**
                         * Build the comment object to push onto the state
                         */
                        const commentElement = {
                            id: change.doc.id || null,
                            type: type || null,
                            x_pos: x_pos || null,
                            y_pos: y_pos || null,
                            secondsAt: secondsAt || null,
                            canvasData: canvasData || null,
                        };
                        /**
                         * Was this comment added or ammended?
                         */
                        if (change.type === "added") {
                            /**
                             * If it was added, push it to the array
                             */
                            setComments((comments) => [...comments, commentElement]);
                        } else {
                            /**
                             * If it was modified, find and update the current comment in the array
                             */
                            setComments((comments) => {
                                /**
                                 * Create a new mutable array to update
                                 */
                                let updatedComments = [...comments];
                                /**
                                 * Loop over the comments passed in from the update state
                                 */
                                for (let i in comments) {
                                    /**
                                     * If the changed document matches the one on this iteration
                                     */
                                    if (comments[i].id === change.doc.id) {
                                        /**
                                         * Push the new comment data to the updatedComments array
                                         */
                                        updatedComments[i] = commentElement;
                                        break;
                                    }
                                }
                                /**
                                 * Return the updated array to set the new state
                                 */
                                return updatedComments;
                            });
                        }
                    }
                    /**
                     * Removed case
                     */
                    if (change.type === "removed") {
                        /**
                         * Remove the comment from the array using it's ID
                         */
                        setComments((comments) => comments.filter((commentElement) => commentElement.id !== change.doc.id));
                    }
                });
            });
    };

    return (
        <div id="comments-overlay">
            {[...comments].reverse().map((comment, index) => (
                <div key={comment.id}>
                    {/* Drawing format */}
                    {comment.type === "PEN" &&
                        <div className="content-feedback-tile-drawings">
                            <PastDrawing
                                comment={comment}
                                containerSize={props.containerSize}
                                canvasData={comment.canvasData} />
                        </div>
                    }

                    {/* Pin format */}
                    {!comment.type &&
                        <Comment
                            comment={comment}
                            media={media}
                            toggled={comment.id === currentComment} />
                    }
                </div>
            ))}
        </div>
    );
}

export default CommentsOverlay;
