import React, { useEffect, useState, useContext } from "react";
import firebase from "firebase";
import { AuthContext } from "../../../utils/providers/auth";
import { db, auth } from "../../../utils/firebase";
import { PlusIcon } from "../../../utils/svgs";
import "./timeline.scss";

/**
 * Timeline UI components
 */
import Upload from '../../../components/content/upload/upload';
import Content from "../../../components/timeline/content/content";
import Meta from "../../../components/timeline/meta/meta";

/**
 * Functional component for the timeline page of a given content ID.
 * 
 * @param {object} props
 * @returns HTML markup for the timeline of a given content ID
 */
function Timeline(props) {
    const [projectContent, setProjectContent] = useState([]);
    const [sortedContent, setSortedContent] = useState([]);
    const [canDownload, setCanDownload] = useState(false);
    const [projectData, setProjectData] = useState({});
    const [uploadToggled, setUploadToggled] = useState(false);

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the IDs and permissions we need to watch from the listener passing in through props
     */
    const { clientID, projectID, permissions } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Update the last seen value for this project in the users subcollection
         */
        projectID && db.doc(`users/${auth.currentUser.uid}/projects/${projectID}`).set({
            lastSeen: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
    }, [projectID]);

    /**
     * When the ID for the client & project are recognised in state and the FEEDBACK permission is present
     */
    useEffect(() => {
        /**
         * Setup a database listener on the content collection for the project
         */
        const unsubscribe = db.collection(`clients/${clientID}/projects/${projectID}/content`)
            .orderBy("created", "desc")
            .onSnapshot((contentSnap) => {
                /**
                 * Loop over the documents in this snapshot
                 */
                contentSnap.docChanges().forEach((change) => {
                    /**
                     * Content document added
                     */
                    if (change.type === "added") {
                        setProjectContent((content) => [
                            ...content,
                            { id: change.doc.id, ...change.doc.data() }
                        ]);
                    }
                    /**
                     * Content document updated
                     */
                    if (change.type === "modified") {
                        setProjectContent((content) => {
                            let updatedContent = [...content];
                            for (let i in content) {
                                if (content[i].id === change.doc.id) {
                                    updatedContent[i] = { id: change.doc.id, ...change.doc.data() };
                                    break;
                                }
                            }
                            return updatedContent;
                        });
                    }
                    /**
                     * Content document removed
                     */
                    if (change.type === "removed") {
                        setProjectContent((content) => content.filter(
                            (content) => content.id !== change.doc.id
                        ));
                    }
                });
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, [clientID, projectID, permissions?.FEEDBACK]);

    /**
     * When the project content snapshot updates the state
     */
    useEffect(() => {
        /**
         * Sort function, sorting by created seconds
         */
        const sortByCreated = (a, b) => {
            if (a.created && b.created) {
                if (a.created.seconds > b.created.seconds) {
                    return -1;
                }
                if (a.created.seconds < b.created.seconds) {
                    return 1;
                }
                return 0;
            } else {
                return 0;
            }
        }
        /**
         * Sort the content into date order
         */
        setSortedContent([...projectContent.sort(sortByCreated)]);
    }, [projectContent]);

    /**
     * When the ID for the client & project are recognised in state
     */
    useEffect(() => {
        /**
         * Setup a database listener on the project document
         */
        const unsubscribe = db.doc(`clients/${clientID}/projects/${projectID}`)
            .onSnapshot((projectSnap) => {
                /**
                 * Make sure the project exists
                 */
                if (projectSnap.exists) {
                    /**
                     * Deconstruct the data we want
                     */
                    const { download } = projectSnap.data();
                    /**
                     * Update the state
                     */
                    setCanDownload(download);
                    setProjectData(projectSnap.data());
                }
            });
        /**
         * Remove the listener on component unmount
         */
        return () => unsubscribe();
    }, [clientID, projectID]);

    /**
     * When the user object is loaded from the context, the permissions are in and there is an update 
     * to the length of the sorted context in the local state
     */
    useEffect(() => {
        /**
         * Is the user an agency type, has permission to upload and current has no content on the timeline
         */
        if (user?.is_agency && permissions?.COLLABORATE && sortedContent.length === 0) {
            /**
             * If this is the case, toggle the upload form stright away
             */
            setUploadToggled(true);
        } else {
            /**
             * Otherwise hide the upload form
             */
            setUploadToggled(false);
        }
    }, [user?.is_agency && permissions?.COLLABORATE && sortedContent]);

    return (
        <div className={["timeline-grid", !user.is_agency && "with-padding"].join(" ")}>
            {/* Content column for the cards and add toggle */}
            <div className={["content-column", (sortedContent.length > 0) && "has-content"].join(" ")}>
                {/* Is there no content in the state */}
                {(user?.is_agency && permissions?.COLLABORATE && sortedContent.length === 0) &&
                    <div className="no-content-notice with-margin">
                        <p>Welcome to the start of your timeline!</p>
                        <small>Upload a piece of content below to get started.</small>
                    </div>
                }

                {/* Is this current user an agency user and do they have the correct permissions to add */}
                {(user?.is_agency && permissions?.COLLABORATE && uploadToggled) &&
                    <Upload
                        clientID={clientID}
                        projectID={projectID}
                        firstUpload={sortedContent.length === 0}
                        toggleUpload={() => setUploadToggled(false)} />
                }

                {/* Is this current user an agency user, have the correct permissions and the upload form isn't showing */}
                {(user?.is_agency && permissions?.COLLABORATE && !uploadToggled) &&
                    <div className="upload-content-toggle" onClick={() => setUploadToggled(true)}>
                        <PlusIcon />
                        <p>Upload content</p>
                    </div>
                }

                {/* Is there no content in the state */}
                {(!user?.is_agency && sortedContent.length === 0) &&
                    <div className="no-content-notice">
                        <p>There is no content here...yet</p>
                    </div>
                }

                {/* Is there content to show */}
                {(sortedContent.length > 0) &&
                    sortedContent.map((content) =>
                        <Content
                            key={content.id}
                            clientID={clientID}
                            projectID={projectID}
                            contentID={content.id}
                            permissions={permissions}
                            canDownload={canDownload} />
                    )
                }
            </div>

            {/* Window to display along the right of the timeline for project meta */}
            <Meta clientID={clientID} projectID={projectID} project={projectData} />
        </div>
    );
}

export default Timeline;