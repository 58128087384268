import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { NavLink } from "react-router-dom";
import { db } from "../../utils/firebase";
import "./client.scss";

/**
 * UI components 
 */
import Badge from "../design-system/ui/badge/badge";

/**
 * Hooks
 */
import useClientLogo from "../../hooks/useClientLogo";
import useImageCollage from "../../hooks/useImageCollage";

/**
 * Functional component for the client card. An ID is passed in and is used to setup a listener 
 * on the database for streaming the data into the card
 * 
 * @param {object} props Props for the client card, should contain "clientID"
 * @returns HTML markup for the client card
 */
function Client(props) {
    const [clientExists, setClientExists] = useState(true);
    const [clientName, setClientName] = useState("");
    const [clientProjects, setClientProjects] = useState(0);
    const [clientAgency, setClientAgency] = useState("");
    const [clientThumbnails, setClientThumbnails] = useState([]);
    const [agencyName, setAgencyName] = useState("");

    /**
     * Deconstruct the user object from context
     */
    const { user } = useContext(AuthContext);

    /**
     * Deconstruct the client ID from the props
     */
    const { client } = props;

    /**
     * Get the logo image for the client or their initials
     */
    const clientLogo = useClientLogo(client.id);

    /**
     * Get the preview thumbnails HTML
     */
    const previewGrid = useImageCollage(clientThumbnails);

    /**
     * When the client ID from the props is loaded/changed
     */
    useEffect(() => {
        /**
         * Setup a listener on the client's document
         */
        const unsubscribe = db.doc(`clients/${client.id}`).onSnapshot((clientDoc) => {
            /**
             * Make sure the client document exists
             */
            if (clientDoc.exists) {
                /**
                 * Deconstruct the data from the client data object
                 */
                const { name, agency, preview_thumbnails } = clientDoc.data();
                /**
                 * Set the client data into the state
                 */
                setClientName(name);
                setClientAgency(agency);
                setClientThumbnails(preview_thumbnails);
            } else {
                /**
                 * Make sure we don't print a card out if the client doesn't exist
                 */
                setClientExists(false);
            }
        });
        /**
         * Unsubscribe from the listener on the component unload
         */
        return () => unsubscribe();
    }, [client?.id]);

    /**
     * When the client ID is present
     */
    useEffect(() => {
        /**
         * Setup a listener to retrieve the projects count
         */
        const unsubscribe = db.collection(`clients/${client.id}/projects`).onSnapshot((projectsSnapshot) => {
            /**
             * Update the state with the clients projects count
             */
            setClientProjects(projectsSnapshot.size);
        });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, [client?.id]);

    /**
     * When the client's agency ID is loaded into state
     */
    useEffect(() => {
        /**
         * Fetch the name of the agency from the database
         */
        clientAgency && db.doc(`agencies/${clientAgency}`).get().then((agencyDoc) => {
            /**
             * Make sure the agency exists first
             */
            if (agencyDoc.exists) {
                /**
                 * Update the local state
                 */
                setAgencyName(agencyDoc.data().name);
            }
        });
    }, [clientAgency]);

    /**
     * When the clientName changes, push it back up the props for sorting the clients grid
     */
    useEffect(() => {
        props.appendName(client.id, clientName);
    }, [clientName]);

    /**
     * Make sure we are printing the card if the client exists
     */
    if (clientExists) {
        return (
            <div className="client-card">
                {/* Collage of the most recent content loaded for this client */}
                <div className="project-collage">
                    {/* Badge to display the name of the agency the client is connected to */}
                    {agencyName && <Badge type="INFO" label={agencyName} />}

                    {/* Grid collage showing the thumbnails for the client */}
                    {previewGrid}

                    {/* Client logo holder to sit above the collage, either image or initals are returned */}
                    <div className="client-logo">
                        {clientLogo}
                    </div>
                </div>

                {/* Client name */}
                <p className="client-name">{clientName}</p>

                {/* If the user is an agency user, we want to display some additional information */}
                {user.is_agency &&
                    <div className="client-stats">
                        <div className="stat">
                            <p>{clientProjects}<span>Project{clientProjects !== 1 ? 's' : ''}</span></p>
                        </div>
                        <div className="stat">
                        </div>
                        <div className="stat">
                        </div>
                    </div>
                }

                <div className="client-manage">
                    <NavLink className="ui-button info" to={`/client/${client.id}/projects`}>
                        Manage
                    </NavLink>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default Client;