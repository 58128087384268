import React, { useContext, useState } from "react";
import { AuthContext } from "../../utils/providers/auth";
import "./tasks.scss";

/**
 * UI components & structure blocks
 */
import Tile from "../../components/design-system/structure/tile/tile";
import Title from "../../components/design-system/structure/title/title";
import { BarsHorizontalIcon, CalendarIcon, ColumnsIcon } from "../../utils/svgs";

/**
 * Functional component to return the tasks page for the current user
 */
function Tasks() {
    const [tasks, setTasks] = useState([]);
    const [display, setDisplay] = useState("ROW");

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    return (
        <Tile fullPage={true}>
            <Title hasBorder={true} isFlex={true}>
                <h1>Tasks</h1>

                <div className="task-display-toggle-wrap">
                    <div
                        className={["task-display-toggle", display === "ROW" && "is-active"].join(" ")}
                        onClick={() => setDisplay("ROW")}>
                        <BarsHorizontalIcon />
                    </div>
                    <div
                        className={["task-display-toggle", display === "COLUMN" && "is-active"].join(" ")}
                        onClick={() => setDisplay("COLUMN")}>
                        <ColumnsIcon />
                    </div>
                    <div
                        className={["task-display-toggle", display === "CALENDAR" && "is-active"].join(" ")}
                        onClick={() => setDisplay("CALENDAR")}>
                        <CalendarIcon />
                    </div>
                </div>
            </Title>

            <div className="">
                {/* If there are no tasks to show */}
                {tasks.length === 0 &&
                    <p className="no-tasks-found">Nice! You don't have any tasks that require attention.</p>
                }
            </div>
        </Tile>
    );
}

export default Tasks;