import React from "react";
import "./navigation.scss";

/**
 * Functional component to display a navigation bar to sit beneath the tile title
 * 
 * @param {object} props Props passed in expected to be the links (children) to sit inside the nav
 * @returns HTML markup for the navigation with links included
 */
function Navigation(props) {
    return (
        <div className="tile-navigation">
            <div className="links">
                {props.children}
            </div>
            <div className="lower-border" />
        </div>
    );
}

export default Navigation;