import React, { useEffect, useState } from "react";
import { db, auth } from "../../../utils/firebase";

/**
 * UI components
 */
import InviteUser from "./invite";
import AgencyUser from "./user/user";

/**
 * Functional component to return the user management for the agency in scope
 */
function Users(props) {
    const [users, setUsers] = useState([]);
    const [canManageUsers, setCanManageUsers] = useState(false);

    /**
     * Deconstruct the agencyID from the props
     */
    const { agencyID } = props;

    /**
     * When the agencyID is updated
     */
    useEffect(() => {
        /**
         * Setup a listener on the database to stream the agency users down
         */
        const unsubscribe = db.collection(`agencies/${agencyID}/users`)
            .onSnapshot((agencySnap) => {
                /**
                 * Loop over the user documents found
                 */
                agencySnap.docChanges().forEach((change) => {
                    /**
                     * User document added
                     */
                    if (change.type === "added") {
                        setUsers((users) => [
                            ...users,
                            { id: change.doc.id, ...change.doc.data() }
                        ]);
                    }
                    /**
                     * User document updated
                     */
                    if (change.type === "modified") {
                        setUsers((users) => {
                            let updatedUsers = [...users];
                            for (let i in users) {
                                if (users[i].id === change.doc.id) {
                                    updatedUsers[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedUsers;
                        });
                    }
                    /**
                     * User document removed
                     */
                    if (change.type === "removed") {
                        setUsers((users) => users.filter((user) => user.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, [agencyID]);

    /**
     * When the agencyID is updated, we also need to check the current users permissions
     */
    useEffect(() => {
        /**
         * Setup a listener on the current users document for this agency
         */
        const unsubscribe = db.doc(`agencies/${agencyID}/users/${auth.currentUser.uid}`)
            .onSnapshot((userSnap) => {
                /**
                 * Make sure the user doclument exists
                 */
                if (userSnap.exists) {
                    /**
                     * Deconstruct the permissions object from them
                     */
                    const { permissions } = userSnap.data();
                    /**
                     * Push the MANAGE_USERS permissions into he state as the canManageUsers flag
                     */
                    setCanManageUsers(permissions?.MANAGE_USERS);
                }
            });
        /**
         * Remove the listener when the component unloads
         */
        return () => unsubscribe();
    }, [agencyID]);

    return (
        <div className="agency-users">
            {/* Invite user row */}
            <InviteUser agencyID={agencyID} />

            {/* Print the users to the DOM */}
            {users.map((user) => (
                <AgencyUser
                    key={user.id}
                    userID={user.id}
                    agencyID={agencyID}
                    canRemove={canManageUsers} />
            ))}
        </div>
    );
}

export default Users;