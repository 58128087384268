import React, { useContext, useEffect, useState } from "react";
import { ContentContext } from "../../../utils/providers/content";
import { db } from "../../../utils/firebase";
import { fetchUser } from "../../../utils/database";

/**
 * Styles
 */
import "./comments.scss";

/**
 * UI components
 */
import Comment from "./comment/comment";

/**
 * Functional component that sets up a listener on the content record in the database
 * and streams the updates to the state.
 *
 * @returns HTML markup for the comments to display on the content review page
 */
function Comments() {
    const [comments, setComments] = useState([]);

    /**
     * Get the IDs needed to listen for comments from the content provider
     */
    const { ids } = useContext(ContentContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup comments listener if the IDs are present from the context
         */
        ids && listenForComments();
    }, [ids]);

    /**
     * Moment used to print a reader-friendly date
     */
    const moment = require("moment");

    /**
     * Sets up a listener on the database record for this content to stream the comments
     * down into the state for displaying.
     *
     * @type {const}
     * @returns Updates the component state with the comments for this content
     */
    const listenForComments = () => {
        /**
         * Deconstruct the IDs for use
         */
        const { client, project, content, file } = ids;
        /**
         * Reference the document in the database
         */
        db.collection(`clients/${client}/projects/${project}/content/${content}/files/${file}/comments`)
            .orderBy("date")
            .onSnapshot((snap) => {
                /**
                 * Loop over the changes passed down
                 */
                snap.docChanges().forEach(async (change) => {
                    /**
                     * Added or modified case, both included here as almost same logic applies
                     */
                    if (change.type === "added" || change.type === "modified") {
                        /**
                         * Deconstruct the attributes of the comment from the changed document
                         */
                        const { user, status, date, onTaskList, comment, x_pos, y_pos, useragent, secondsAt, taskID } = change.doc.data();
                        /**
                         * Build the date object from the comment
                         */
                        const commentDate = moment.unix(date.seconds).format("Do MMM YYYY HH:mm");
                        /**
                         * Get the users details (name and email) who left the comment
                         */
                        const commentUser = await fetchUser(user);
                        /**
                         * Build the comment object to push onto the state
                         */
                        const commentElement = {
                            id: change.doc.id || null,
                            userID: user,
                            user: commentUser.name || commentUser.email,
                            date: commentDate || null,
                            status: status || null,
                            onTaskList: onTaskList || false,
                            comment: comment || null,
                            x_pos: x_pos || null,
                            y_pos: y_pos || null,
                            useragent: useragent || null,
                            secondsAt: secondsAt || null,
                            taskID: taskID || null,
                        };
                        /**
                         * Was this comment added or ammended?
                         */
                        if (change.type === "added") {
                            /**
                             * If it was added, push it to the array
                             */
                            setComments((comments) => [...comments, commentElement]);
                        } else {
                            /**
                             * If it was modified, find and update the current comment in the array
                             */
                            setComments((comments) => {
                                /**
                                 * Create a new mutable array to update
                                 */
                                let updatedComments = [...comments];
                                /**
                                 * Loop over the comments passed in from the update state
                                 */
                                for (let i in comments) {
                                    /**
                                     * If the changed document matches the one on this iteration
                                     */
                                    if (comments[i].id === change.doc.id) {
                                        /**
                                         * Push the new comment data to the updatedComments array
                                         */
                                        updatedComments[i] = commentElement;
                                        break;
                                    }
                                }
                                /**
                                 * Return the updated array to set the new state
                                 */
                                return updatedComments;
                            });
                        }
                    }
                    /**
                     * Removed case
                     */
                    if (change.type === "removed") {
                        /**
                         * Remove the comment from the array using it's ID
                         */
                        setComments((comments) => comments.filter((commentElement) => commentElement.id !== change.doc.id));
                    }
                });
            });
    };

    /**
     * Are there comments in the state to iterate through?
     */
    if (comments.length > 0) {
        /**
         * Iterate over the comments in the state and load a comment component in for each
         */
        return (
            <div className="comments-column">
                <div className="comments-title">Comments:</div>
                {[...comments].reverse().map((comment, index) => (
                    <Comment key={comment.id} comment={comment} />
                ))}
            </div>
        );
    } else {
        return <div className="no-comments">No comments to display yet...</div>;
    }
}

export default Comments;
