import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../../utils/providers/auth';

/**
 * Route components
 */
import Sandbox from '../../../containers/sandbox/sandbox';

import Login from '../../../containers/login/login';
import Signup from '../../../containers/signup/signup';
import PasswordReset from '../../../containers/password/password';
import Dashboard from '../../../containers/dashboard/dashboard';
import Clients from '../../../containers/clients/clients';
import Client from '../../../containers/client/client';
import Content from '../../../containers/content/content';
import Agency from '../../../containers/agency/agency';
import Project from '../../../containers/project/project';
import Projects from '../../../containers/projects/projects';
import Tasks from '../../../containers/tasks/tasks';
import Settings from '../../../containers/settings/settings';
import Error404 from '../../../containers/errors/404';
import Error403 from '../../../containers/errors/403';

/**
 * Returns the routes for the app.
 * 
 * These routes are only loaded in when the parent Route component recognises there is 
 */
function SpecificRoutes() {
    /**
     * Deconstruct the user object from the auth context
     */
    const { user } = useContext(AuthContext);

    if (!user.logged_in) {
        /**
         * If the user object is still loading from the context, show loading
         */
        return ("loading");
    } else {
        /**
         * Otherwise if the user object is loaded in, pass through the routes
         */
        return (
            <Switch>
                {/* Sandbox page for testing */}
                <Route path="/sandbox" exact component={Sandbox} />

                {/* Login, Password Reset & Signup */}
                <Route path="/login" exact component={Login} />
                <Route path="/password-reset" component={PasswordReset} />
                <Route path="/signup" component={Signup} />

                {/* General app pages */}
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/clients" component={Clients} />
                <Route path="/client/:id" component={Client} />
                <Route path="/content/:id" component={Content} />
                <Route path="/agency/:id" component={Agency} />
                <Route path="/project/:id" component={Project} />
                <Route path="/projects" component={Projects} />
                <Route path="/tasks" component={Tasks} />
                <Route path="/settings" component={Settings} />

                {/* Page redirects */}
                {user.is_agency &&
                    <Redirect from="/" to="/dashboard" exact />
                }

                {!user.is_agency &&
                    <Redirect from="/" to="/projects" exact />
                }

                {/* Error pages */}
                <Route path="/unauthorised" exact component={Error403} />
                <Route path="*" component={Error404} />
            </Switch>
        );
    }
}

export default SpecificRoutes;